import { useDispatch, useSelector } from 'react-redux';
import { contentIdSelector, currentListSelector } from '../../../../store/analytics/analytics.selector';
import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import ListItemComponent from '../common/ListItem/ListItem';
import AnalyticsList from '../../common/AnalyticsList';
import { useIsFocused } from '@react-navigation/native';
import HeaderTabs from '../common/HeaderTabs';
import { log } from '../../../../store/appActivity/appActivity.slice';
import usePlaylistAttributesMap from '../hooks/usePlaylistAttributesMap';
import useHasAttributesToRender from '../../hooks/useHasAttributesToRender';
import useHasOrganizationAttributes from '../../hooks/useHasOrganizationAttributes';
import { IStackNavigation } from '../../../../navigation/types';
import useAttributesData from '../../hooks/useAttributesData';
import { getPlaylistAttributes } from '../../../../store/attributes/playlistAttributes/playlistAttributes.slice';
import useHeaderTabsDivider from '../../hooks/useHeaderTabsDivider';
import { analyticsListDataSet } from '../../analytics.constants';
import { getUsersData, setIsNeedToScrollUp } from '../../../../store/attributes/playlistStats/playlistStats.slice';
import {
  isNeedToScrollUpSelector,
  usersDataSelector,
  usersErrorSelector,
  usersLoadingSelector,
  usersNextSelector,
} from '../../../../store/attributes/playlistStats/playlistStats.selector';
import { ECurrentList } from '../../../../store/analytics/analytics.types';
import { PLAYLIST_ATTRIBUTES_SCREEN, PLAYLIST_USERS_LIST_SCREEN } from '../constants';
import styled from 'styled-components/native';
import { calcWidth, deviceWidth, isWeb } from '../../../../utils/dimensions';
import { selectedFilterAttributeValueIdsSelector } from '../../../../store/attributes/playlistAttributes/playlistAttributes.selector';
import { PlaylistUserStats } from '../../../../store/attributes/playlistStats/playlistStats.types';
import UsersSorting from '../common/UsersSorting';
import PlaylistUserDetailsBottomSheet from '../common/PlaylistUserDetailsBottomSheet';
import { playlistItemSelector } from '../../../../store/playlist/playlist.selectors';
import PlaylistTotalBar from '../../common/TotalBar/PlaylistTotalBar/PlaylistTotalBar';

const PlaylistUsersList: React.FC<IStackNavigation> = ({ navigation }) => {
  const dispatch = useDispatch();
  const isFocused = useIsFocused();
  const isLoading = useSelector(usersLoadingSelector);
  const usersList = useSelector(usersDataSelector);
  const usersError = useSelector(usersErrorSelector);
  const usersNext = useSelector(usersNextSelector);
  const isNeedToScrollUp = useSelector(isNeedToScrollUpSelector);
  const selectedValueIds = useSelector(selectedFilterAttributeValueIdsSelector);

  const formattedData = useMemo(() => (usersList ? [null, ...usersList] : undefined), [usersList]);

  const { attributesMap } = usePlaylistAttributesMap();
  const { hasAttributesToRender } = useHasAttributesToRender({ attributesMap });
  const { handleScroll, isShowHeaderBottomDivider } = useHeaderTabsDivider();

  const [selectedItem, setSelectedItem] = useState<PlaylistUserStats>(null);

  const playlistId = useSelector(contentIdSelector);
  const playlist = useSelector(playlistItemSelector(playlistId));

  useAttributesData({
    attributesMap,
    onLoad: getPlaylistAttributes,
  });

  const currentList = useSelector(currentListSelector);
  const { hasOrganizationAttributes } = useHasOrganizationAttributes();

  const handleSetIsNeedToScrollUp = useCallback(
    ({ status }) => {
      dispatch(setIsNeedToScrollUp(status));
    },
    [dispatch],
  );

  const renderListItem = useCallback(
    ({ index, item }) => {
      if (index === 0) {
        return <PlaylistTotalBar isUsers />;
      }

      const withSeparator = !!item.activity && !!usersList[index]?.activity;
      const withDivider = index > 1 && !item.activity && !!usersList[index - 2]?.activity;
      const total = playlist?.is_quiz
        ? playlist?.bite_shares.filter((biteShare) => biteShare.playlist_section !== 'intro').length
        : playlist?.bite_shares.length;
      return (
        <ListItem
          item={item}
          withDivider={withDivider}
          withSeparator={withSeparator}
          onPress={setSelectedItem}
          total={total}
          isQuiz={playlist?.is_quiz}
        />
      );
    },
    [playlist?.bite_shares, playlist?.is_quiz, usersList],
  );

  const handleEndReached = useCallback(() => {
    dispatch(
      log({
        event: 'OverviewList.handleEndReached',
        data: { usersNext, isLoading, usersError },
      }),
    );
    dispatch(getUsersData());
  }, [dispatch, isLoading, usersError, usersNext]);

  const handleEndReachedProp = useMemo(() => {
    if (!usersNext || isLoading || usersError) {
      return;
    }

    return handleEndReached;
  }, [handleEndReached, isLoading, usersError, usersNext]);

  const handleCloseUserDetails = useCallback(() => {
    setSelectedItem(null);
  }, []);

  const handleRefresh = useCallback(
    ({ isPullToRefresh, callback }) => {
      dispatch(
        log({
          event: 'OverviewList.handleRefresh',
        }),
      );
      dispatch(getUsersData({ reset: true, clearAnalyticsCache: isPullToRefresh, callback }));
    },
    [dispatch],
  );

  const handleErrorRefresh = useCallback(() => {
    dispatch(
      log({
        event: 'OverviewList.handleErrorRefresh',
      }),
    );
    dispatch(getUsersData());
  }, [dispatch]);

  const switchedToFocusRef = useRef(isFocused);

  useEffect(() => {
    const switchedToFocus = isFocused && !switchedToFocusRef.current;
    switchedToFocusRef.current = isFocused;

    if (isLoading || !isFocused || (usersList !== null && (!usersError || !switchedToFocus))) {
      return;
    }

    dispatch(getUsersData({ reset: !usersError }));
  }, [dispatch, isFocused, isLoading, usersError, usersList]);

  useEffect(() => {
    if (currentList === ECurrentList.ATTRIBUTES && hasOrganizationAttributes) {
      navigation.replace(PLAYLIST_ATTRIBUTES_SCREEN);
    }
  }, [currentList, hasOrganizationAttributes, navigation]);

  return (
    <>
      <S.Container>
        <HeaderTabs
          currentScreen={PLAYLIST_USERS_LIST_SCREEN}
          attributesScreen={PLAYLIST_ATTRIBUTES_SCREEN}
          listScreen={PLAYLIST_USERS_LIST_SCREEN}
          rightComponent={<UsersSorting />}
          disableAttributes={!hasAttributesToRender}
          isShowBottomDivider={isShowHeaderBottomDivider}
        />
        <AnalyticsList
          dataSet={analyticsListDataSet}
          data={formattedData}
          renderItem={renderListItem}
          onEndReached={handleEndReachedProp}
          onErrorRefresh={handleErrorRefresh}
          isLoading={isLoading}
          onRefresh={handleRefresh}
          error={usersError}
          isNeedToScrollUp={isNeedToScrollUp}
          onScroll={handleScroll}
          scrollEventThrottle={10}
          selectedValueIds={selectedValueIds}
          setIsNeedToScrollUp={handleSetIsNeedToScrollUp}
        />
      </S.Container>
      <PlaylistUserDetailsBottomSheet details={selectedItem} onClose={handleCloseUserDetails} />
    </>
  );
};

const S = {
  Container: styled.View`
    height: 100%;
    width: ${isWeb ? calcWidth(550) : deviceWidth}px;
    align-self: center;
    flex: 1;
    background-color: white;
  `,
};

interface IListItemProps {
  item: PlaylistUserStats;
  withDivider?: boolean;
  withSeparator?: boolean;
  onPress: (item: PlaylistUserStats) => void;
  total: number;
  isQuiz?: boolean;
}

const ListItem: React.FC<IListItemProps> = ({ item, withDivider, withSeparator, onPress, total, isQuiz }) => {
  const { activity, user } = item;

  const stats = useMemo(() => {
    if (!activity) {
      return;
    }

    if (isQuiz) {
      return {
        progress: activity.filter((bite) => !!bite.bite_progress).length,
        progressTotal: total,
        success: activity.filter((bite) => bite.answer === 'correctly').length,
        successTotal: total,
      };
    }

    return {
      progress: activity.filter((bite) => !!bite.bite_progress).length,
      progressTotal: total,
      success: activity.filter((bite) => bite.answer === 'correctly').length,
      successTotal: activity.filter((bite) => bite.has_question && bite.answer !== null).length,
    };
  }, [activity, isQuiz, total]);

  const handlePress = useCallback(() => {
    onPress(item);
  }, [item, onPress]);

  return (
    <ListItemComponent
      stats={stats}
      user={user}
      withDivider={withDivider}
      withSeparator={withSeparator}
      onPress={item.activity?.length ? handlePress : undefined}
    />
  );
};

export default memo(PlaylistUsersList);
