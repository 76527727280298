import { ICloudAsset } from '../../store/cloudAssets/cloudAssets.types';

export const PRIMARY_SIDEBAR_WIDTH = 64;
export const SECONDARY_SIDEBAR_WIDTH = 320;
export const SETTINGS_SIDEBAR_WIDTH = 200;
export const TOP_BAR_HEIGHT = 58;
export const TIMELINE_CONTROLS_HEIGHT = 46;
export const SPACING = 12;
export const IS_EXPANDED_TRANSITION_TS = 500;

export const MAX_ASSET_FILE_SIZE_MB = 250;
export const MAX_UNDO_REDO_STACK_SIZE = 20;

export const STOCK_FILE_TYPES: ('sticker' | ICloudAsset['fileType'])[] = ['video', 'image', 'gif', 'sticker'];
// const getDefaultIndicatorOffsetX = (containerWidth: number) => containerWidth / 4;
// const TRIM_CONTROLS_OFFSET = 4;

export const TIMELINE_CONTAINER_HEIGHT = 200;
