import React, { useCallback, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';
import { createCloudAsset } from '../../../store/cloudAssets/cloudAssets.slice';
import { v4 as uuid } from 'uuid';
import {
  addToTimeline,
  addUndo,
  deleteProcessingTimelineItemState,
  playVideo,
  removeEmptyTimelineLayers,
  removeUndoRedoById,
  saveVideo,
  setCurrentSeconds,
  setIsPlaying,
  setProcessingTimelineItemState,
  setRecordingTimelineItemId,
  updateTimelineItem,
  updateTimelineItemLayer,
  updateTimelineMeta,
} from '../../../store/videoEditor/videoEditor.slice';
import {
  currentSecondsSeletor,
  isPlayingSeletor,
  recordingTimelineItemIdSelector,
  videoResolutionSeletor,
} from '../../../store/videoEditor/videoEditor.selectors';

import { ITimelineItem } from '../../../store/videoEditor/videoEditor.types';
import {
  ICloudAssetVideoCache,
  cloneCloudAssetCacheForVideoTimelineItem,
} from '../../../store/cloudAssets/cloudAssets.cache';
import { logError } from '../../../store/appActivity/appActivity.slice';
import styled from 'styled-components';
import defaultTheme from '../../../themes/defaultTheme';
import MicIcon from '../../../assets/icons/videoEditor/leftSidebar/record-mic.svg';
import StopIcon from '../../../assets/icons/videoEditor/leftSidebar/record-stop.svg';
import { getDisplayTimeFromDuration } from '../utils/getDisplayTimeFromDuration';
import { getIsItemOnTimeline } from '../utils/getIsItemOnTimeline';
import SidebarTitle from './SidebarTitle';
import { PreviewContent } from './preview/Preview';
import { SidebarProps } from './LeftSidebar';

type Props = SidebarProps & {};
const ScreenRecordingSidebar = ({ previewPanelRef, setIsPreviewPanelVisible }: Props) => {
  const dispatch = useDispatch();

  const recordingTimelineItemId = useSelector(recordingTimelineItemIdSelector);

  const isPlaying = useSelector(isPlayingSeletor);
  const currentSeconds = useSelector(currentSecondsSeletor);
  const resolution = useSelector(videoResolutionSeletor);

  const [time, setTime] = useState(0);
  const startTsRef = useRef<number | null>(null);

  const mediaRecorderRef = useRef<MediaRecorder>(null);
  const videoRef = useRef(null);
  const streamRef = useRef<MediaStream | null>(null);

  const handleRecordVideo = useCallback(async () => {
    try {
      const startTs = Date.now();
      startTsRef.current = startTs;
      const end = currentSeconds + 0.1;

      const timelineItem: ITimelineItem = {
        id: uuid(),
        type: 'video',
        cloudAssetId: null,
        start: currentSeconds,
        end,
      };

      let timelineLayerId: string | null = null;
      dispatch(
        addToTimeline({
          timelineItem,
          start: currentSeconds,
          onAddToTimeline: (_) => {
            timelineLayerId = _.timelineLayer.id;
          },
        }),
      );
      dispatch(removeEmptyTimelineLayers());

      const undoId = uuid();
      dispatch(
        addUndo({
          id: undoId,
        }),
      );

      dispatch(setCurrentSeconds(end));

      const mediaRecorder = new MediaRecorder(streamRef.current);
      mediaRecorderRef.current = mediaRecorder;

      let chunks = [];

      mediaRecorder.ondataavailable = (event) => {
        chunks.push(event.data);
      };

      mediaRecorder.onstop = async () => {
        clearInterval(interval);
        setTime(0);

        const processingLabel = 'Video recording...';

        dispatch(
          setProcessingTimelineItemState({
            timelineItemId: timelineItem.id,
            label: processingLabel,
            status: 'PROCESSING',
          }),
        );

        dispatch(setRecordingTimelineItemId(null));

        const blob = new Blob(chunks, { type: 'video/webm' });
        const blobUrl = URL.createObjectURL(blob);

        const { duration, width, height } = await getVideoDuration(blobUrl);

        // create file from blob
        const file = new File([blob], 'video.webm', { type: 'video/webm' });

        const newEnd = currentSeconds + (Date.now() - startTs) / 1000;

        dispatch(setCurrentSeconds(newEnd));
        dispatch(
          updateTimelineItem({
            id: timelineItem.id,
            end: newEnd,
            width: 100,
            height: 100,
          }),
        );

        dispatch(updateTimelineMeta());

        dispatch(
          createCloudAsset({
            fileType: 'video',
            file,
            fileMeta: {
              name: 'Video recording',
              duration,
              width,
              height,
              mimeType: file.type,
            },
            originalSrc: 'recorded',
            originalData: {
              name: file.name,
              size: file.size,
              type: file.type,
            },
            onCacheReady: async ({ cloudAsset, cloudAssetCache }) => {
              dispatch(
                removeUndoRedoById({
                  id: undoId,
                }),
              );

              dispatch(
                setProcessingTimelineItemState({
                  timelineItemId: timelineItem.id,
                  label: processingLabel,
                  status: 'UPLOADING',
                }),
              );

              const isOnTimeline = getIsItemOnTimeline(timelineItem.id);

              if (!isOnTimeline) {
                return;
              }

              await cloneCloudAssetCacheForVideoTimelineItem(cloudAssetCache as ICloudAssetVideoCache, timelineItem.id);

              dispatch(
                updateTimelineItem({
                  id: timelineItem.id,
                  cloudAssetId: cloudAsset.id,
                }),
              );

              dispatch(
                addUndo({
                  id: undoId,
                }),
              );
              dispatch(saveVideo({}));

              // temporary until we set up CF
              // cloudAssetCache[timelineItem.id] = {
              //   blobUrl,
              //   audio: new Audio(blobUrl),
              // };
            },
            onCreate: () => {
              dispatch(saveVideo({}));
              dispatch(
                setProcessingTimelineItemState({
                  timelineItemId: timelineItem.id,
                  label: processingLabel,
                  status: 'DONE',
                }),
              );
              setTimeout(() => {
                dispatch(
                  deleteProcessingTimelineItemState({
                    timelineItemId: timelineItem.id,
                  }),
                );
              }, 5000);
            },
            onFail: (error: any) => {
              dispatch(
                logError({
                  event: 'handleRecordVideo: create cloud asset error',
                }),
              );

              dispatch(
                setProcessingTimelineItemState({
                  timelineItemId: timelineItem.id,
                  label: processingLabel,
                  status: 'ERROR',
                  error: {
                    details: error,
                  },
                }),
              );
            },
          }),
        );
      };

      mediaRecorder.start();

      const interval = setInterval(() => {
        const newEnd = currentSeconds + (Date.now() - startTs) / 1000;

        dispatch(
          updateTimelineItem({
            id: timelineItem.id,
            end: newEnd,
          }),
        );

        dispatch(
          updateTimelineItemLayer({
            timelineItemId: timelineItem.id,
            timelineLayerId,
            onChangeLayer: (_) => {
              timelineLayerId = _.timelineLayer.id;
            },
          }),
        );

        setTime(newEnd - currentSeconds);
      }, 30);

      dispatch(setRecordingTimelineItemId(timelineItem.id));

      dispatch(playVideo());
    } catch (error) {
      dispatch(
        logError({
          event: 'handleRecordVideo: error',
          data: { error },
        }),
      );
    }
  }, [currentSeconds, dispatch]);

  useEffect(() => {
    const run = async () => {
      streamRef.current = await navigator?.mediaDevices?.getDisplayMedia?.({
        video: true,
        audio: true,
      });
      videoRef.current.srcObject = streamRef.current;
    };
    run();

    return () => {
      streamRef.current?.getTracks().forEach((track) => {
        track.stop();
      });
    };
  }, [resolution]);

  useEffect(() => {
    setIsPreviewPanelVisible(true);

    return () => {
      setIsPreviewPanelVisible(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isPlaying) {
      mediaRecorderRef.current?.stop();
    }
  }, [isPlaying]);

  const handleClick = useCallback(() => {
    if (recordingTimelineItemId) {
      dispatch(setIsPlaying(false));
      return;
    }

    handleRecordVideo();
  }, [dispatch, handleRecordVideo, recordingTimelineItemId]);

  return (
    <>
      <S.Title>Record screen</S.Title>
      <S.ControlsContainer>
        <S.RecordButton onClick={handleClick}>{!recordingTimelineItemId ? <MicIcon /> : <StopIcon />}</S.RecordButton>
        <S.TimeContainer>{getDisplayTimeFromDuration(time)}</S.TimeContainer>
      </S.ControlsContainer>
      {previewPanelRef.current &&
        createPortal(
          <>
            <SidebarTitle title='Text to speech' bold={false} />
            <PreviewContent>
              <video
                ref={videoRef}
                // width='640'
                // height='480'
                autoPlay
                muted
              />
            </PreviewContent>
            {/* <PreviewControls onClose={handleClosePreview} /> */}
          </>,
          previewPanelRef.current,
        )}
    </>
  );
};
export default ScreenRecordingSidebar;

const S = {
  Title: styled.div`
    padding: 16px;
    font-size: 15px;
    font-weight: 700;
    font-family: ${defaultTheme.fontFamilies.Arimo};
  `,
  ControlsContainer: styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
  `,
  RecordButton: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 79px;
    height: 79px;
    background: linear-gradient(212.43deg, #fe00c1 15.05%, #ff3c27 83.3%);
    border-radius: 50%;
    cursor: pointer;
  `,
  TimeContainer: styled.div`
    position: absolute;
    top: 65%;
    font-size: 24px;
    font-weight: 400;
    font-family: ${defaultTheme.fontFamilies.Arimo};
  `,
};

const getVideoDuration = async (videoBlobUrl: string) => {
  const video = document.createElement('video');
  video.src = videoBlobUrl;

  const promise = new Promise<{
    duration: number;
    width: number;
    height: number;
  }>((resolve) => {
    video.onloadedmetadata = function () {
      // set the mediaElement.currentTime to a high value beyond its real duration
      video.currentTime = Number.MAX_SAFE_INTEGER;
      // listen to time position change
      video.ontimeupdate = function () {
        video.ontimeupdate = function () {};
        // setting player currentTime back to 0 can be buggy too, set it first to .1 sec
        video.currentTime = 0.1;
        video.currentTime = 0;

        URL.revokeObjectURL(videoBlobUrl);

        // media.duration should now have its correct value, return it...
        resolve({
          duration: video.duration,
          width: video.videoWidth,
          height: video.videoHeight,
        });
      };
    };
  });

  video.load();

  return promise;
};
