import Skeleton from '../../../../components/shared/Skeleton/Skeleton';
import React, { memo, useCallback } from 'react';
import styled, { css } from 'styled-components/native';
import { calcHeight, calcWidth, deviceWidth, isWeb } from '../../../../utils/dimensions';
import Divider from '../../../../components/shared/Divider';
import { getIsWebRtl } from '../../../../locale/i18n';

interface IProps {
  rowsNum?: number;
  valuesNum?: number;
}

const LoadingState: React.FC<IProps> = ({ rowsNum = 5, valuesNum = 3 }) => {
  const isRtl = getIsWebRtl();
  const renderComponent = useCallback(
    (_, index) => (
      <React.Fragment key={index}>
        <S.ListItem isRtl={isRtl}>
          <Skeleton width={100} height={20} borderRadius={4} />
          <S.IconsContainer isRtl={isRtl}>
            {Array(valuesNum)
              .fill(0)
              .map(() => (
                <S.IconContainer>
                  <S.ValueText>
                    <Skeleton width={30} height={20} borderRadius={4} />
                  </S.ValueText>
                </S.IconContainer>
              ))}
          </S.IconsContainer>
        </S.ListItem>
        <S.Divider />
      </React.Fragment>
    ),
    [isRtl, valuesNum],
  );

  return (
    <S.ListItemContainer>
      <S.Divider />
      {Array(rowsNum).fill(0).map(renderComponent)}
    </S.ListItemContainer>
  );
};

const S = {
  ListItemContainer: styled.View`
    padding: 0 ${calcWidth(19)}px;
  `,
  ListItem: styled.View<{ isRtl: boolean }>`
    flex-direction: ${({ isRtl }) => (isRtl ? 'row-reverse' : 'row')};
    justify-content: space-between;
    align-items: center;
    height: ${calcHeight(44)}px;
  `,
  IconsContainer: styled.View<{ isRtl: boolean }>`
    flex: 1;
    flex-direction: ${({ isRtl }) => (isRtl ? 'row-reverse' : 'row')};
    width: ${isWeb ? calcWidth(512) : deviceWidth - calcWidth(38)}px;
    justify-content: flex-end;
    align-items: center;
    ${({ isRtl }) =>
      isRtl
        ? css`
            margin-right: ${calcWidth(10)}px;
          `
        : css`
            margin-left: ${calcWidth(10)}px;
          `}
  `,
  IconContainer: styled.View`
    align-items: center;
    height: ${calcHeight(24)}px;
    width: ${calcWidth(30)}px;
    margin-left: ${calcWidth(12)}px;
    justify-content: center;
    width: ${calcWidth(60)}px;
    margin-left: ${calcWidth(6)}px;
  `,
  ValueText: styled.View`
    align-items: center;
  `,
  Divider: styled(Divider)`
    width: 100%;
    background-color: ${({ theme }) => theme.colors.lightGray1};
  `,
};

export default memo(LoadingState);
