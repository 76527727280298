import React, { useCallback } from 'react';
import styled from 'styled-components';
import defaultTheme from '../../../themes/defaultTheme';
import { useDispatch, useSelector } from 'react-redux';
import {
  setGenerationExtractedAssetsWalkme,
  setHighlightLeftSidebar,
} from '../../../store/videoEditor/videoEditor.slice';
import { generationExtractedAssetsWalkmeSelector } from '../../../store/videoEditor/videoEditor.selectors';
import Button from '../../../components/shared/buttons/Button/Button';
import {
  PRIMARY_SIDEBAR_WIDTH,
  SECONDARY_SIDEBAR_WIDTH,
  SPACING,
  TIMELINE_CONTAINER_HEIGHT,
  TIMELINE_CONTROLS_HEIGHT,
} from '../videoEditor.constants';

const buttonStyle = {
  paddingLeft: 16,
  paddingRight: 16,
  height: 35,
};

type Props = {};
export const GenerationExtractedAssetsWalkmeModal = ({}: Props) => {
  const dispatch = useDispatch();

  const generationExtractedAssetsWalkme = useSelector(generationExtractedAssetsWalkmeSelector);

  const handleNext = useCallback(() => {
    dispatch(setHighlightLeftSidebar(false));
    dispatch(setGenerationExtractedAssetsWalkme(false));
  }, [dispatch]);

  const handleBgClick = useCallback((e) => {
    e.stopPropagation();
  }, []);

  const timelintBottom = TIMELINE_CONTAINER_HEIGHT + TIMELINE_CONTROLS_HEIGHT + SPACING;
  const availableHeightSpace = window.innerHeight - timelintBottom - SPACING;
  const top = SPACING + availableHeightSpace / 2;
  const left = SPACING + PRIMARY_SIDEBAR_WIDTH + SECONDARY_SIDEBAR_WIDTH + 10;

  if (!generationExtractedAssetsWalkme) {
    return null;
  }

  return (
    <S.Modal onClick={handleBgClick}>
      <S.Container top={top} left={left}>
        <div>Here you can find image assets extracted from you PDF</div>
        <S.Spacing />
        <div>You can add them to the video timeline</div>
        <S.Spacing n={2} />
        <S.Controls>
          <Button
            text='Done'
            textColor={defaultTheme.colors.primaryBlue}
            border={defaultTheme.colors.primaryBlue}
            fill={defaultTheme.colors.transparent}
            height={35}
            style={buttonStyle}
            onPress={handleNext}
          />
        </S.Controls>
        <S.Arrow />
      </S.Container>
    </S.Modal>
  );
};

const S = {
  Modal: styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
  `,
  Container: styled.div<{ top: number; left: number }>`
    position: absolute;
    top: ${({ top }) => top}px;
    left: ${({ left }) => left}px;
    padding: 16px;
    max-width: 300px;
    font-size: 16px;
    font-family: ${defaultTheme.fontFamilies.Arimo};
    border-radius: 16px;
    background-color: ${defaultTheme.colors.white};
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.2);
    transform: translateY(-50%);
  `,
  Arrow: styled.div`
    position: absolute;
    top: 50%;
    left: -10px;
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right: 10px solid white;
    transform: translateY(-50%);
  `,
  Spacing: styled.div<{ n?: number }>`
    height: ${({ n = 1 }) => n * 4}px;
  `,
  Controls: styled.div`
    display: flex;
    justify-content: flex-end;
  `,
};
