import React, { useCallback } from 'react';
import styled from 'styled-components';
import defaultTheme from '../../../themes/defaultTheme';
import { useDispatch, useSelector } from 'react-redux';
import {
  setGenerationExtractedAssetsWalkme,
  setHighlightLeftSidebar,
  setHighlightSelectedTimelineItem,
  setReplacingSelectedTimelineItemWalkme,
} from '../../../store/videoEditor/videoEditor.slice';
import {
  highlightSelectedTimelineItemPositionSelector,
  replacingSelectedTimelineItemWalkmeSelector,
} from '../../../store/videoEditor/videoEditor.selectors';
import Button from '../../../components/shared/buttons/Button/Button';
import BulbIcon from '../../../assets/icons/videoEditor/bulb.svg';

const buttonStyle = {
  paddingLeft: 16,
  paddingRight: 16,
  height: 35,
};

type Props = {};
export const ReplacingSelectedTimelineItemWalkmeModal = ({}: Props) => {
  const dispatch = useDispatch();

  const replacingSelectedTimelineItemWalkme = useSelector(replacingSelectedTimelineItemWalkmeSelector);
  const highlightSelectedTimelineItemPosition = useSelector(highlightSelectedTimelineItemPositionSelector);

  const handleNext = useCallback(() => {
    dispatch(setHighlightSelectedTimelineItem(false));
    dispatch(setReplacingSelectedTimelineItemWalkme(false));
    dispatch(setHighlightLeftSidebar(true));
    dispatch(setGenerationExtractedAssetsWalkme(true));
  }, [dispatch]);

  const handleBgClick = useCallback((e) => {
    e.stopPropagation();
  }, []);

  if (!highlightSelectedTimelineItemPosition || !replacingSelectedTimelineItemWalkme) {
    return null;
  }

  return (
    <S.Modal onClick={handleBgClick}>
      <S.Container
        left={highlightSelectedTimelineItemPosition.screenX}
        bottom={window.innerHeight - highlightSelectedTimelineItemPosition.screenY}
        width={highlightSelectedTimelineItemPosition.width}
      >
        <div>Video assets were automatically selected for you</div>
        <S.Spacing />
        <div>
          Click on the <BulbIcon /> icon to replace the video asset
        </div>
        <S.Spacing n={2} />
        <S.Controls>
          <Button
            text='Next'
            textColor={defaultTheme.colors.primaryBlue}
            border={defaultTheme.colors.primaryBlue}
            fill={defaultTheme.colors.transparent}
            height={35}
            style={buttonStyle}
            onPress={handleNext}
          />
        </S.Controls>
        <S.Arrow />
      </S.Container>
    </S.Modal>
  );
};

const S = {
  Modal: styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
  `,
  Container: styled.div<{ left: number; bottom: number; width: number }>`
    position: absolute;
    left: ${({ left, width }) => left + width / 2}px;
    bottom: ${({ bottom }) => bottom + 10}px;
    padding: 16px;
    max-width: 300px;
    font-size: 16px;
    font-family: ${defaultTheme.fontFamilies.Arimo};
    border-radius: 16px;
    background-color: ${defaultTheme.colors.white};
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.2);
    transform: translateX(-50%);
  `,
  Arrow: styled.div`
    position: absolute;
    left: 50%;
    bottom: -10px;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid white;
    transform: translateX(-50%);
  `,
  Spacing: styled.div<{ n?: number }>`
    height: ${({ n = 1 }) => n * 4}px;
  `,
  Controls: styled.div`
    display: flex;
    justify-content: flex-end;
  `,
};
