import { DefaultTheme } from 'styled-components/native';
import { calcFontSize } from '../utils/dimensions';

export const colors = {
  white: '#fff',
  black: 'rgb(0,0,0)',
  transparentBlack: 'rgba(0, 0, 0, 0.3)',
  transparentBlack2: 'rgba(0, 0, 0, 0.5)',
  transparentWhite: 'rgba(255, 255, 255, 0.7)',
  transparentWhite2: 'rgba(246, 246, 246, 0.98)',
  transparentGray1: 'rgba(240, 240, 240, 0.93)',
  primaryBlue: '#1253fa',
  lightPrimaryBlue: '#5685FD',
  lightBlue: '#eaf1ff',
  darkerPrimaryBlue: '#1154FA',
  darkerPrimaryBlue2: '#001CF5',
  blue2: 'lightblue',
  blue3: 'rgba(18, 83, 250, 0.7)',
  blue4: '#1252fa',
  blue5: '#0500FF',
  blue6: '#1B5AF6',
  transparentBlue: 'rgba(18, 83, 250, .75)',
  darkPrimary: '#344356',
  darkShadow: 'rgba(60,128,209,0.09)',
  lightBackground: 'rgba(243,245,249,0.5)',
  lightBackground2: 'rgba(214, 214, 214, .8)',
  textInput: '#f3f5f9',
  borderGray: '#4d4d4d',
  blueInputBg: '#0d42f8',
  blueIcon: '#1352fa',
  darkBlueBg: '#000925',
  defaultGrayBg: '#F4F0F5',
  darkGray1: '#304358',
  darkGray2: '#5C6977',
  darkGray3: '#AEAEB2',
  darkGray4: '#808080',
  darkGray5: '#868686',
  darkGray6: '#4d4d4d',
  darkGray7: '#b3b3b3',
  darkGray8: '#6c6c6c',
  darkGray9: '#454545',
  darkGray10: '#484749',
  darkGray11: '#919191',
  darkGray12: '#78767a',
  darkGray13: '#696969',
  darkGray14: '#707070',
  darkGray15: '#6a6a6a',
  darkGray16: '#585858',
  darkGray17: '#242529',
  gray1: '#7c7c7c',
  gray2: '#939292',
  gray3: '#262525',
  gray4: '#898989',
  gray5: '#a3a3a3',
  gray7: '#a8a8a8',
  gray8: '#d1d1d1',
  gray9: '#666666',
  gray10: '#a5a5a5',
  gray11: '#707070',
  gray12: '#2e2e2e',
  gray13: '#757575',
  gray14: '#6A6A6A',
  gray15: '#575756',
  gray16: '#f1f3f5',
  gray17: '#e0e0e0',
  gray18: '#787878',
  gray19: '#707175',
  gray20: '#ADB0BD',
  gray21: '#dee1e3',
  gray22: '#cdd1d4',
  gray23: '#5a5a5a',
  gray24: '#b0b2b8',
  gray25: '#56575b',
  gray26: '#f4f6fc',
  gray27: '#d2dfe7',
  gray28: '#DADBDE',
  gray29: '#e5e5e5',
  gray30: '#EFEFEF',
  lightGray1: '#E6E6E6',
  lightGray2: '#AEB4BC',
  lightGray3: '#F3F5F9',
  lightGray4: '#3D4B5D',
  lightGray5: '#C3C3C3',
  lightGray6: '#94979A',
  lightGray7: '#E8EEF4',
  lightGray8: '#858E99',
  lightGray9: '#CACACA',
  lightGray10: '#596775',
  lightGray11: '#F3F5F9',
  lightGray12: '#B3B3B3',
  lightGray13: '#F2F4F8',
  lightGray14: '#999999',
  lightGray15: '#c7c7c7',
  lightGray16: '#cccccc',
  lightGray17: '#535353',
  lightGray18: '#E8E8E8',
  lightGray19: '#c9c9c9',
  lightGray20: '#878787',
  lightGray21: '#888888',
  lightGray22: '#777777',
  lightGray23: '#d0d0d0',
  lightGray24: '#f5f5f5',
  lightGray25: '#f0f0f0',
  lightGray26: '#909090',
  lightGray27: '#a2a2a2',
  lightGray28: '#717171',
  lightGray29: '#a0a0a0',
  lightGray30: '#f2f2f2',
  lightGray31: '#acacac',
  lightGray32: '#e5e5e5',
  lightGray33: '#ddd',
  lightGray34: '#d9d9d9',
  lightGray35: '#c4c4c4',
  lightGray36: '#edf0f1',
  lightGray37: '#f3f4f6',
  lightGray38: '#d5d5d5',
  lightGray39: '#b2b3b8',
  lightGray40: '#575757',
  lightGray41: '#EDEDF2',
  lightGray42: '#c6c6c8',
  lightGray43: '#efeff3',
  lightGray44: '#efeff4',
  lightGray45: '#DFDFDF',
  lightGray46: '#707174',
  lightGray47: '#f3f3f5',
  lightGray48: '#B3B4BA',
  lightGray49: '#979797',
  transparentGray: 'rgba(94, 96, 104, .75)',
  warning: '#FF284E',
  red1: '#fc1313',
  red2: '#FF0000',
  failRed: '#B53333',
  lightRed: '#F34357',
  lightblue1: '#E7EEF5',
  lightblue2: '#DFE6F9',
  lightblue3: '#3e6ef7',
  lightblue4: '#dbe1f6',
  lightblue5: '#f2f5f9',
  lightblue6: '#0285d4',
  lightText: '#999999',
  teamsPurple: '#5359af',
  backgroundPurple: '#664ff9',

  yellowChoose: '#e9de1d',
  yellowSticky: '#fbf7bf',
  yellowWarning: '#ffc107',

  violet1: '#BC54BE',
  violet2: '#5802D5',

  orange1: '#FE9900',
  orange2: '#FE6700',
  orangeChoose: '#e99c1d',
  orangeSticky: '#ffe4cb',
  redChoose: '#f76868',
  redSticky: '#ffc5c5',
  buttonPink: '#f0489f',
  pink: '#ffd2e9',
  pinkDark: '#ce0a70',
  pinkChoose: '#ff3ba0',
  pinkSticky: '#ffd5eb',
  pinkError: '#FE00C1',
  transparentPink: 'rgba(206, 10, 111, 0.75)',
  purpleChoose: '#a340f2',
  purpleSticky: '#e0c5f6',
  blueChoose: '#1d9ce9',
  blueSticky: '#cceafc',
  turqChoose: '#1de9c3',
  turqSticky: '#c8fef4',
  green1: '#62BD00',
  green2: '#17C868',
  greenChoose: '#9ce91d',
  greenSticky: '#e0f5bc',
  text: '#313235',
  shadowColor: 'rgba(106, 105, 125, 0.1)',
  shadowColor2: 'rgba(0, 0, 0, 0.2)',
  overviewBackground: '#f3f6fd',
  overviewDivider: '#E1E1E1',
  overviewDividerTransparent: 'rgba(225, 225, 225, 0.3)',
  questionnaireGradient: ['#dbe3f6', '#e6e3f7'],
  disabledButtonGradient: ['#fdfdfd', '#fbfbfb'],
  fileBackground: '#EAF0FF',
  fileBackgroundSuccess: '#E4F2D5',
  fileBackgroundError: '#FFE0E0',
  fileSuccessText: '#4B9000',
  fileErrorText: '#F31414',
  toggleButtonActiveBackground: 'rgba(224, 233, 255, 0.98)',
  deleteButton: '#FF3C27',
  feedProjectBite: '#D6D6D6',
  totalBarBackground: '#EDF3FF',
};

export const fontSizes = {
  s9: calcFontSize(9),
  s10: calcFontSize(10),
  s11: calcFontSize(11),
  s11b: calcFontSize(11.5),
  s12: calcFontSize(12),
  s13: calcFontSize(13),
  s14: calcFontSize(14),
  s15: calcFontSize(15),
  s16: calcFontSize(16),
  s17: calcFontSize(17),
  s18: calcFontSize(18),
  s19: calcFontSize(19),
  s20: calcFontSize(20),
  s21: calcFontSize(21),
  s22: calcFontSize(22),
  s24: calcFontSize(24),
  s26: calcFontSize(26),
  s28: calcFontSize(28),
  s32: calcFontSize(32),
  s34: calcFontSize(34),
  s36: calcFontSize(36),
  s40: calcFontSize(40),
  s44: calcFontSize(44),
};

export const fontFamilies = {
  GilroyBold: 'Gilroy-Bold',
  GilroyMedium: 'Gilroy-Medium',
  LucidaGrande: 'LucidaGrande',
  SFProRegular: 'SFProText-Regular',
  SFProSemibold: 'SFProText-Semibold',
  TTNormsMedium: 'TTNorms-Medium',
  UniSans: 'UniSans',
  AmaticSCRegular: 'AmaticSC-Regular',
  FrankRuhlLibreRegular: 'FrankRuhlLibre-Regular',
  FredokaRegular: 'Fredoka-Regular',
  Arimo: 'Arimo-Regular',
  ArimoBold: 'Arimo-Bold',
  OpenSansHebrew: 'OpenSansHebrew-Regular',
  Ubuntu: 'Ubuntu-Regular',
  UbuntuBold: 'Ubuntu-Bold',
};

const defaultTheme: DefaultTheme = {
  colors,
  fontSizes,
  fontFamilies,
};

export interface ITheme {
  theme: typeof defaultTheme;
}

export default defaultTheme;
