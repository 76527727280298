import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  biteDisplayModeSelector,
  guestsAttributeSelector,
  isGuestsAttributeLoadedSelector,
  biteSharedWithFilterSelector,
} from '../../../../store/attributes/biteAttributes/biteAttributes.selector';
import React, { useCallback, useEffect, useMemo } from 'react';
import { log } from '../../../../store/appActivity/appActivity.slice';
import { getGuestsAttribute } from '../../../../store/attributes/biteAttributes/biteAttributes.slice';
import { ISection } from '../../common/AttributesList/AttributesList';
import SectionHeader from '../../common/AttributesList/SectionHeader';
import AttributeValueItem from './BiteAttributeValueItem';
import styled from 'styled-components/native';
import { useIsFocused } from '@react-navigation/native';
import { EAnalyticsScreenType } from '../../Analytics.types';
import { ESharedWithFilter } from '../../../../types/anayltics';
import { IBiteAttributeValue } from '../../../../store/attributes/biteAttributes/biteAttributes.types';
import useShowIcons from '../hooks/useShowIcons';

interface IProps {
  isRefreshing?: boolean;
  currentTab: EAnalyticsScreenType;
  customData?: { data: IBiteAttributeValue; isLoading: boolean; error?: string };
}

const GuestsAttribute: React.FC<IProps> = ({ isRefreshing, currentTab, customData }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isFocused = useIsFocused();

  const { showViews, showAnswers, showComments } = useShowIcons({ currentTab });

  const sharedWithFilter = useSelector(biteSharedWithFilterSelector);
  const displayMode = useSelector(biteDisplayModeSelector);
  const isGuestsAttributeLoaded = useSelector(isGuestsAttributeLoadedSelector(currentTab));
  const guestsAttribute = useSelector(guestsAttributeSelector);
  const { data, isLoading, error } = customData || guestsAttribute;

  const handleLoad = useCallback(() => {
    dispatch(
      log({
        event: 'GuestsAttribute.handleLoad',
      }),
    );

    dispatch(getGuestsAttribute({ currentTab }));
  }, [dispatch, currentTab]);

  const formattedHeaderValue: ISection = useMemo(
    () => ({
      title: t('analytics.overview.guests'),
      data: data ? [data] : [],
      isLoading,
      isError: !!error,
      isDisplaySkeleton: isLoading && !isRefreshing,
    }),
    [data, error, isLoading, isRefreshing, t],
  );

  const formattedValue: IBiteAttributeValue = useMemo(
    () => ({
      id: 1,
      attribute_title_id: 1,
      name: t('analytics.overview.guests'),
      views_absolute: data?.views_absolute,
      answers_absolute: data?.answers_absolute,
      comments_absolute: data?.comments_absolute,
      total_users_count: data?.total_users_count,
    }),
    [data?.answers_absolute, data?.comments_absolute, data?.total_users_count, data?.views_absolute, t],
  );

  useEffect(() => {
    if (!isFocused || isLoading || isGuestsAttributeLoaded) {
      return;
    }
    handleLoad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFocused, isGuestsAttributeLoaded]);

  if (
    formattedValue.total_users_count === 0 ||
    (sharedWithFilter[ESharedWithFilter.COMMUNICATION_HUB] && !sharedWithFilter[ESharedWithFilter.ORGANICALLY])
  ) {
    return null;
  }

  return (
    <S.Attribute>
      <SectionHeader section={formattedHeaderValue} onRefresh={handleLoad} skeletonRowsNum={1} hasOnlyOneAttribute />
      {(!isLoading || isRefreshing) && !error && (
        <AttributeValueItem
          isViewsTabEnabled={showViews}
          isAnsweredTabEnabled={showAnswers}
          isCommentsTabEnabled={showComments}
          displayMode={displayMode}
          item={formattedValue}
        />
      )}
    </S.Attribute>
  );
};

const S = {
  Attribute: styled.View``,
  AttributeName: styled.Text`
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: ${({ theme }) => theme.colors.black};
  `,
  AttributeValue: styled.Text`
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
  `,
};

export default GuestsAttribute;
