import React, { memo, useCallback, useEffect, useMemo } from 'react';
import styled, { css } from 'styled-components/native';
import { useDispatch, useSelector } from 'react-redux';
import { playlistDisplayModeSelector } from '../../../../../store/attributes/playlistAttributes/playlistAttributes.selector';
import { calcHeight, calcWidth, deviceWidth, isWeb } from '../../../../../utils/dimensions';
import { getIsWebRtl } from '../../../../../locale/i18n';
import { absoluteValueTextDataSet, percentageValueTextDataSet } from '../../../analytics.constants';
import { EAnalyticsContentType } from '../../../../../store/analytics/analytics.types';
import { contentTypeSelector } from '../../../../../store/analytics/analytics.selector';
import {
  playlistQuestionNumberSelector,
  playlistTotalDataSelector,
  playlistTotalErrorSelector,
  playlistTotalLoadingSelector,
} from '../../../../../store/attributes/playlistStats/playlistStats.selector';
import useCalculatePlaylistStats from '../../../playlist/hooks/useCalculatePlaylistStats';
import Skeleton from '../../../../../components/shared/Skeleton';
import TotalBarErrorIcon from '../../../../../assets/icons/total-bar-error.svg';
import { useTranslation } from 'react-i18next';
import { getTotalData, resetTotalData } from '../../../../../store/attributes/playlistStats/playlistStats.slice';

const PlaylistTotalBar = ({ isUsers }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isWebRtl = getIsWebRtl();

  const displayMode = useSelector(playlistDisplayModeSelector);
  const contentType = useSelector(contentTypeSelector);
  const playlistQuestionNumber = useSelector(playlistQuestionNumberSelector);

  const data = useSelector(playlistTotalDataSelector);
  const isLoading = useSelector(playlistTotalLoadingSelector);
  const error = useSelector(playlistTotalErrorSelector);

  const stats = useCalculatePlaylistStats(data);

  useEffect(() => {
    if (data !== null || isLoading || error) {
      return;
    }

    dispatch(getTotalData());
  }, [data, dispatch, error, isLoading]);

  const handleReload = useCallback(() => {
    dispatch(resetTotalData());
  }, [dispatch]);

  const renderValues = useMemo(() => {
    if (isUsers) {
      return (
        <S.UsersDataContainer isRtl={isWebRtl}>
          {contentType === EAnalyticsContentType.PLAYLIST && (
            <>
              <S.UsersValueContainer>
                <S.ValueText>{stats.progressRate}%</S.ValueText>
              </S.UsersValueContainer>
              <S.VerticalDivider />
            </>
          )}
          <S.UsersValueContainer>
            <S.ValueText bold>{playlistQuestionNumber ? `${stats.success}%` : '-'}</S.ValueText>
          </S.UsersValueContainer>
        </S.UsersDataContainer>
      );
    }

    return (
      <S.DataContainer isRtl={isWebRtl}>
        <S.ValueContainer>
          {displayMode === 'absolute' ? (
            <>
              <S.ValueText
                // @ts-ignore
                dataSet={absoluteValueTextDataSet}
              >
                {stats.started}
              </S.ValueText>
              {displayMode === 'absolute' && <S.TotalValueText>({stats.totalUsersCount})</S.TotalValueText>}
            </>
          ) : (
            <S.ValueText
              // @ts-ignore
              dataSet={percentageValueTextDataSet}
            >
              {stats.startedRate}%
            </S.ValueText>
          )}
        </S.ValueContainer>
        <S.VerticalDivider />
        {contentType === EAnalyticsContentType.PLAYLIST && (
          <>
            <S.ValueContainer>
              <S.ValueText>{stats.progressRate}%</S.ValueText>
              {displayMode === 'absolute' && <S.EmptyView />}
            </S.ValueContainer>
            <S.VerticalDivider />
          </>
        )}
        <S.ValueContainer>
          <S.ValueText>{displayMode === 'absolute' ? stats.completed : `${stats.completedRate}%`}</S.ValueText>
          {displayMode === 'absolute' && <S.TotalValueText>({stats.started})</S.TotalValueText>}
        </S.ValueContainer>
        <S.VerticalDivider />
        <S.ValueContainer>
          <S.ValueText bold>{playlistQuestionNumber ? `${stats.success}%` : '-'}</S.ValueText>
          {displayMode === 'absolute' && <S.EmptyView />}
        </S.ValueContainer>
      </S.DataContainer>
    );
  }, [
    isUsers,
    isWebRtl,
    displayMode,
    stats.started,
    stats.totalUsersCount,
    stats.startedRate,
    stats.progressRate,
    stats.completed,
    stats.completedRate,
    stats.success,
    contentType,
    playlistQuestionNumber,
  ]);

  return (
    <S.Container isRtl={isWebRtl} isUsers={isUsers}>
      <S.LeftSide isRtl={isWebRtl}>
        <S.TotalText>{t('common.total')}</S.TotalText>

        {!!error && (
          <S.ReloadButton isRtl={isWebRtl} onPress={handleReload}>
            <S.ReloadButtonText>{t('common.reload')}</S.ReloadButtonText>
          </S.ReloadButton>
        )}
      </S.LeftSide>
      {error ? (
        <TotalBarErrorState isRtl={isWebRtl} isUsers={isUsers} playlistQuestionNumber={playlistQuestionNumber} />
      ) : isLoading ? (
        <TotalBarLoadingState isRtl={isWebRtl} isUsers={isUsers} playlistQuestionNumber={playlistQuestionNumber} />
      ) : (
        renderValues
      )}
    </S.Container>
  );
};

interface ITotalBarErrorStateProps {
  isUsers: boolean;
  isRtl: boolean;
  playlistQuestionNumber: number;
}

const TotalBarErrorState: React.FC<ITotalBarErrorStateProps> = ({ isUsers, isRtl, playlistQuestionNumber }) => {
  if (isUsers) {
    return (
      <S.UsersDataContainer isRtl={isRtl}>
        <S.UsersValueContainer>
          <TotalBarErrorIcon />
        </S.UsersValueContainer>
        <S.UsersValueContainer>
          <S.ValueText>{playlistQuestionNumber ? <TotalBarErrorIcon /> : '-'}</S.ValueText>
        </S.UsersValueContainer>
      </S.UsersDataContainer>
    );
  }

  return (
    <S.DataContainer isRtl={isRtl}>
      <S.ValueContainer>
        <TotalBarErrorIcon />
      </S.ValueContainer>
      <S.ValueContainer>
        <TotalBarErrorIcon />
      </S.ValueContainer>
      <S.ValueContainer>
        <TotalBarErrorIcon />
      </S.ValueContainer>
      <S.ValueContainer>
        <S.ValueText>{playlistQuestionNumber ? <TotalBarErrorIcon /> : '-'}</S.ValueText>
      </S.ValueContainer>
    </S.DataContainer>
  );
};

interface ITotalBarLoadingStateProps {
  isUsers: boolean;
  isRtl: boolean;
  playlistQuestionNumber: number;
}

const TotalBarLoadingState: React.FC<ITotalBarLoadingStateProps> = ({ isUsers, isRtl, playlistQuestionNumber }) => {
  if (isUsers) {
    return (
      <S.UsersDataContainer isRtl={isRtl}>
        <S.UsersValueContainer>
          <Skeleton width={30} height={18} />
        </S.UsersValueContainer>
        <S.UsersValueContainer>
          <S.ValueText>{playlistQuestionNumber ? <Skeleton width={30} height={18} /> : '-'}</S.ValueText>
        </S.UsersValueContainer>
      </S.UsersDataContainer>
    );
  }

  return (
    <S.DataContainer isRtl={isRtl}>
      <S.ValueContainer>
        <Skeleton width={30} height={18} />
      </S.ValueContainer>
      <S.ValueContainer>
        <Skeleton width={30} height={18} />
      </S.ValueContainer>
      <S.ValueContainer>
        <Skeleton width={30} height={18} />
      </S.ValueContainer>
      <S.ValueContainer>
        <S.ValueText>{playlistQuestionNumber ? <Skeleton width={30} height={18} /> : '-'}</S.ValueText>
      </S.ValueContainer>
    </S.DataContainer>
  );
};

const S = {
  Container: styled.View<{ isRtl?: boolean; isUsers?: boolean }>`
    height: 66px;
    width: ${isWeb ? '530px' : `${deviceWidth - 20}px`};
    padding: 13px 10px;
    margin: 14px 10px ${({ isUsers }) => (isUsers ? calcHeight(28) : '0')}px;
    flex-shrink: 0;

    flex-direction: ${({ isRtl }) => (isRtl ? 'row-reverse' : 'row')};
    align-items: center;

    border-radius: 11px;
    background-color: ${({ theme }) => theme.colors.totalBarBackground};
    justify-content: space-between;
  `,
  LeftSide: styled.View<{ isRtl: boolean }>`
    flex-direction: ${({ isRtl }) => (isRtl ? 'row-reverse' : 'row')};
  `,
  TotalText: styled.Text`
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 14px; /* 93.333% */
  `,
  DataContainer: styled.View`
    flex: 1;
    flex-direction: ${({ isRtl }) => (isRtl ? 'row-reverse' : 'row')};
    width: ${isWeb ? calcWidth(512) : deviceWidth - calcWidth(38)}px;
    justify-content: flex-end;
    align-items: center;
    ${({ isRtl }) =>
      isRtl
        ? css`
            margin-right: ${calcWidth(10)}px;
          `
        : css`
            margin-left: ${calcWidth(10)}px;
          `}
  `,
  ValueContainer: styled.View`
    align-items: center;
    height: ${calcHeight(24)}px;
    justify-content: center;
    width: ${calcWidth(50)}px;
  `,
  UsersValueContainer: styled.View`
    align-items: center;
    height: ${calcHeight(24)}px;
    justify-content: center;
    width: ${calcWidth(50)}px;
    margin: 0 10px;
  `,
  ValueText: styled.Text<{ bold?: boolean }>`
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    text-align: center;
    font-weight: ${({ bold }) => (bold ? 700 : 400)};
    color: ${({ theme }) => theme.colors.text};
    font-size: ${({ theme }) => theme.fontSizes.s14}px;
    line-height: ${({ theme }) => theme.fontSizes.s14}px;
  `,
  TotalValueText: styled.Text`
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    text-align: center;
    font-weight: ${({ bold }) => (bold ? 700 : 400)};
    color: #7e7e7e;
    font-size: ${({ theme }) => theme.fontSizes.s12}px;
    line-height: ${({ theme }) => theme.fontSizes.s14}px;
  `,
  UsersDataContainer: styled.View<{ isRtl: boolean }>`
    flex-direction: ${({ isRtl }) => (isRtl ? 'row-reverse' : 'row')};
    align-items: center;
    justify-content: space-between;
    margin-right: -10px;
    margin-top: 0;
  `,
  EmptyView: styled.View`
    height: ${calcHeight(15)}px;
  `,
  VerticalDivider: styled.View`
    width: 1px;
    height: 39px;
    background-color: ${({ theme }) => theme.colors.overviewDivider};
  `,
  ReloadButton: styled.TouchableOpacity<{ isRtl?: boolean }>`
    ${({ isRtl }) =>
      isRtl
        ? css`
            margin-right: 32px;
          `
        : css`
            margin-left: 32px;
          `}
  `,
  ReloadButtonText: styled.Text`
    color: ${({ theme }) => theme.colors.primaryBlue};
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    text-decoration-line: underline;
    text-decoration-style: solid;
    text-decoration-skip-ink: auto;
    text-decoration-thickness: auto;
    text-underline-offset: auto;
    text-underline-position: from-font;
  `,
};

export default memo(PlaylistTotalBar);
