import { all, put, select, spawn, takeEvery, takeLatest } from 'redux-saga/effects';
import {
  createGeneration,
  INITIAL_GENERATION_ID,
  loadGenerationCloudAssets,
  openProject,
  resetEditGeneration,
  setEditGenerationId,
  setGenerationConfig,
  startGenerationPolling,
  setGenerationCloudAssets,
  saveAndRunGeneration,
  generateTopics,
} from './editAiGeneration.slice';
import { cloudAssetsSelector, editGenerationIdSelector, generationConfigSelector } from './editAiGeneration.selector';
import withRetry from '../../utils/withRetry';
import * as calls from '../api/bites-api/calls/aiGeneration.calls';
import { activeOrganizationSelector } from '../auth/auth.selectors';
import { IAction } from '../common/types';
import { getCloudAsset } from '../api/bites-api/calls/cloudAssets.calls';
import { generationByIdSelector, isGenerationLoadingSelector } from '../aiGeneration/aiGeneration.selector';
import { EGenerationStatus, IGeneration } from '../aiGeneration/aiGeneration.types';
import {
  removeGeneration,
  setGeneration,
  setGenerationError,
  setGenerationLoading,
  unshiftGenerationToFeed,
} from '../aiGeneration/aiGeneration.slice';
import { setGenerationScripts } from '../aiGenerationScripts/aiGenerationScripts.slice';
import Routes from '../../navigation/routes';
import { navigate } from '../../navigation/RootNavigation';
import { ILocalCloudAsset } from './editAiGeneration.types';

function* createGenerationSaga() {
  const { id: orgId } = yield select(activeOrganizationSelector);
  const { inputCloudAssetIds, withIntro, withBgMusic, voiceId } = yield select(generationConfigSelector);
  const editGenerationId = yield select(editGenerationIdSelector);

  try {
    yield put(setGenerationLoading({ generationId: editGenerationId, state: true }));

    const {
      data: { generation },
    } = yield withRetry(
      () =>
        calls.createGeneration({
          orgId,
          generation: {
            inputCloudAssetIds,
            topics: null,
            withIntro,
            withBgMusic,
            voiceId,
          },
        }),
      {
        errorContext: {
          data: {
            action: 'createGenerationSaga: createGeneration',
          },
        },
      },
    );

    yield put(setGeneration(generation));
    yield put(setEditGenerationId(generation.id));
    yield put(
      unshiftGenerationToFeed({
        generation,
      }),
    );

    yield spawn(generateTopicsSaga);
    yield put(setGenerationLoading({ generationId: INITIAL_GENERATION_ID, state: false }));
  } catch (e) {
    yield put(setGenerationError({ generationId: editGenerationId, error: e.message }));
  }
}

function* generateTopicsSaga() {
  const { id: orgId } = yield select(activeOrganizationSelector);
  const editGenerationId = yield select(editGenerationIdSelector);

  try {
    yield put(setGenerationLoading({ generationId: editGenerationId, state: true }));

    yield withRetry(
      () =>
        calls.generateTopics({
          orgId,
          generationId: editGenerationId,
        }),
      {
        errorContext: {
          data: {
            action: 'generateTopicsSaga: generateTopics',
          },
        },
      },
    );

    yield spawn(startGenerationStatusPollingSaga, {
      payload: { generationId: editGenerationId, ignoreLoadingState: true },
    });
  } catch (e) {
    yield put(setGenerationError({ generationId: editGenerationId, error: e.message }));
  }
}

function* saveAndRunGenerationSaga() {
  const { id: orgId } = yield select(activeOrganizationSelector);
  const { topics, inputCloudAssetIds, withIntro, withBgMusic, voiceId } = yield select(generationConfigSelector);
  const editGenerationId = yield select(editGenerationIdSelector);
  const editGeneration: IGeneration = yield select(generationByIdSelector(editGenerationId));
  const cleanTopics = topics.map((topic) => (topic.trim().length > 0 ? topic.trim() : null)).filter(Boolean);

  try {
    yield put(setGenerationLoading({ generationId: editGenerationId, state: true }));

    const generationData: Omit<
      IGeneration,
      'id' | 'regeneratedFromId' | 'orgId' | 'creatorId' | 'withOuttro' | 'createDate' | 'updateDate'
    > = {
      name: editGeneration.name,
      description: editGeneration.description,
      inputCloudAssetIds,
      topics: cleanTopics,
      withIntro,
      withBgMusic,
      voiceId,
      status: EGenerationStatus.IN_PROGRESS,
    };

    const isNew = editGeneration.status === EGenerationStatus.INITIAL;

    let generation: IGeneration | null = null;
    if (isNew) {
      // we are in the context of new generation

      yield withRetry(
        () =>
          calls.updateGeneration({
            orgId,
            generationId: editGenerationId,
            generation: generationData,
          }),
        {
          errorContext: {
            data: {
              action: 'saveAndRunGenerationSaga: updateGeneration',
            },
          },
        },
      );

      generation = editGeneration;
    } else {
      // we are editing existing generation

      const {
        data: { generation: newGeneration },
      } = yield withRetry(
        () =>
          calls.createGeneration({
            orgId,
            generation: {
              ...generationData,
              regeneratedFromId: editGenerationId,
            },
          }),
        {
          errorContext: {
            data: {
              action: 'saveAndRunGenerationSaga: createGeneration',
            },
          },
        },
      );

      generation = newGeneration;

      // mark old generation as outdated
      yield calls.updateGeneration({
        orgId,
        generationId: editGenerationId,
        generation: {
          status: EGenerationStatus.OUTDATED,
        },
      });
    }

    yield withRetry(
      () =>
        calls.runGeneration({
          orgId,
          generationId: generation.id,
        }),
      {
        errorContext: {
          data: {
            action: 'createGenerationSaga: runGeneration',
          },
        },
      },
    );

    yield put(setGeneration(generation));
    yield put(setEditGenerationId(generation.id));

    if (!isNew) {
      yield put(
        unshiftGenerationToFeed({
          generation,
        }),
      );
      yield put(removeGeneration(editGenerationId));
    }

    yield spawn(startGenerationStatusPollingSaga, {
      payload: { generationId: generation.id, ignoreLoadingState: true },
    });
  } catch (e) {
    yield put(setGenerationError({ generationId: editGenerationId, error: e.message }));
  }
}

function* startGenerationStatusPollingSaga({
  payload: { generationId, ignoreLoadingState },
}: IAction<{ generationId: string; ignoreLoadingState?: boolean }>) {
  const { id: orgId } = yield select(activeOrganizationSelector);
  const isGenerationLoading = yield select(isGenerationLoadingSelector(generationId));

  if (isGenerationLoading && !ignoreLoadingState) {
    return;
  }

  yield put(setGenerationLoading({ generationId, state: true }));
  while (true) {
    try {
      const {
        data: { generation },
      } = yield calls.getGeneration({
        orgId,
        generationId,
      });

      if (generation.status === EGenerationStatus.OUTDATED) {
        yield put(setGenerationLoading({ generationId, state: false }));
        yield put(removeGeneration(generationId));
        break;
      }

      const currentGeneration = yield select(generationByIdSelector(generationId));
      if (currentGeneration.status !== generation.status) {
        yield put(setGeneration(generation));
      }

      if (generation.status === EGenerationStatus.INITIAL) {
        yield put(setGenerationLoading({ generationId, state: false }));
        break;
      }

      if (generation.status === EGenerationStatus.DONE) {
        const {
          data: { results: scripts },
        } = yield calls.searchGeneratedScripts({ orgId, filters: { generationId } });
        yield put(setGenerationScripts({ generationId, scripts: scripts.map(({ videoScript }) => videoScript) }));

        yield put(setGenerationLoading({ generationId, state: false }));
        break;
      }

      if (generation.status === EGenerationStatus.FAILED) {
        yield put(setGenerationError({ generationId, error: generation.error }));
        break;
      }
    } catch (e) {
    } finally {
      yield new Promise((resolve) => setTimeout(resolve, 5000));
    }
  }
}

function* loadGenerationCloudAssetsSaga({ payload: { generationId } }: IAction<{ generationId: string }>) {
  const generation = yield select(generationByIdSelector(generationId));
  const loadedCloudAssets: ILocalCloudAsset[] = yield select(cloudAssetsSelector);

  const loadedCloudAssetIds = new Set(
    loadedCloudAssets
      .map((asset) => (asset.downloadError || asset.createError ? null : asset.data.cloudAssetId))
      .filter(Boolean),
  );
  const notLoadedCloudAssetIds = generation?.inputCloudAssetIds?.filter((id) => !loadedCloudAssetIds.has(id)) || [];

  if (notLoadedCloudAssetIds.length === 0) {
    return;
  }

  const notLoadedCloudAssets = notLoadedCloudAssetIds.map((id) => ({
    key: id,
    data: { name: null, fileType: null, cloudAssetId: id },
    isLoading: true,
    createError: null,
    downloadError: null,
  }));

  yield put(setGenerationCloudAssets(notLoadedCloudAssets));

  const cloudAssets = yield Promise.allSettled(
    notLoadedCloudAssets.map(({ data }) => getCloudAsset({ id: data.cloudAssetId })),
  );

  const formattedCloudAssets = cloudAssets.map((response, index) => {
    if (response.status === 'fulfilled') {
      return {
        key: notLoadedCloudAssets[index].key,
        data: {
          name: response.value.data.cloudAsset.fileMeta.name,
          fileType: response.value.data.cloudAsset.fileMeta.mimeType,
          cloudAssetId: response.value.data.cloudAsset.id,
        },
        isLoading: false,
        createError: null,
        downloadError: null,
      };
    }

    return {
      key: notLoadedCloudAssets[index].key,
      downloadError: response.reason,
    };
  });

  yield put(setGenerationCloudAssets(formattedCloudAssets));
}

function* openProjectSaga({ payload: { generationId } }) {
  const generation = yield select(generationByIdSelector(generationId));

  yield put(resetEditGeneration());
  yield put(setEditGenerationId(generationId));
  yield put(
    setGenerationConfig({
      topics: [...generation.topics, ''],
      inputCloudAssetIds: generation.inputCloudAssetIds,
      withIntro: generation.withIntro,
      withBgMusic: generation.withBgMusic,
      voiceId: generation.voiceId,
    }),
  );

  navigate(Routes.MainStack.AIContent);
}

export default function* editAiGenerationSaga() {
  yield all([
    takeLatest(openProject, openProjectSaga),
    takeLatest(createGeneration, createGenerationSaga),
    takeLatest(generateTopics, generateTopicsSaga),
    takeLatest(saveAndRunGeneration, saveAndRunGenerationSaga),
    takeLatest(loadGenerationCloudAssets, loadGenerationCloudAssetsSaga),
    takeEvery(startGenerationPolling, startGenerationStatusPollingSaga),
  ]);
}
