import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createCloudAsset } from '../../../store/cloudAssets/cloudAssets.slice';
import { v4 as uuid } from 'uuid';
import {
  addToTimeline,
  addUndo,
  deleteProcessingTimelineItemState,
  playVideo,
  removeEmptyTimelineLayers,
  removeUndoRedoById,
  saveVideo,
  setCurrentSeconds,
  setIsPlaying,
  setProcessingTimelineItemState,
  setRecordingTimelineItemId,
  updateTimelineItem,
  updateTimelineItemLayer,
  updateTimelineMeta,
  updateVideoDuration,
} from '../../../store/videoEditor/videoEditor.slice';
import {
  currentSecondsSeletor,
  isPlayingSeletor,
  recordingTimelineItemIdSelector,
} from '../../../store/videoEditor/videoEditor.selectors';

import { ITimelineItem } from '../../../store/videoEditor/videoEditor.types';
import {
  ICloudAssetAudioCache,
  cloneCloudAssetCacheForAudioTimelineItem,
} from '../../../store/cloudAssets/cloudAssets.cache';
import { logError } from '../../../store/appActivity/appActivity.slice';
import styled from 'styled-components';
import defaultTheme from '../../../themes/defaultTheme';
import MicIcon from '../../../assets/icons/videoEditor/leftSidebar/record-mic.svg';
import StopIcon from '../../../assets/icons/videoEditor/leftSidebar/record-stop.svg';
import { getDisplayTimeFromDuration } from '../utils/getDisplayTimeFromDuration';
import { getIsItemOnTimeline } from '../utils/getIsItemOnTimeline';

const RecordSidebar = () => {
  const dispatch = useDispatch();

  const recordingTimelineItemId = useSelector(recordingTimelineItemIdSelector);

  const isPlaying = useSelector(isPlayingSeletor);
  const currentSeconds = useSelector(currentSecondsSeletor);

  const [time, setTime] = useState(0);
  const startTsRef = useRef<number | null>(null);

  const mediaRecorderRef = useRef<MediaRecorder>(null);
  const handleRecordAudio = useCallback(async () => {
    try {
      const stream = await navigator?.mediaDevices?.getUserMedia?.({ audio: true });
      const startTs = Date.now();
      startTsRef.current = startTs;
      const end = currentSeconds + 0.1;

      const timelineItem: ITimelineItem = {
        id: uuid(),
        type: 'audio',
        cloudAssetId: null,
        start: currentSeconds,
        end,
      };

      let timelineLayerId: string | null = null;
      dispatch(
        addToTimeline({
          timelineItem,
          start: currentSeconds,
          onAddToTimeline: (_) => {
            timelineLayerId = _.timelineLayer.id;
          },
        }),
      );
      dispatch(removeEmptyTimelineLayers());

      const undoId = uuid();
      dispatch(
        addUndo({
          id: undoId,
        }),
      );

      dispatch(setCurrentSeconds(end));

      const mediaRecorder = new MediaRecorder(stream);
      mediaRecorderRef.current = mediaRecorder;

      let audioChunks = [];

      mediaRecorder.ondataavailable = (event) => {
        audioChunks.push(event.data);
      };

      mediaRecorder.onstop = async () => {
        clearInterval(interval);
        setTime(0);

        const processingLabel = 'Audio recording...';

        dispatch(
          setProcessingTimelineItemState({
            timelineItemId: timelineItem.id,
            label: processingLabel,
            status: 'PROCESSING',
          }),
        );

        dispatch(setRecordingTimelineItemId(null));

        const audioBlob = new Blob(audioChunks, { type: 'audio/webm' });
        const audioBlobUrl = URL.createObjectURL(audioBlob);

        const duration = await getAudioDuration(audioBlobUrl);

        // create file from blob
        const file = new File([audioBlob], 'audio.webm', { type: 'audio/webm' });

        const newEnd = currentSeconds + (Date.now() - startTs) / 1000;

        dispatch(setCurrentSeconds(newEnd));
        dispatch(
          updateTimelineItem({
            id: timelineItem.id,
            end: newEnd,
          }),
        );

        dispatch(updateTimelineMeta());

        dispatch(
          createCloudAsset({
            fileType: 'audio',
            file,
            fileMeta: {
              name: 'Audio recording',
              duration,
              mimeType: file.type,
            },
            originalSrc: 'recorded',
            originalData: {
              name: file.name,
              size: file.size,
              type: file.type,
            },
            onCacheReady: async ({ cloudAsset, cloudAssetCache }) => {
              dispatch(
                removeUndoRedoById({
                  id: undoId,
                }),
              );

              dispatch(
                setProcessingTimelineItemState({
                  timelineItemId: timelineItem.id,
                  label: processingLabel,
                  status: 'UPLOADING',
                }),
              );

              const isOnTimeline = getIsItemOnTimeline(timelineItem.id);

              if (!isOnTimeline) {
                return;
              }

              await cloneCloudAssetCacheForAudioTimelineItem(cloudAssetCache as ICloudAssetAudioCache, timelineItem.id);

              dispatch(
                updateTimelineItem({
                  id: timelineItem.id,
                  cloudAssetId: cloudAsset.id,
                }),
              );

              dispatch(
                addUndo({
                  id: undoId,
                }),
              );
              dispatch(saveVideo({}));

              // temporary until we set up CF
              // cloudAssetCache[timelineItem.id] = {
              //   blobUrl,
              //   audio: new Audio(blobUrl),
              // };
            },
            onCreate: () => {
              dispatch(saveVideo({}));
              dispatch(
                setProcessingTimelineItemState({
                  timelineItemId: timelineItem.id,
                  label: processingLabel,
                  status: 'DONE',
                }),
              );
              setTimeout(() => {
                dispatch(
                  deleteProcessingTimelineItemState({
                    timelineItemId: timelineItem.id,
                  }),
                );
              }, 5000);
            },
            onFail: (error: any) => {
              dispatch(
                logError({
                  event: 'handleRecordAudio: create cloud asset error',
                }),
              );

              dispatch(
                setProcessingTimelineItemState({
                  timelineItemId: timelineItem.id,
                  label: processingLabel,
                  status: 'ERROR',
                  error: {
                    details: error,
                  },
                }),
              );
            },
          }),
        );
      };

      mediaRecorder.start();

      const interval = setInterval(() => {
        const newEnd = currentSeconds + (Date.now() - startTs) / 1000;

        dispatch(
          updateTimelineItem({
            id: timelineItem.id,
            end: newEnd,
          }),
        );

        dispatch(updateVideoDuration());

        dispatch(
          updateTimelineItemLayer({
            timelineItemId: timelineItem.id,
            timelineLayerId,
            onChangeLayer: (_) => {
              timelineLayerId = _.timelineLayer.id;
            },
          }),
        );

        setTime(newEnd - currentSeconds);
      }, 30);

      dispatch(setRecordingTimelineItemId(timelineItem.id));

      dispatch(playVideo());
    } catch (error) {
      dispatch(
        logError({
          event: 'handleRecordAudio: error',
          data: { error },
        }),
      );
    }
  }, [currentSeconds, dispatch]);

  useEffect(() => {
    if (!isPlaying) {
      mediaRecorderRef.current?.stop();
    }
  }, [isPlaying]);

  const handleClick = useCallback(() => {
    if (recordingTimelineItemId) {
      dispatch(setIsPlaying(false));
      return;
    }

    handleRecordAudio();
  }, [dispatch, handleRecordAudio, recordingTimelineItemId]);

  return (
    <>
      <S.Title>Record audio</S.Title>
      <S.ControlsContainer>
        <S.RecordButton onClick={handleClick}>{!recordingTimelineItemId ? <MicIcon /> : <StopIcon />}</S.RecordButton>
        <S.TimeContainer>{getDisplayTimeFromDuration(time)}</S.TimeContainer>
      </S.ControlsContainer>
    </>
  );
};
export default RecordSidebar;

const S = {
  Title: styled.div`
    padding: 16px;
    font-size: 15px;
    font-weight: 700;
    font-family: ${defaultTheme.fontFamilies.Arimo};
  `,
  ControlsContainer: styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
  `,
  RecordButton: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 79px;
    height: 79px;
    background: linear-gradient(212.43deg, #fe00c1 15.05%, #ff3c27 83.3%);
    border-radius: 50%;
    cursor: pointer;
  `,
  TimeContainer: styled.div`
    position: absolute;
    top: 65%;
    font-size: 24px;
    font-weight: 400;
    font-family: ${defaultTheme.fontFamilies.Arimo};
  `,
};

const getAudioDuration = async (audioBlobUrl: string): Promise<number> => {
  const audio = new Audio(audioBlobUrl);

  const promise = new Promise<number>((resolve) => {
    audio.onloadedmetadata = function () {
      // set the mediaElement.currentTime  to a high value beyond its real duration
      audio.currentTime = Number.MAX_SAFE_INTEGER;
      // listen to time position change
      audio.ontimeupdate = function () {
        audio.ontimeupdate = function () {};
        // setting player currentTime back to 0 can be buggy too, set it first to .1 sec
        audio.currentTime = 0.1;
        audio.currentTime = 0;

        URL.revokeObjectURL(audioBlobUrl);

        // media.duration should now have its correct value, return it...
        resolve(audio.duration);
      };
    };
  });

  audio.load();

  return promise;
};
