import React, { memo, useCallback, useMemo } from 'react';
import PeopleIconInactive from '../../../assets/icons/analytics/people-inactive.svg';
import PeopleIconActive from '../../../assets/icons/analytics/people-active.svg';
import ChartIconInactive from '../../../assets/icons/analytics/chart-inactive.svg';
import ChartIconActive from '../../../assets/icons/analytics/chart-active.svg';
import styled, { useTheme } from 'styled-components/native';
import { useNavigation } from '@react-navigation/native';
import { useDispatch, useSelector } from 'react-redux';
import { biteInitialAttributesMapByIdSelector } from '../../../store/attributes/biteAttributes/biteAttributes.selector';
import { calcHeight, calcWidth } from '../../../utils/dimensions';
import CommentsButton from '../bite/common/CommentsButton';
import { TListScreen } from '../hooks/useRedirectFromAttributes';
import { log } from '../../../store/appActivity/appActivity.slice';
import { setCurrentList } from '../../../store/analytics/analytics.slice';
import { ECurrentList } from '../../../store/analytics/analytics.types';
import { getIsWebRtl } from '../../../locale/i18n';
import AttributesSorting from '../bite/common/AttributesSorting';
import { StackNavigationProp } from '@react-navigation/stack';
import {
  disabledHeaderAttributesButtonDataSet,
  headerAttributesButtonDataSet,
  headerUsersButtonDataSet,
} from '../analytics.constants';

interface IProps {
  currentScreen: string;
  listScreen: TListScreen;
  attributesScreen: string;
  isComments?: boolean;
  rightComponent?: React.ReactNode;
  disableAttributes?: boolean;
  onLayout?: (event: any) => void;
  isShowBottomDivider?: boolean;
}

const HeaderTabs: React.FC<IProps> = ({
  onLayout,
  currentScreen,
  listScreen,
  attributesScreen,
  disableAttributes,
  isComments,
  // rightComponent,
  isShowBottomDivider,
}) => {
  const isWebRtl = getIsWebRtl();
  const dispatch = useDispatch();
  const theme = useTheme();
  const navigation = useNavigation<StackNavigationProp<any>>();

  const initialAttributesMapById = useSelector(biteInitialAttributesMapByIdSelector);

  const hasAttributes = useMemo(() => Object.keys(initialAttributesMapById).length > 0, [initialAttributesMapById]);

  const handlePressPeople = useCallback(() => {
    if (currentScreen === listScreen) {
      return;
    }

    dispatch(
      log({
        event: 'HeaderTabs.handlePressPeople',
        data: { listScreen },
      }),
    );

    dispatch(setCurrentList(ECurrentList.USERS));
    // @ts-ignore
    navigation.replace(listScreen);
  }, [currentScreen, dispatch, listScreen, navigation]);

  const handlePressAnalytics = useCallback(() => {
    if (disableAttributes || currentScreen === attributesScreen) {
      return;
    }

    dispatch(
      log({
        event: 'HeaderTabs.handlePressAnalytics',
        data: { attributesScreen },
      }),
    );

    dispatch(setCurrentList(ECurrentList.ATTRIBUTES));
    // @ts-ignore
    navigation.replace(attributesScreen);
  }, [attributesScreen, currentScreen, disableAttributes, dispatch, navigation]);

  return (
    <S.HeaderContainer isRtl={isWebRtl} onLayout={onLayout} isShowBottomDivider={isShowBottomDivider}>
      <S.LeftSide isRtl={isWebRtl}>
        {hasAttributes && (
          <>
            <S.HeaderButton
              dataSet={disableAttributes ? disabledHeaderAttributesButtonDataSet : headerAttributesButtonDataSet}
              onPress={handlePressAnalytics}
              disabled={disableAttributes}
            >
              {currentScreen === attributesScreen ? (
                <ChartIconActive />
              ) : (
                <ChartIconInactive color={disableAttributes ? theme.colors.gray17 : theme.colors.gray19} />
              )}
            </S.HeaderButton>
            <S.VerticalDivider />
          </>
        )}
        {(hasAttributes || isComments) && (
          <>
            <S.HeaderButton dataSet={headerUsersButtonDataSet} onPress={handlePressPeople}>
              {currentScreen === listScreen ? <PeopleIconActive /> : <PeopleIconInactive />}
            </S.HeaderButton>
            {isComments && (
              <>
                <S.VerticalDivider />
                <CommentsButton />
              </>
            )}
          </>
        )}
      </S.LeftSide>
      <S.RightSide isRtl={isWebRtl}>
        <AttributesSorting currentScreen={currentScreen} />
        {/*{!!rightComponent && rightComponent}*/}
      </S.RightSide>
    </S.HeaderContainer>
  );
};

const S = {
  HeaderContainer: styled.View<{ isRtl: boolean; isShowBottomDivider?: boolean }>`
    flex-direction: ${({ isRtl }) => (isRtl ? 'row-reverse' : 'row')};
    align-items: center;
    justify-content: space-between;
    background-color: ${({ theme }) => theme.colors.white};
    padding: ${calcHeight(10)}px ${calcWidth(10)}px ${calcHeight(5)}px;
    border: 0 solid
      ${({ theme, isShowBottomDivider }) => (isShowBottomDivider ? theme.colors.overviewDivider : 'transparent')};
    border-bottom-width: 1px;
  `,
  IconsContainer: styled.View<{ isRtl: boolean }>`
    flex-direction: ${({ isRtl }) => (isRtl ? 'row-reverse' : 'row')};
    align-items: center;
    justify-content: space-between;
    background-color: ${({ theme }) => theme.colors.white};
    padding: 0 ${calcWidth(10)}px;
  `,
  IconContainer: styled.View<{ isRtl: boolean }>`
    align-items: center;
    height: ${calcHeight(24)}px;
    width: ${calcWidth(50)}px;
    justify-content: center;
  `,
  HeaderButton: styled.TouchableOpacity`
    padding: 10px;
  `,
  VerticalDivider: styled.View`
    width: 1px;
    height: 24px;
    background-color: ${({ theme }) => theme.colors.overviewDivider};
  `,
  LeftSide: styled.View<{ isRtl: boolean }>`
    flex-direction: ${({ isRtl }) => (isRtl ? 'row-reverse' : 'row')};
    align-items: center;
  `,
  RightSide: styled.View<{ isRtl: boolean }>`
    flex-direction: ${({ isRtl }) => (isRtl ? 'row-reverse' : 'row')};
    align-items: flex-end;
    justify-content: flex-end;
  `,
  SwitchLabelText: styled.Text`
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    font-size: ${({ theme }) => theme.fontSizes.s14}px;
    color: ${({ theme }) => theme.colors.primaryBlue};
    font-weight: 400;
    text-align: center;
  `,
  SwitchModeContainer: styled.TouchableOpacity`
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 10px;
  `,
};

export default memo(HeaderTabs);
