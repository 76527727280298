import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { biteDisplayModeSelector } from '../../../../../../store/attributes/biteAttributes/biteAttributes.selector';
import TotalBarErrorIcon from '../../../../../../assets/icons/total-bar-error.svg';
import Skeleton from '../../../../../../components/shared/Skeleton/Skeleton';
import { absoluteValueTextDataSet, percentageValueTextDataSet } from '../../../../analytics.constants';
import styled from 'styled-components/native';
import { calcHeight, calcWidth } from '../../../../../../utils/dimensions';

interface INumberProps {
  absolute: number;
  percentage: number;
  isError: boolean;
  isLoading: boolean;
  totalUserCount: number;
}

const Number: React.FC<INumberProps> = ({ absolute, percentage, isError, isLoading, totalUserCount }) => {
  const displayMode = useSelector(biteDisplayModeSelector);

  if (isError) {
    return (
      <S.ValueContainer>
        <TotalBarErrorIcon />
      </S.ValueContainer>
    );
  }

  if (isLoading || absolute === null) {
    return (
      <S.ValueContainer>
        <Skeleton width={30} height={18} />
      </S.ValueContainer>
    );
  }

  if (displayMode === 'absolute') {
    return (
      <S.ValueContainer>
        <S.ValueText dataSet={absoluteValueTextDataSet}>{absolute}</S.ValueText>
        <S.TotalValueText>({totalUserCount})</S.TotalValueText>
      </S.ValueContainer>
    );
  }

  return (
    <S.ValueContainer>
      <S.ValueText dataSet={percentageValueTextDataSet}>{percentage || 0}%</S.ValueText>
    </S.ValueContainer>
  );
};

const S = {
  ValueContainer: styled.View`
    align-items: center;
    height: ${calcHeight(24)}px;
    justify-content: center;
    width: ${calcWidth(60)}px;
  `,
  ValueText: styled.Text<{ bold?: boolean }>`
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    text-align: center;
    font-weight: ${({ bold }) => (bold ? 700 : 400)};
    color: ${({ theme }) => theme.colors.text};
    font-size: ${({ theme }) => theme.fontSizes.s14}px;
    line-height: ${({ theme }) => theme.fontSizes.s14}px;
  `,
  TotalValueText: styled.Text`
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    text-align: center;
    font-weight: ${({ bold }) => (bold ? 700 : 400)};
    color: #7e7e7e;
    font-size: ${({ theme }) => theme.fontSizes.s12}px;
    line-height: ${({ theme }) => theme.fontSizes.s14}px;
  `,
  EmptyView: styled.View`
    height: ${calcHeight(15)}px;
  `,
};

export default memo(Number);
