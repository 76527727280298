import { ITimelineItem } from '../../store/videoEditor/videoEditor.types';
import defaultTheme from '../../themes';

export const SCROLL_BAR_WIDTH = 8;
export const SCROLL_BAR_OFFSET = 2;
export const TIMELINE_RIGHT_SPACING = 400;
export const LAYER_PADDING_LEFT = SCROLL_BAR_WIDTH;
export const LAYER_PLACEHOLDER_HEIGHT = 8;
export const INTER_TIMELINE_ITEM_SPACING = 2;
export const TRIM_CONTROL_WIDTH = 16;
export const TRIM_CONTROL_MARGIN = 2;
export const TRIM_CONTROL_LINE_SPACING = 4;
export const PREVIEW_MARGIN = 2;
export const SPLITTER_WIDTH = 6;

// const LAYER_HEIGHT = 50;
export const LAYER_HEIGHT_BY_TYPE = {
  audio: 36,
  video: 64,
  image: 64,
  text: 36,
  gif: 36,
  shape: 36,
  group: 36,
};
export const LAYER_VERTICAL_PADDING_BY_TYPE = {
  audio: 0,
  video: 0,
  image: 0,
  text: 0,
  gif: 0,
  shape: 0,
  group: 0,
};
export const getTimelineItemHeight = (type: ITimelineItem['type']) =>
  LAYER_HEIGHT_BY_TYPE[type] - LAYER_VERTICAL_PADDING_BY_TYPE[type] * 2;

export const itemColorsByType = {
  video: [defaultTheme.colors.backgroundPurple, defaultTheme.colors.primaryBlue],
  audio: [defaultTheme.colors.pinkError, defaultTheme.colors.pinkError],
  image: [defaultTheme.colors.orange1, defaultTheme.colors.orange2],
  text: [defaultTheme.colors.green1, defaultTheme.colors.green2],
  gif: [defaultTheme.colors.violet1, defaultTheme.colors.violet2],
  shape: [defaultTheme.colors.green1, defaultTheme.colors.green2],
  group: [defaultTheme.colors.green1, defaultTheme.colors.green2],
};
