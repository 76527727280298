import { ITimelineItem } from '../../../store/videoEditor/videoEditor.types';
import { INTER_TIMELINE_ITEM_SPACING, LAYER_PADDING_LEFT } from '../timeline.constants';
import { getTimelineItemWidth } from './getTimelineItemWidth';

export const getTimelinePosition = ({
  timelineItem,
  startSeconds,
  secondDisplayWidth,
}: {
  timelineItem: ITimelineItem;
  startSeconds: number;
  secondDisplayWidth: number;
}) => {
  if (!timelineItem) {
    return {
      startX: null,
      endX: null,
      width: null,
    };
  }

  const startX = startSeconds * secondDisplayWidth + LAYER_PADDING_LEFT + INTER_TIMELINE_ITEM_SPACING / 2;
  const width = getTimelineItemWidth({ timelineItem, secondDisplayWidth });
  const endX = startX + width;

  return {
    startX,
    endX,
    width,
  };
};
