import React, { memo } from 'react';
import { IBiteAttributeValue } from '../../../../store/attributes/biteAttributes/biteAttributes.types';
import styled, { css } from 'styled-components/native';
import { calcHeight, calcWidth, deviceWidth, isWeb } from '../../../../utils/dimensions';
import Divider from '../../../../components/shared/Divider';
import { getIsWebRtl } from '../../../../locale/i18n';
import { absoluteValueTextDataSet, percentageValueTextDataSet } from '../../analytics.constants';
import { TDisplayMode } from '../../../../types/anayltics';
import { safeDivide } from '../../../../utils/math';

interface IProps {
  item: IBiteAttributeValue;
  displayMode: TDisplayMode;
  isViewsTabEnabled: boolean;
  isAnsweredTabEnabled: boolean;
  isCommentsTabEnabled: boolean;
  isBoldTitle?: boolean;
  isLastItem?: boolean;
  dataSet?: any;
}

const BiteAttributeValueItem: React.FC<IProps> = ({
  item,
  displayMode,
  isViewsTabEnabled,
  isAnsweredTabEnabled,
  isCommentsTabEnabled,
  isBoldTitle,
  dataSet,
}) => {
  const isRtl = getIsWebRtl();
  return (
    <S.ListItemContainer dataSet={dataSet}>
      <S.ListItem isRtl={isRtl}>
        <S.ListItemTitle isRtl={isRtl} isBoldTitle={isBoldTitle}>
          {item.name}
        </S.ListItemTitle>
        <S.IconsContainer isRtl={isRtl}>
          {item.views_absolute !== undefined && isViewsTabEnabled && (
            <S.IconContainer>
              {displayMode === 'absolute' ? (
                <>
                  <S.ValueText dataSet={absoluteValueTextDataSet}>{item.views_absolute}</S.ValueText>
                  {item.total_users_count ? <S.TotalText>({item.total_users_count})</S.TotalText> : <S.EmptyView />}
                </>
              ) : (
                <S.ValueText
                  // @ts-ignore
                  dataSet={percentageValueTextDataSet}
                >
                  {`${safeDivide(item.views_absolute, item.total_users_count)}%`}
                </S.ValueText>
              )}
            </S.IconContainer>
          )}
          {item.answers_absolute !== undefined && isAnsweredTabEnabled && (
            <S.IconContainer>
              <S.ValueText>
                {displayMode === 'absolute'
                  ? item.answers_absolute
                  : `${safeDivide(item.answers_absolute, item.views_absolute)}%`}
              </S.ValueText>
              {displayMode === 'absolute' && <S.TotalText>({item.views_absolute || 0})</S.TotalText>}
            </S.IconContainer>
          )}
          {item.comments_absolute !== undefined && isCommentsTabEnabled && (
            <S.IconContainer>
              <S.ValueText>
                {displayMode === 'absolute'
                  ? item.comments_absolute
                  : `${safeDivide(item.comments_absolute, item.views_absolute)}%`}
              </S.ValueText>
              {displayMode === 'absolute' && <S.TotalText>({item.views_absolute || 0})</S.TotalText>}
            </S.IconContainer>
          )}
        </S.IconsContainer>
      </S.ListItem>

      <S.Divider />
    </S.ListItemContainer>
  );
};

const S = {
  ListItemContainer: styled.View`
    padding: 0 ${calcWidth(19)}px;
  `,
  ListItem: styled.View<{ isRtl: boolean }>`
    flex-direction: ${({ isRtl }) => (isRtl ? 'row-reverse' : 'row')};
    justify-content: space-between;
    align-items: center;
    height: ${calcHeight(44)}px;
  `,
  ListItemTitle: styled.Text<{ isRtl: boolean; isBoldTitle: boolean }>`
    flex: 3;
    max-width: ${isWeb ? calcWidth(370) : deviceWidth - calcWidth(180)}px;
    color: ${({ theme }) => theme.colors.text};
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    font-size: ${({ theme, isBoldTitle }) => (isBoldTitle ? theme.fontSizes.s15 : theme.fontSizes.s14)}px;
    line-height: ${({ theme, isBoldTitle }) => (isBoldTitle ? theme.fontSizes.s15 : theme.fontSizes.s14)}px;
    text-align: ${({ isRtl }) => (isRtl ? 'right' : 'left')};
    font-weight: ${({ isBoldTitle }) => (isBoldTitle ? 700 : 400)};
    ${({ isRtl }) =>
      isRtl
        ? css`
            padding-left: ${calcWidth(12)}px;
          `
        : css`
            padding-right: ${calcWidth(12)}px;
          `}
  `,
  IconsContainer: styled.View<{ isRtl: boolean }>`
    flex: 1;
    flex-direction: ${({ isRtl }) => (isRtl ? 'row-reverse' : 'row')};
    width: ${isWeb ? calcWidth(512) : deviceWidth - calcWidth(38)}px;
    justify-content: flex-end;
    align-items: center;
    ${({ isRtl }) =>
      isRtl
        ? css`
            margin-right: ${calcWidth(10)}px;
          `
        : css`
            margin-left: ${calcWidth(10)}px;
          `}
  `,
  IconContainer: styled.View`
    align-items: center;
    height: ${calcHeight(24)}px;
    justify-content: center;
    width: ${calcWidth(60)}px;
    margin-left: ${calcWidth(6)}px;
  `,
  Divider: styled(Divider)`
    width: 100%;
    background-color: ${({ theme }) => theme.colors.lightGray1};
  `,
  ValueText: styled.Text`
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    text-align: center;
    font-weight: 400;
    color: ${({ theme }) => theme.colors.text};
    font-size: ${({ theme }) => theme.fontSizes.s14}px;
    line-height: ${({ theme }) => theme.fontSizes.s14}px;
  `,
  TotalText: styled.Text`
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    text-align: center;
    font-weight: ${({ bold }) => (bold ? 700 : 400)};
    color: #7e7e7e;
    font-size: ${({ theme }) => theme.fontSizes.s12}px;
    line-height: ${({ theme }) => theme.fontSizes.s14}px;
  `,
  EmptyView: styled.View`
    height: ${calcHeight(15)}px;
  `,
};

export default memo(BiteAttributeValueItem);
