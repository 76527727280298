import { EAnalyticsScreenType } from '../../Analytics.types';
import useAnalyticsTabs from './useAnalyticsTabs';

const useShowIcons = ({ currentTab }) => {
  const { isViewsTabEnabled, isAnsweredTabEnabled, isCommentsTabEnabled } = useAnalyticsTabs();

  const showViews =
    (currentTab === EAnalyticsScreenType.OVERVIEW || currentTab === EAnalyticsScreenType.VIEWS) && isViewsTabEnabled;
  const showAnswers =
    (currentTab === EAnalyticsScreenType.OVERVIEW || currentTab === EAnalyticsScreenType.ANSWERS) &&
    isAnsweredTabEnabled;
  const showComments =
    (currentTab === EAnalyticsScreenType.OVERVIEW || currentTab === EAnalyticsScreenType.COMMENTS) &&
    isCommentsTabEnabled;

  return {
    showViews,
    showAnswers,
    showComments,
  };
};

export default useShowIcons;
