import React, { memo, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAnswersAttributes,
  resetAnswersAttributes,
} from '../../../../store/attributes/biteAttributes/biteAttributes.slice';
import AnswersAggregation from '../common/AnswersAggregation';
import HeaderTabs from '../../common/HeaderTabs';
import { currentListSelector } from '../../../../store/analytics/analytics.selector';
import useAnsweredAttributesMap from '../hooks/useAnsweredAttributesMap';
import useRedirectFromAttributes from '../../hooks/useRedirectFromAttributes';
import { IStackNavigation } from '../../../../navigation/types';
import { ECurrentList } from '../../../../store/analytics/analytics.types';
import { EAnalyticsScreenType } from '../../Analytics.types';
import useAnimatedHeaderTabs from '../hooks/useAnimatedHeaderTabs/useAnimatedHeaderTabs';
import GuestsAttribute from '../common/GuestsAttribute';
import {
  guestsAttributeAnswersWithOptionSelector,
  selectedFilterAttributeValueIdsSelector,
  biteSharedWithFilterSelector,
} from '../../../../store/attributes/biteAttributes/biteAttributes.selector';
import useHeaderTabsDivider from '../../hooks/useHeaderTabsDivider';
import { ANSWERED_ATTRIBUTES_SCREEN, ANSWERED_LIST_SCREEN } from '../constants';
import { attributesListDataSet } from '../../analytics.constants';
import {
  answersIdsFilterSelector,
  isNeedToScrollUpAnswersSelector,
} from '../../../../store/attributes/biteStats/biteStats.selector';
import { resetAnsweredTabsTotal, setAnswersData } from '../../../../store/attributes/biteStats/biteStats.slice';
import { ESharedWithFilter } from '../../../../types/anayltics';
import BiteAttributesList from '../common/BiteAttributesList';
import AnswersTabTotalBar from '../../common/TotalBar/BiteTotalBar/AnswersTabTotalBar';

const AnsweredAttributes: React.FC<IStackNavigation> = ({ navigation }) => {
  const dispatch = useDispatch();

  const isNeedToScrollUp = useSelector(isNeedToScrollUpAnswersSelector);
  const selectedAnswers = useSelector(answersIdsFilterSelector);
  const currentList = useSelector(currentListSelector);
  const selectedAttributes = useSelector(selectedFilterAttributeValueIdsSelector);
  const [isHideGuestsAttribute, setIsHideGuestsAttribute] = useState(false);
  const [isFocusedMode, setIsFocusedMode] = useState(false);

  const guestsAttributeAnswersWithOption = useSelector(guestsAttributeAnswersWithOptionSelector);
  const sharedWithFilter = useSelector(biteSharedWithFilterSelector);

  const { setIsShowHeaderBottomDivider, isShowHeaderBottomDivider } = useHeaderTabsDivider();
  const { attributesMap } = useAnsweredAttributesMap();

  useRedirectFromAttributes({
    attributesMap,
    listScreen: ANSWERED_LIST_SCREEN,
    navigation,
  });

  const handleAnswerSelect = useCallback(() => {
    dispatch(resetAnswersAttributes());
    dispatch(setAnswersData({ results: null, next: null, reset: true }));
    dispatch(resetAnsweredTabsTotal());
  }, [dispatch]);

  useEffect(() => {
    if (currentList === ECurrentList.USERS) {
      navigation.replace(ANSWERED_LIST_SCREEN);
    }
  }, [currentList, navigation]);

  const { handleHeaderLayout, scrollHandler, renderAnimatedHeaderTabs, stickyHeaderStyles } = useAnimatedHeaderTabs({
    onScroll: setIsShowHeaderBottomDivider,
    customHandleOffset: selectedAnswers.length !== 0 ? 10 : undefined,
    isFocusedMode,
  });

  const renderGuestsComponent = useCallback(
    ({ isRefreshing, customData }) => {
      if (
        selectedAttributes.length ||
        isHideGuestsAttribute ||
        (sharedWithFilter.length === 1 && sharedWithFilter.includes(ESharedWithFilter.COMMUNICATION_HUB))
      ) {
        return null;
      }

      return (
        <GuestsAttribute
          customData={customData}
          currentTab={EAnalyticsScreenType.ANSWERS}
          isRefreshing={isRefreshing}
        />
      );
    },
    [isHideGuestsAttribute, selectedAttributes.length, sharedWithFilter],
  );

  const renderListHeaderComponent = useCallback(
    ({ isRefreshing }) => {
      return (
        <>
          {selectedAnswers.length === 0 && (
            <>
              <AnswersAggregation onSelect={handleAnswerSelect} />
              <HeaderTabs
                onLayout={handleHeaderLayout}
                currentScreen={ANSWERED_ATTRIBUTES_SCREEN}
                attributesScreen={ANSWERED_ATTRIBUTES_SCREEN}
                listScreen={ANSWERED_LIST_SCREEN}
              />
            </>
          )}
          <>
            <AnswersTabTotalBar />
            {renderGuestsComponent({
              isRefreshing,
              customData: selectedAnswers?.length ? guestsAttributeAnswersWithOption : null,
            })}
          </>
        </>
      );
    },
    [
      guestsAttributeAnswersWithOption,
      handleAnswerSelect,
      handleHeaderLayout,
      renderGuestsComponent,
      selectedAnswers.length,
    ],
  );

  return (
    <>
      {selectedAnswers.length !== 0 && (
        <>
          <AnswersAggregation onSelect={handleAnswerSelect} />
          <HeaderTabs
            currentScreen={ANSWERED_ATTRIBUTES_SCREEN}
            attributesScreen={ANSWERED_ATTRIBUTES_SCREEN}
            listScreen={ANSWERED_LIST_SCREEN}
            isShowBottomDivider={isShowHeaderBottomDivider && !isFocusedMode}
          />
        </>
      )}

      {selectedAnswers.length === 0 && renderAnimatedHeaderTabs()}
      <BiteAttributesList
        dataSet={attributesListDataSet}
        onSelectAttribute={setIsHideGuestsAttribute}
        onSingleAttributeChanged={setIsFocusedMode}
        isNeedToScrollUp={isNeedToScrollUp}
        listType={EAnalyticsScreenType.ANSWERS}
        scrollEventThrottle={0.0001}
        onScroll={scrollHandler}
        renderListHeaderComponent={renderListHeaderComponent}
        attributesMap={attributesMap}
        onLoad={getAnswersAttributes}
        stickyHeaderStyles={selectedAnswers.length === 0 ? stickyHeaderStyles : undefined}
      />
    </>
  );
};

export default memo(AnsweredAttributes);
