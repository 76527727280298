import React, { memo, useCallback, useEffect, useMemo } from 'react';
import styled, { css } from 'styled-components/native';
import { useDispatch, useSelector } from 'react-redux';
import { calcHeight, calcWidth, deviceWidth, isWeb } from '../../../../../utils/dimensions';
import { getIsWebRtl } from '../../../../../locale/i18n';
import { useTranslation } from 'react-i18next';
import {
  getAnsweredTabTotal,
  getTotalViews,
  resetAnsweredTabsTotal,
  setTotalRequestsId,
} from '../../../../../store/attributes/biteStats/biteStats.slice';
import {
  totalAnswersWithAnswerIdsSelector,
  totalViewsSelector,
} from '../../../../../store/attributes/biteStats/biteStats.selector';
import Number from './common/Numbers';
import { useIsFocused } from '@react-navigation/native';
import { v4 as uuid } from 'uuid';
import { safeDivide } from '../../../../../utils/math';

interface IProps {
  isUsers?: boolean;
}

const AnswersTabTotalBar: React.FC<IProps> = ({ isUsers }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isWebRtl = getIsWebRtl();
  const isFocused = useIsFocused();

  const {
    data: viewsNumber,
    error: viewsNumberError,
    isLoading: viewsNumberIsLoading,
  } = useSelector(totalViewsSelector);
  const {
    data: answersNumber,
    error: answersNumberError,
    isLoading: answersNumberIsLoading,
  } = useSelector(totalAnswersWithAnswerIdsSelector);

  const data = useMemo(() => {
    return {
      viewsNumber,
      answersNumber,
      answersPercentage: answersNumber !== null && viewsNumber ? safeDivide(answersNumber, viewsNumber) : null,
    };
  }, [answersNumber, viewsNumber]);

  const isError = viewsNumberError || answersNumberError;
  const isLoading = viewsNumberIsLoading || answersNumberIsLoading;

  useEffect(() => {
    if (isLoading || isError || !isFocused) {
      return;
    }

    // request id needed to avoid race condition changes in store
    const requestId = uuid();
    dispatch(setTotalRequestsId(requestId));

    if (viewsNumber === null) {
      dispatch(getTotalViews());
    }

    if (answersNumber === null) {
      dispatch(getAnsweredTabTotal());
    }
  }, [answersNumber, dispatch, isError, isFocused, isLoading, viewsNumber]);

  const handleReload = useCallback(() => {
    dispatch(resetAnsweredTabsTotal());
  }, [dispatch]);

  return (
    <S.Container isRtl={isWebRtl} isUsers={isUsers}>
      <S.LeftSide isRtl={isWebRtl}>
        <S.TotalText>{t('common.total')}</S.TotalText>

        {!!isError && (
          <S.ReloadButton isRtl={isWebRtl} onPress={handleReload}>
            <S.ReloadButtonText>{t('common.reload')}</S.ReloadButtonText>
          </S.ReloadButton>
        )}
      </S.LeftSide>
      <S.DataContainer isRtl={isWebRtl}>
        <Number
          absolute={data.answersNumber}
          percentage={data.answersPercentage}
          isError={!!answersNumberError}
          isLoading={answersNumberIsLoading}
          totalUserCount={data.viewsNumber || 0}
        />
      </S.DataContainer>
    </S.Container>
  );
};

const S = {
  Container: styled.View<{ isRtl?: boolean; isUsers?: boolean }>`
    height: 66px;
    width: ${isWeb ? '530px' : `${deviceWidth - 20}px`};
    padding: 13px 10px;
    margin: 14px 10px ${({ isUsers }) => (isUsers ? calcHeight(28) : '0')}px;
    flex-shrink: 0;

    flex-direction: ${({ isRtl }) => (isRtl ? 'row-reverse' : 'row')};
    align-items: center;

    border-radius: 11px;
    background-color: ${({ theme }) => theme.colors.totalBarBackground};
    justify-content: space-between;
  `,
  LeftSide: styled.View<{ isRtl: boolean }>`
    flex-direction: ${({ isRtl }) => (isRtl ? 'row-reverse' : 'row')};
  `,
  TotalText: styled.Text`
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 14px; /* 93.333% */
  `,
  DataContainer: styled.View`
    flex: 1;
    flex-direction: ${({ isRtl }) => (isRtl ? 'row-reverse' : 'row')};
    width: ${isWeb ? calcWidth(512) : deviceWidth - calcWidth(38)}px;
    justify-content: flex-end;
    align-items: center;
    ${({ isRtl }) =>
      isRtl
        ? css`
            margin-right: ${calcWidth(10)}px;
          `
        : css`
            margin-left: ${calcWidth(10)}px;
          `}
  `,
  ReloadButton: styled.TouchableOpacity<{ isRtl?: boolean }>`
    ${({ isRtl }) =>
      isRtl
        ? css`
            margin-right: 32px;
          `
        : css`
            margin-left: 32px;
          `}
  `,
  ReloadButtonText: styled.Text`
    color: ${({ theme }) => theme.colors.primaryBlue};
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    text-decoration-line: underline;
    text-decoration-style: solid;
    text-decoration-skip-ink: auto;
    text-decoration-thickness: auto;
    text-underline-offset: auto;
    text-underline-position: from-font;
  `,
};

export default memo(AnswersTabTotalBar);
