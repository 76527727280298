import React, {
  useCallback,
  useMemo,
  useRef,
  memo,
  useEffect,
  RefObject,
  useState,
  ReactElement,
  MutableRefObject,
  FC,
  ComponentProps,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  currentSecondsSeletor,
  ghostTimelineItemMetaSelector,
  isPlayingSeletor,
  isSplitModeSeletor,
  processingTimelineItemSelector,
  recordingTimelineItemIdSelector,
  selectedTimelineItemIdSelector,
  timelineItemSeletor,
  timelineScaleSeletor,
  trimTimelineItemMetaSelector,
  videoDurationSeletor,
  videoTimelineLayersSeletor,
  acceptTypesByTimelineLayerIdSelector,
  timelineSnapSelector,
  highlightSelectedTimelineItemSelector,
  replacingSelectedTimelineItemSelector,
} from '../../store/videoEditor/videoEditor.selectors';
import styled from 'styled-components';
import {
  IGhostTimelineItemMeta,
  ILayersDisplayDataLayer,
  ILayersDisplayDataPlaceholder,
  ITimelineItem,
  ITimelineItemMeta,
  ITimelineLayer,
  TLayerDisplayItem,
  TLayersDisplayData,
} from '../../store/videoEditor/videoEditor.types';

import {
  addToTimeline,
  addUndo,
  removeEmptyTimelineLayers,
  saveVideo,
  setCurrentSeconds,
  setGhostTimelineItemMeta,
  setGhostTimelineLayerPrevState,
  setHighlightSelectedTimelineItem,
  setIsPlaying,
  setIsSplitMode,
  setReplacingSelectedTimelineItem,
  setSelectedTimelineItemId,
  setShowTimelineItemGeneratedMeta,
  setTimelineHeight,
  setTimelineSnap,
  setTrimTimelineItemMeta,
  splitTimelineItem,
  timelineGhostItemHoverTimelineLayer,
  trimTimelineItemEnd,
  trimTimelineItemStart,
  updateCanvas,
  updateVideoDuration,
} from '../../store/videoEditor/videoEditor.slice';
import { v4 as uuid } from 'uuid';
import { Easing, interpolateColor, useSharedValue, withTiming } from 'react-native-reanimated';
import { useGetNodeRect } from '../../hooks/web/useGetNodeRect';
import { clickTargetRef } from './documentEvents.web';
import Konva from 'konva';
import { Stage, Layer, Group, Rect, Line, Text, Arc, Image } from 'react-konva';
import defaultTheme from '../../themes/defaultTheme';
import { POSITIONS_MAP_STEP, retryFunctions, videoEditorData } from '../../store/videoEditor/videoEditor.data';
import { getTimelineLayerVisualType } from './utils/getTimelineLayerVisualType';
import { useTimelineScale } from './utils/useTimelineScale';
import { formatTime } from './utils/formatTime';
import {
  ICloudAssetGifCache,
  ICloudAssetImageCache,
  ICloudAssetVideoCache,
  cloudAssetCache,
} from '../../store/cloudAssets/cloudAssets.cache';
import BulbIcon from '../../assets/icons/videoEditor/bulb.svg';
import TextToSpeechIcon from '../../assets/icons/videoEditor/timeline/text-to-speech.svg';
import AudioRecordingIcon from '../../assets/icons/videoEditor/timeline/audio-recording.svg';
import { renderToStaticMarkup } from 'react-dom/server';
import { getMathMax } from '../../store/videoEditor/videoEditor.saga';
import store from '../../store';
import { SvgProps } from 'react-native-svg';
import { cloudAssetSelector } from '../../store/cloudAssets/cloudAssets.selector';
import { getTranscriptionCombinedText } from './LeftSidebar/utils/getTranscriptionCombinedText';
import { TIMELINE_CONTAINER_HEIGHT } from './videoEditor.constants';
import { useInitGenerationWalkme } from './hooks/useInitGenerationWalkme';
import { getTimelinePosition } from './utils/getTimelinePosition';
import {
  getTimelineItemHeight,
  INTER_TIMELINE_ITEM_SPACING,
  itemColorsByType,
  LAYER_HEIGHT_BY_TYPE,
  LAYER_PADDING_LEFT,
  LAYER_PLACEHOLDER_HEIGHT,
  LAYER_VERTICAL_PADDING_BY_TYPE,
  PREVIEW_MARGIN,
  SCROLL_BAR_OFFSET,
  SCROLL_BAR_WIDTH,
  SPLITTER_WIDTH,
  TRIM_CONTROL_LINE_SPACING,
  TRIM_CONTROL_MARGIN,
  TRIM_CONTROL_WIDTH,
} from './timeline.constants';

const Timeline = ({}) => {
  const dispatch = useDispatch();

  const timelineLayers = useSelector(videoTimelineLayersSeletor);
  const videoDuration = useSelector(videoDurationSeletor);
  const timelineScalePc = useSelector(timelineScaleSeletor);
  const currentSeconds = useSelector(currentSecondsSeletor);
  const isSplitMode = useSelector(isSplitModeSeletor);
  const highlightSelectedTimelineItem = useSelector(highlightSelectedTimelineItemSelector);
  const replacingSelectedTimelineItem = useSelector(replacingSelectedTimelineItemSelector);
  const selectedTimelineItemId = useSelector(selectedTimelineItemIdSelector);

  const ghostTimelineItemMeta = useSelector(ghostTimelineItemMetaSelector);
  // const ghostisMouseDownTimelineItemRef useSelector(timelineItemSeletor(ghostTimelineItemMeta?.timelineItemId));

  const isPlaying = useSelector(isPlayingSeletor);

  const acceptTypesByTimelineLayerId = useSelector(acceptTypesByTimelineLayerIdSelector);

  const containerRef = useRef<HTMLDivElement>(null);
  const getContainerRect = useGetNodeRect(containerRef);
  // const positionIndicatorRef = useRef<HTMLDivElement>(null);

  const [scrollLeft, setScrollLeft] = useState<number>(0);
  const [scrollTop, setScrollTop] = useState<number>(0);

  const isDraggingTimelineItemRef = useRef<boolean>(false);
  const isMouseDownRef = useRef<boolean>(false);
  const isMouseDownTimelineItemRef = useRef<boolean>(false);

  // const [cursor, setCursor] = useState<string>('default');
  const setCursor = useCallback((cursor: string) => {
    containerRef.current.style.cursor = cursor;
  }, []);
  // const cursor = 'default';

  // const containerScrollTimeoutRef = useRef<number | null>(null);
  // if (!isPlaying && containerScrollTimeoutRef.current) {
  //   clearTimeout(containerScrollTimeoutRef.current);
  //   containerScrollTimeoutRef.current = null;
  // }

  // set container width initial and upon resize
  const [containerWidth, setContainerWidth] = useState<number>(0);
  const [containerHeight, setContainerHeight] = useState<number>(0);
  useEffect(() => {
    const containerRect = getContainerRect();
    setContainerWidth(containerRect.width);
    setContainerHeight(containerRect.height);
  }, [getContainerRect]);

  const { secondDisplayWidth, timelineWidth } = useTimelineScale({
    containerWidth,
    videoDuration,
    timelineScalePc,
  });

  const getSecondsFromClientX = useCallback(
    (clientX: number): [number, { innerX: number }] => {
      const containerRect = getContainerRect();

      const innerX = clientX - containerRect.left;
      const innerXWithScroll = Math.max(innerX + scrollLeft - LAYER_PADDING_LEFT, 0);

      const seconds = innerXWithScroll / secondDisplayWidth;

      return [seconds, { innerX }];
    },
    [getContainerRect, scrollLeft, secondDisplayWidth],
  );

  const onMouseMoveSetPosition = useCallback(
    (e) => {
      requestAnimationFrame(() => {
        const [seconds] = getSecondsFromClientX(e.clientX);

        dispatch(setCurrentSeconds(seconds));
        dispatch(
          updateCanvas({
            // seconds,
          }),
        );
      });
    },
    [dispatch, getSecondsFromClientX],
  );

  const handleMouseMove = useCallback(
    (e) => {
      mouseDownTsRef.current = Date.now();

      if (
        ((isMouseDownRef.current && !isMouseDownTimelineItemRef.current) || isSplitMode) &&
        !ghostTimelineItemMeta &&
        !isPlaying
      ) {
        onMouseMoveSetPosition(e);
      }
    },
    [ghostTimelineItemMeta, isPlaying, isSplitMode, onMouseMoveSetPosition],
  );

  const handleClick = useCallback(
    (e) => {
      dispatch(setIsPlaying(false));
      if (!isDraggingTimelineItemRef.current) {
        onMouseMoveSetPosition(e);
      }
      isDraggingTimelineItemRef.current = false;
    },
    [dispatch, onMouseMoveSetPosition],
  );

  const withHorizontalScrollBar = timelineWidth + LAYER_PADDING_LEFT > containerWidth;

  const timelinePaddingBottom = withHorizontalScrollBar ? SCROLL_BAR_OFFSET * 2 + SCROLL_BAR_WIDTH : 0;

  const layersDisplayData: TLayersDisplayData = useMemo(() => {
    // const visualLayers: ITimelineLayer[] = timelineLayers.filter(
    //   (layer) => !layerVisualTypeByLayerId || layerVisualTypeByLayerId[layer.id] === 'visual',
    // );
    // const audioLayers: ITimelineLayer[] = timelineLayers.filter(
    //   (layer) => layerVisualTypeByLayerId[layer.id] === 'audio',
    // );

    if (timelineLayers.length === 0) {
      return {
        items: [],
        totalLayersHeight: 0,
        totalFullHeight: 0,
      };
    }

    let yOffset = TIME_SCALE_HEIGHT;

    const addOffset = (offset: number) => {
      yOffset += offset;
      result.totalLayersHeight += offset;
      result.totalFullHeight += offset;
    };

    const firstDisplayLayer = timelineLayers[timelineLayers.length - 1];
    const firstDisplayLayerAcceptTypes = acceptTypesByTimelineLayerId[firstDisplayLayer.id];
    const firstDisplayLayerVisualType = getTimelineLayerVisualType(firstDisplayLayerAcceptTypes);

    const result: TLayersDisplayData = {
      items: [
        {
          type: 'placeholder',
          afterLayerId: firstDisplayLayer?.id || null,
          newTimelineId: uuid(),
          accept: [firstDisplayLayerVisualType],
          yOffset,
          height: LAYER_PLACEHOLDER_HEIGHT,
        },
      ],
      totalLayersHeight: 0, // layers only
      totalFullHeight: yOffset, // layers + scale + placeholders + paddings
    };
    addOffset(LAYER_PLACEHOLDER_HEIGHT);

    for (let i = timelineLayers.length - 1; i >= 0; i--) {
      const layer = timelineLayers[i];

      const acceptTypes = acceptTypesByTimelineLayerId[layer.id];
      const visualType = getTimelineLayerVisualType(acceptTypes);

      const height = LAYER_HEIGHT_BY_TYPE[acceptTypes[0]];

      result.items.push({
        type: 'layer',
        layer,
        yOffset,
        height,
        // accept: 'visual',
      });
      addOffset(LAYER_HEIGHT_BY_TYPE[acceptTypes[0]]);

      // next layer might not exist
      const nextLayer = timelineLayers[i - 1];
      const nextLayerAcceptTypes = nextLayer ? acceptTypesByTimelineLayerId[nextLayer.id] : null;
      const nextLayerVisualType = nextLayerAcceptTypes ? getTimelineLayerVisualType(nextLayerAcceptTypes) : null;
      // placeholder layer between visual and audio layers should accept both audio and visual timeline items
      // i === 0 - the most bottom layer
      const nextPlaceholderAcceptTypes = (
        i === 0 ? [visualType] : [...new Set([visualType, nextLayerVisualType])]
      ) as ILayersDisplayDataPlaceholder['accept'];

      result.items.push({
        type: 'placeholder',
        beforeLayerId: layer.id,
        newTimelineId: uuid(),
        accept: nextPlaceholderAcceptTypes,
        yOffset,
        height: LAYER_PLACEHOLDER_HEIGHT,
      });
      addOffset(LAYER_PLACEHOLDER_HEIGHT);
    }

    // // for correct new audio layer positioning against other audio layers
    // if ((visualLayers.length === 0 || ghostTimelineItem?.type === 'audio') && audioLayers.length) {
    //   const item = result.items[result.items.length - 1] as ILayersDisplayDataPlaceholder;
    //   item.beforeLayerId = null;
    //   item.afterLayerId = audioLayers[0].id;
    // }

    // placeholder layer between visual and audio layers should accept both audio and visual timeline items
    // (result.items[result.items.length - 1] as ILayersDisplayDataPlaceholder).accept = ['audio', 'visual'];

    // for (let i = audioLayers.length - 1; i >= 0; i--) {
    //   const layer = audioLayers[i];

    //   result.items.push({
    //     type: 'layer',
    //     layer,
    //     yOffset,
    //     accept: 'audio',
    //   });
    //   addOffset(LAYER_HEIGHT);

    //   result.items.push({
    //     type: 'placeholder',
    //     beforeLayerId: layer.id,
    //     newTimelineId: uuid(),
    //     accept: ['audio'],
    //     yOffset,
    //   });
    //   addOffset(LAYER_PLACEHOLDER_HEIGHT);
    // }

    // if (audioLayers.length === 0 && videoLayers.length === 0) {
    //   result.items.push({
    //     type: 'placeholder',
    //     newTimelineId: uuid(),
    //     accept: ['visual'],
    //     yOffset,
    //     isEnlarged: true,
    //   });
    //   addOffset(ENLARGED_LAYER_PLACEHOLDER_HEIGHT);

    //   result.items.push({
    //     type: 'placeholder',
    //     afterLayerId: audioLayers[0].id,
    //     newTimelineId: uuid(),
    //     accept: ['visual'],
    //     yOffset,
    //   });
    //   addOffset(LAYER_PLACEHOLDER_HEIGHT);
    // }

    addOffset(timelinePaddingBottom);

    return result;
  }, [timelinePaddingBottom, acceptTypesByTimelineLayerId, timelineLayers]);

  const timelineHeight = (() => {
    return TIMELINE_CONTAINER_HEIGHT;
    // if (layersDisplayData.totalFullHeight > TIMELINE_CONTAINER_HEIGHT) {
    //   return TIMELINE_CONTAINER_HEIGHT;
    // }
    // return layersDisplayData.totalFullHeight;
  })();

  const layersDisplayHeight = timelineHeight - TIME_SCALE_HEIGHT;
  const withVerticalScrollBar = layersDisplayData.totalLayersHeight > layersDisplayHeight;

  // set timeline height to store
  useEffect(() => {
    dispatch(setTimelineHeight(Math.min(layersDisplayData.totalFullHeight, TIMELINE_CONTAINER_HEIGHT)));
  }, [dispatch, layersDisplayData]);

  const stageRef = useRef(null);

  const updateScroll = useCallback(
    ({ newX, newY }) => {
      const stage = stageRef.current;

      newX = newX ?? stage.x();
      newY = newY ?? stage.y();

      const edgeScrollX = Math.max(timelineWidth + LAYER_PADDING_LEFT - containerWidth, 0);
      const edgeScrollY = Math.max(layersDisplayData.totalFullHeight - containerHeight, 0);

      // Ensure the new positions do not exceed the maximum timeline width

      newX = newX < -edgeScrollX ? -edgeScrollX : newX;
      newY = newY < -edgeScrollY ? -edgeScrollY : newY;

      // place the time scale at the top of the timeline
      timeScaleRef.current.y(-newY);
      // horizontalScrollBarRef.current?.y(newY+TIME_SCALE_HEIGHT  + SCROLL_BAR_OFFSET)

      // const verticalVisiblePc = (timelineHeight - TIME_SCALE_HEIGHT) / layersDisplayData.totalFullHeight;
      // verticalScrollBarRef.current?.x(newX + SCROLL_BAR_OFFSET);

      // verticalScrollBarRef.current?.y(-newY - newY * verticalVisiblePc);

      const newPosition: any = {
        x: stage.x(),
        y: newY,
      };
      if (!isPlaying) {
        newPosition.x = newX;
      }
      stage.position(newPosition);

      stage.batchDraw();

      if (!isPlaying) {
        setScrollLeft(-newX);
      }
      setScrollTop(-newY);
    },
    [containerHeight, containerWidth, isPlaying, layersDisplayData.totalFullHeight, timelineWidth],
  );

  const handleWheel = useCallback(
    (e) => {
      e.evt.preventDefault?.();

      const stage = stageRef.current;
      // Determine the amount of movement based on the scroll event
      const deltaX = e.evt.deltaX;
      const deltaY = e.evt.deltaY;

      // Calculate new position
      let newX = stage.x() - deltaX;
      let newY = stage.y() - deltaY;

      // Ensure the new positions do not go below 0
      newX = newX > 0 ? 0 : newX;
      newY = newY > 0 ? 0 : newY;

      updateScroll({
        newX,
        newY,
      });
    },
    [updateScroll],
  );

  const reshreshTimelineOffsetTimeoutRef = useRef();
  useEffect(() => {
    if (!isPlaying && reshreshTimelineOffsetTimeoutRef.current) {
      clearTimeout(reshreshTimelineOffsetTimeoutRef.current);
      reshreshTimelineOffsetTimeoutRef.current = null;
      return;
    }
  }, [isPlaying]);

  // wheel handler setup
  useEffect(() => {
    if (!stageRef.current) {
      return;
    }

    const stage = stageRef.current;
    stage.on('wheel', handleWheel);

    return () => {
      stage.off('wheel', handleWheel);
    };
  }, [containerWidth, handleWheel]);

  const timeScaleRef = useRef(null);

  const verticalScrollBarStageRef = useRef(null);
  const horizontalScrollBarStageRef = useRef(null);
  const horizontalScrollBarRef = useRef(null);
  const verticalScrollBarRef = useRef(null);

  const mouseDownTsRef = useRef<number | null>(null);

  // keep scroll left position when playing to make position indicator look sticky
  const playingScrollLeftRef = useRef<number | null>(null);
  const handlePositionIndicatorUpdate = useCallback(
    (x: number) => {
      // const timelineWidth = timelineSeconds * secondDisplayWidth;

      const THRESHOLD = 0.15;

      const currentOffsetLeft = scrollLeft + playingScrollLeftRef.current;
      if (currentOffsetLeft + containerWidth >= timelineWidth + LAYER_PADDING_LEFT) {
        return;
      }

      const positionIndicatorScreenX = x - scrollLeft - playingScrollLeftRef.current;
      const thresholdPx = containerWidth * THRESHOLD;

      if (positionIndicatorScreenX < thresholdPx) {
        return;
      }

      const offset = positionIndicatorScreenX - containerWidth * THRESHOLD;

      playingScrollLeftRef.current += offset;

      const stage = stageRef.current;

      stage.position({
        x: -(x - thresholdPx),
        y: stage.y(),
      });

      stage.batchDraw();
    },
    [scrollLeft, containerWidth, timelineWidth],
  );

  // reset scrollLeft when stop playing
  useEffect(() => {
    if (isPlaying) {
      return;
    }

    if (playingScrollLeftRef.current) {
      setScrollLeft(scrollLeft + playingScrollLeftRef.current);
    }

    playingScrollLeftRef.current = 0;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPlaying]);

  // useEffect(() => {
  //   if (!containerWidth) {
  //     return;
  //   }

  //   if (timelineWidth + LAYER_PADDING_LEFT < scrollLeft + containerWidth) {
  //     const newScrollLeft = timelineWidth + LAYER_PADDING_LEFT - containerWidth;

  //     setScrollLeft(newScrollLeft);
  //     stageRef.current.position({
  //       x: -newScrollLeft,
  //       y: stageRef.current.y(),
  //     });
  //   }

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [secondDisplayWidth]);

  const secondDisplayWidthRef = useRef(secondDisplayWidth);

  // 1. adjust position when scrolled to the edge of the timeline
  // and the size of the timeline is reduced
  // 2. keep position indicator sticky when resizing the timeline
  useEffect(() => {
    if (!containerWidth || !stageRef.current || isPlaying) {
      return;
    }

    const stage = stageRef.current;

    let stageX = stage.x();
    const stageY = stage.y();

    // const timelineWidth = videoDuration! * secondDisplayWidth + TIMELINE_RIGHT_SPACING;

    const edgeScrollX = Math.max(timelineWidth + LAYER_PADDING_LEFT - containerWidth, 0);
    const edgeScrollY = Math.max(layersDisplayData.totalFullHeight - containerHeight, 0);

    let newX: number | null = null;
    let newY: number | null = null;

    // keep position indicator sticky when resizing the timeline
    const prevSecondDisplayWidth = secondDisplayWidthRef.current;
    if (secondDisplayWidth !== prevSecondDisplayWidth) {
      const prevPositionIndicatorX = currentSeconds * prevSecondDisplayWidth + LAYER_PADDING_LEFT;
      const positionIndicatorX = currentSeconds * secondDisplayWidth + LAYER_PADDING_LEFT;

      const positionIndicatorStageX = prevPositionIndicatorX - -stageX;

      newX = -(positionIndicatorX - positionIndicatorStageX);
      newX = newX > 0 ? 0 : newX;
      stageX = newX;

      secondDisplayWidthRef.current = secondDisplayWidth;
    }

    // ensure stage is not scrolled out of bounds
    if (stageX < -edgeScrollX) {
      newX = stageX < -edgeScrollX ? -edgeScrollX : stageX;
    }
    if (stageY < -edgeScrollY) {
      newY = stageY < -edgeScrollY ? -edgeScrollY : stageY;
    }

    // nothing to do if no overflow
    if (newX === null && newY === null) {
      return;
    }

    // place the time scale at the top of the timeline
    timeScaleRef.current.y(-newY);
    // horizontalScrollBarRef.current?.y(newY +TIME_SCALE_HEIGHT + SCROLL_BAR_OFFSET);
    // verticalScrollBarRef.current?.x(newX + SCROLL_BAR_OFFSET);

    const newPosition: any = {
      x: newX,
      y: newY,
    };

    stage.position(newPosition);

    stage.batchDraw();

    setScrollLeft(-newX);
    setScrollTop(-newY);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    videoDuration, // check width
    layersDisplayData.totalLayersHeight, // check height
    secondDisplayWidth,
  ]);

  useEffect(() => {
    const verticalScrollBarStage = verticalScrollBarStageRef.current;
    verticalScrollBarStage?.on('wheel', handleWheel);

    const horizontalScrollBarStage = horizontalScrollBarStageRef.current;
    horizontalScrollBarStage?.on('wheel', handleWheel);

    return () => {
      verticalScrollBarStage?.off('wheel', handleWheel);
      horizontalScrollBarStage?.off('wheel', handleWheel);
    };
  }, [layersDisplayHeight, layersDisplayData.totalLayersHeight, containerWidth, timelineWidth, handleWheel]);

  const verticalScrollbarHeight = withHorizontalScrollBar
    ? layersDisplayHeight - SCROLL_BAR_OFFSET - SCROLL_BAR_WIDTH
    : layersDisplayHeight;

  const horizontalScrollbarWidth = withVerticalScrollBar
    ? containerWidth - SCROLL_BAR_OFFSET - SCROLL_BAR_WIDTH
    : containerWidth;

  const highlightedLayersDisplayItem = useMemo(() => {
    if (!highlightSelectedTimelineItem && !replacingSelectedTimelineItem) {
      return null;
    }

    if (!selectedTimelineItemId) {
      return null;
    }

    const layersDisplayItems = layersDisplayData.items;

    for (let i = 0; i < layersDisplayItems.length; i++) {
      const item = layersDisplayItems[i];

      if (item.type === 'placeholder') {
        continue;
      }

      const layer = item.layer;

      for (let j = 0; j < layer.timeline.length; j++) {
        const itemId = layer.timeline[j];

        if (itemId !== selectedTimelineItemId) {
          continue;
        }

        return item;
      }
    }

    return null;
  }, [highlightSelectedTimelineItem, layersDisplayData, replacingSelectedTimelineItem, selectedTimelineItemId]);

  const highlightedTimelineItem = useMemo(() => {
    if (!highlightedLayersDisplayItem) {
      return null;
    }

    return (
      <TimelineItem
        itemId={selectedTimelineItemId}
        layer={highlightedLayersDisplayItem.layer}
        layersDisplayItems={layersDisplayData.items}
        yOffset={highlightedLayersDisplayItem.yOffset}
        scrollLeft={scrollLeft}
        scrollTop={scrollTop}
        setCursor={setCursor}
        secondDisplayWidth={secondDisplayWidth}
        isMouseDownTimelineItemRef={isMouseDownTimelineItemRef}
        isDraggingTimelineItemRef={isDraggingTimelineItemRef}
        getContainerRect={getContainerRect}
      />
    );
  }, [
    getContainerRect,
    highlightedLayersDisplayItem,
    layersDisplayData,
    scrollLeft,
    scrollTop,
    secondDisplayWidth,
    selectedTimelineItemId,
    setCursor,
  ]);

  useInitGenerationWalkme({
    timelineLayers,
    highlightedLayersDisplayItem,
    getContainerRect,
    secondDisplayWidth,
    scrollTop,
    scrollLeft,
  });

  if (layersDisplayData.items.length === 0) {
    return null;
  }

  return (
    <S.Container
      maxHeight={timelineHeight}
      onMouseMove={replacingSelectedTimelineItem ? undefined : handleMouseMove}
      onClick={replacingSelectedTimelineItem ? undefined : handleClick}
      onMouseDown={() => {
        isMouseDownRef.current = true;
        mouseDownTsRef.current = Date.now();
      }}
      onMouseUp={() => {
        isMouseDownRef.current = false;
      }}
      ref={containerRef}
    >
      {containerWidth > 0 && (
        <>
          <Stage x={0} y={0} width={containerWidth} height={timelineHeight} ref={stageRef}>
            {layersDisplayData.items.map((item) =>
              item.type === 'placeholder' ? (
                <LayerPlaceholder
                  secondDisplayWidth={secondDisplayWidth}
                  timelineWidth={timelineWidth}
                  beforeLayerId={item.beforeLayerId}
                  afterLayerId={item.afterLayerId}
                  // newTimelineId={item.newTimelineId}
                  yOffset={item.yOffset}
                  key={item.newTimelineId}
                />
              ) : (
                <TimelineLayer
                  timelineWidth={timelineWidth}
                  layer={item.layer}
                  yOffset={item.yOffset}
                  // scrollLeft={scrollLeft}
                  // getContainerRect={getContainerRect}
                  setCursor={setCursor}
                  key={item.layer.id}
                />
              ),
            )}

            <TimelineItems
              layersDisplayItems={layersDisplayData.items}
              scrollLeft={scrollLeft}
              scrollTop={scrollTop}
              setCursor={setCursor}
              secondDisplayWidth={secondDisplayWidth}
              isMouseDownTimelineItemRef={isMouseDownTimelineItemRef}
              isDraggingTimelineItemRef={isDraggingTimelineItemRef}
              getContainerRect={getContainerRect}
            />

            <SnapIndicator totalHeight={layersDisplayData.totalFullHeight} secondDisplayWidth={secondDisplayWidth} />

            {highlightSelectedTimelineItem && (
              <>
                <Layer>
                  <HighlightOverlay
                    width={timelineWidth + SCROLL_BAR_OFFSET + SCROLL_BAR_WIDTH}
                    height={Math.max(layersDisplayData.totalFullHeight, TIMELINE_CONTAINER_HEIGHT)}
                  />
                </Layer>
                {highlightedTimelineItem}
              </>
            )}

            {replacingSelectedTimelineItem && (
              <>
                <Layer>
                  <ReplacingOverlay
                    width={timelineWidth + SCROLL_BAR_OFFSET + SCROLL_BAR_WIDTH}
                    height={Math.max(layersDisplayData.totalFullHeight, TIMELINE_CONTAINER_HEIGHT)}
                  />
                </Layer>
                {highlightedTimelineItem}
              </>
            )}

            <TimeScale
              secondDisplayWidth={secondDisplayWidth}
              timelineWidth={timelineWidth}
              timeScaleRef={timeScaleRef}
              // getContainerRect={getContainerRect}
            />
            <PositionIndicator
              totalHeight={layersDisplayData.totalFullHeight}
              secondDisplayWidth={secondDisplayWidth}
              onPositionIndicatorUpdate={handlePositionIndicatorUpdate}
            />
          </Stage>
          {!isPlaying && withVerticalScrollBar && (
            <S.VerticalScrollbarContainer height={verticalScrollbarHeight}>
              <Stage
                x={0}
                y={0}
                width={SCROLL_BAR_OFFSET + SCROLL_BAR_WIDTH}
                height={verticalScrollbarHeight}
                ref={verticalScrollBarStageRef}
              >
                <Layer>
                  <VerticalScrollBar
                    scrollBarHeight={verticalScrollbarHeight}
                    displayHeight={layersDisplayHeight}
                    contentHeight={layersDisplayData.totalLayersHeight}
                    scrollTop={scrollTop}
                    verticalScrollBarRef={verticalScrollBarRef}
                    onScroll={updateScroll}
                  />
                </Layer>
              </Stage>
            </S.VerticalScrollbarContainer>
          )}
          {!isPlaying && withHorizontalScrollBar && (
            <S.HorizontalScrollbarContainer width={horizontalScrollbarWidth}>
              <Stage
                x={0}
                y={0}
                width={horizontalScrollbarWidth}
                height={SCROLL_BAR_OFFSET + SCROLL_BAR_WIDTH}
                ref={horizontalScrollBarStageRef}
              >
                <Layer>
                  <HorizontalScrollBar
                    scrollBarWidth={horizontalScrollbarWidth}
                    displayWidth={containerWidth}
                    contentWidth={timelineWidth + LAYER_PADDING_LEFT}
                    scrollLeft={scrollLeft}
                    horizontalScrollBarRef={horizontalScrollBarRef}
                    onScroll={updateScroll}
                  />
                </Layer>
              </Stage>
            </S.HorizontalScrollbarContainer>
          )}
        </>
      )}
    </S.Container>
  );
};
export default memo(Timeline);

const HighlightOverlay = memo(({ width, height }: { width: number; height: number }) => {
  const dispatch = useDispatch();

  return (
    <Rect
      x={0}
      y={0}
      width={width}
      height={height}
      fill='rgba(0, 0, 0, 0.7)'
      onClick={() => {
        dispatch(setHighlightSelectedTimelineItem(false));
      }}
    />
  );
});

const ReplacingOverlay = memo(({ width, height }: { width: number; height: number }) => {
  const dispatch = useDispatch();

  return (
    <Rect
      x={0}
      y={0}
      width={width}
      height={height}
      fill='rgba(216, 216, 216, 0.70)'
      onClick={() => {
        dispatch(setReplacingSelectedTimelineItem(false));
      }}
    />
  );
});

interface ISnapIndicator {
  totalHeight: number;
  secondDisplayWidth: number;
}
const SnapIndicator = memo(({ totalHeight, secondDisplayWidth }: ISnapIndicator) => {
  const timelineSnap = useSelector(timelineSnapSelector);

  if (!timelineSnap || timelineSnap?.isPositionIndicator) {
    return null;
  }

  const x = getTimelineXFromSeconds({
    seconds: timelineSnap.seconds,
    secondDisplayWidth,
  });

  return (
    <Layer>
      <Line
        points={[x, 0, x, totalHeight]}
        stroke={defaultTheme.colors.pinkError}
        strokeWidth={1}
        lineCap='round'
        lineJoin='round'
        listening={false}
      />
    </Layer>
  );
});

const VerticalScrollBar = ({
  scrollBarHeight,
  displayHeight,
  contentHeight,
  scrollTop,
  verticalScrollBarRef,
  onScroll,
}: {
  scrollBarHeight: number;
  displayHeight: number;
  contentHeight: number;
  scrollTop: number;
  verticalScrollBarRef: RefObject<any>;
  onScroll: (e: any) => void;
}) => {
  const scrollBarPc = scrollBarHeight / displayHeight;
  const visiblePc = displayHeight / contentHeight;
  const scrollBarLength = displayHeight * visiblePc * scrollBarPc;

  const [isDragging, setIsDragging] = useState(false);
  const [isHover, setIsHover] = useState(false);

  const handleVerticalScroll = useCallback(
    (e) => {
      const newY = -(e.target.y() / visiblePc / scrollBarPc);
      onScroll({ newY });
    },
    [onScroll, scrollBarPc, visiblePc],
  );

  const dragBoundFunc = useCallback(
    (pos) => ({
      x: 0,
      y: Math.max(0, Math.min(scrollBarHeight - scrollBarLength, pos.y)),
    }),
    [scrollBarHeight, scrollBarLength],
  );

  const handleDragStart = useCallback(() => {
    setIsDragging(true);
  }, []);

  const handleDragEnd = useCallback(() => {
    setIsDragging(false);
  }, []);

  const handleMouseEnter = useCallback(() => {
    setIsHover(true);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setIsHover(false);
  }, []);

  return (
    <Rect
      x={0}
      y={!isDragging ? scrollTop * visiblePc * scrollBarPc : undefined}
      width={SCROLL_BAR_WIDTH}
      height={scrollBarLength}
      fill={isHover ? 'rgba(0, 0, 0, 0.5)' : 'rgba(0, 0, 0, 0.2)'}
      cornerRadius={SCROLL_BAR_WIDTH / 2}
      draggable
      dragBoundFunc={dragBoundFunc}
      ref={verticalScrollBarRef}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onDragStart={handleDragStart}
      onDragMove={handleVerticalScroll}
      onDragEnd={handleDragEnd}
    />
  );
};

const HorizontalScrollBar = ({
  scrollBarWidth,
  displayWidth,
  contentWidth,
  scrollLeft,
  horizontalScrollBarRef,
  onScroll,
}: {
  scrollBarWidth: number;
  displayWidth: number;
  contentWidth: number;
  scrollLeft: number;
  horizontalScrollBarRef: RefObject<any>;
  onScroll: (e: any) => void;
}) => {
  const scrollBarPc = scrollBarWidth / displayWidth;
  const visiblePc = displayWidth / contentWidth;
  const scrollBarLength = displayWidth * visiblePc * scrollBarPc;

  const [isDragging, setIsDragging] = useState(false);
  const [isHover, setIsHover] = useState(false);

  const handleVerticalScroll = useCallback(
    (e) => {
      const newX = -(e.target.x() / visiblePc / scrollBarPc);
      onScroll({ newX });
    },
    [onScroll, scrollBarPc, visiblePc],
  );

  const dragBoundFunc = useCallback(
    (pos) => ({
      x: Math.max(0, Math.min(scrollBarWidth - scrollBarLength, pos.x)),
      y: 0,
    }),
    [scrollBarWidth, scrollBarLength],
  );

  const handleDragStart = useCallback(() => {
    setIsDragging(true);
  }, []);

  const handleDragEnd = useCallback(() => {
    setIsDragging(false);
  }, []);

  const handleMouseEnter = useCallback(() => {
    setIsHover(true);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setIsHover(false);
  }, []);

  return (
    <Rect
      x={!isDragging ? scrollLeft * visiblePc * scrollBarPc : undefined}
      y={0}
      width={scrollBarLength}
      height={SCROLL_BAR_WIDTH}
      fill={isHover ? 'rgba(0, 0, 0, 0.5)' : 'rgba(0, 0, 0, 0.2)'}
      cornerRadius={SCROLL_BAR_WIDTH / 2}
      draggable
      dragBoundFunc={dragBoundFunc}
      ref={horizontalScrollBarRef}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onDragStart={handleDragStart}
      onDragMove={handleVerticalScroll}
      onDragEnd={handleDragEnd}
    />
  );
};

interface ITimelineItemsProps {
  layersDisplayItems: TLayerDisplayItem[];
  scrollLeft: number;
  scrollTop: number;
  secondDisplayWidth: number;
  isMouseDownTimelineItemRef: React.MutableRefObject<boolean>;
  isDraggingTimelineItemRef: React.MutableRefObject<boolean>;
  setCursor: (cursor: string) => void;
  getContainerRect: () => DOMRect;
}
const TimelineItems = memo(
  ({
    layersDisplayItems,
    scrollLeft,
    scrollTop,
    secondDisplayWidth,
    isMouseDownTimelineItemRef,
    isDraggingTimelineItemRef,
    setCursor,
    getContainerRect,
  }: ITimelineItemsProps) => {
    // const timelineLayers = useSelector(videoTimelineLayersSeletor);
    const ghostTimelineItemMeta = useSelector(ghostTimelineItemMetaSelector);
    const ghostTimelineItem = useSelector(timelineItemSeletor(ghostTimelineItemMeta?.timelineItemId));

    const items = useMemo(() => {
      const result: ReactElement[] = [];
      let topTimelineItemLayer: ITimelineLayer | null = null;
      let yOffset: number = null;

      for (let i = 0; i < layersDisplayItems.length; i++) {
        const item = layersDisplayItems[i];

        if (item.type === 'placeholder') {
          continue;
        }

        const layer = item.layer;

        for (let j = 0; j < layer.timeline.length; j++) {
          const itemId = layer.timeline[j];

          if (itemId === ghostTimelineItem?.id) {
            topTimelineItemLayer = layer;
            yOffset = item.yOffset;

            continue;
          }

          result.push(
            <TimelineItem
              itemId={itemId}
              layer={layer}
              layersDisplayItems={layersDisplayItems}
              // isVisible={ghostTimelineItemMeta?.timelineItemId !== itemId || isHover}
              yOffset={item.yOffset}
              scrollLeft={scrollLeft}
              scrollTop={scrollTop}
              setCursor={setCursor}
              secondDisplayWidth={secondDisplayWidth}
              isMouseDownTimelineItemRef={isMouseDownTimelineItemRef}
              isDraggingTimelineItemRef={isDraggingTimelineItemRef}
              getContainerRect={getContainerRect}
              key={itemId}
            />,
          );
        }
      }

      if (ghostTimelineItem) {
        result.push(
          <TimelineItem
            itemId={ghostTimelineItem.id}
            layer={topTimelineItemLayer}
            layersDisplayItems={layersDisplayItems}
            // isVisible={ghostTimelineItemMeta?.timelineItemId !== itemId || isHover}
            yOffset={yOffset}
            scrollLeft={scrollLeft}
            scrollTop={scrollTop}
            secondDisplayWidth={secondDisplayWidth}
            isMouseDownTimelineItemRef={isMouseDownTimelineItemRef}
            isDraggingTimelineItemRef={isDraggingTimelineItemRef}
            setCursor={setCursor}
            getContainerRect={getContainerRect}
            key={ghostTimelineItem.id}
          />,
        );
      }

      return result;
    }, [
      getContainerRect,
      ghostTimelineItem,
      isDraggingTimelineItemRef,
      isMouseDownTimelineItemRef,
      layersDisplayItems,
      scrollLeft,
      scrollTop,
      secondDisplayWidth,
      setCursor,
    ]);

    return <>{items}</>;
  },
);

const TIME_SCALE_HEIGHT = 24;
const TIME_SCALE_ITEM_MIN_WIDTH = 100;
export const DISPLAY_FRAMES_PER_SECOND = 30;

function useTimeScale({ secondDisplayWidth, timelineWidth }: { secondDisplayWidth: number; timelineWidth: number }) {
  let secondsPerItem = TIME_SCALE_ITEM_MIN_WIDTH / secondDisplayWidth;

  // Determine the dimension and calculate the rounded amount per item
  let dimension: 'h' | 'm' | 's' | 'f' = 's';
  let amountPerItem = 0;

  if (secondsPerItem >= 3600) {
    dimension = 'h';
    amountPerItem = Math.ceil(secondsPerItem / 3600);
    secondsPerItem = amountPerItem * 3600;
  } else if (secondsPerItem >= 60) {
    dimension = 'm';
    amountPerItem = Math.ceil(secondsPerItem / 60);
    secondsPerItem = amountPerItem * 60;
  } else if (secondsPerItem >= 1) {
    dimension = 's';
    amountPerItem = Math.ceil(secondsPerItem);
    secondsPerItem = amountPerItem;
  } else {
    dimension = 'f';
    amountPerItem = Math.ceil(secondsPerItem * DISPLAY_FRAMES_PER_SECOND);
    secondsPerItem = amountPerItem / DISPLAY_FRAMES_PER_SECOND;
  }

  // Adjust item width based on rounded seconds per item
  const itemWidth = secondsPerItem * secondDisplayWidth;

  // Calculate the number of items based on the total width
  const itemsNum = Math.ceil(timelineWidth / itemWidth);

  return {
    secondsPerItem,
    dimension,
    amountPerItem,
    itemWidth,
    itemsNum,
  };
}

interface ITimeScaleProps {
  secondDisplayWidth: number;
  timelineWidth: number;
  timeScaleRef: RefObject<any>;
}
const TimeScale = ({ secondDisplayWidth, timelineWidth, timeScaleRef }: ITimeScaleProps) => {
  const highlightSelectedTimelineItem = useSelector(highlightSelectedTimelineItemSelector);
  const replacingSelectedTimelineItem = useSelector(replacingSelectedTimelineItemSelector);

  const { dimension, amountPerItem, itemWidth, itemsNum } = useTimeScale({
    secondDisplayWidth,
    timelineWidth,
  });

  const items = useMemo(
    () =>
      [...Array(itemsNum)].map((_, i) => ({
        text: `${formatTime(dimension, i * amountPerItem)}`,
        points: [
          i * itemWidth + LAYER_PADDING_LEFT,
          TIME_SCALE_HEIGHT * 0.2,
          i * itemWidth + LAYER_PADDING_LEFT,
          TIME_SCALE_HEIGHT,
        ],
      })),
    [itemsNum, amountPerItem, dimension, itemWidth],
  );

  const bottomLine = useMemo(
    () => [0, TIME_SCALE_HEIGHT, timelineWidth + LAYER_PADDING_LEFT, TIME_SCALE_HEIGHT],
    [timelineWidth],
  );

  return (
    <>
      <Layer ref={timeScaleRef}>
        {/* horizontal line at the bottom of the time scale */}
        <Line points={bottomLine} stroke={defaultTheme.colors.lightGray48} strokeWidth={1} />

        <Rect
          width={timelineWidth + LAYER_PADDING_LEFT}
          height={TIME_SCALE_HEIGHT}
          fill={defaultTheme.colors.lightGray47}
        />

        {items.map((s) => (
          <Line
            points={s.points} // Vertical line
            stroke={defaultTheme.colors.lightGray48}
            strokeWidth={1}
            key={s.text}
          />
        ))}

        {items.map((s, index) => (
          <Text
            x={LAYER_PADDING_LEFT + index * itemWidth + 5}
            y={7} // Center vertically against the line
            text={s.text}
            fontSize={13}
            fill={defaultTheme.colors.lightGray46}
            key={s.text}
          />
        ))}

        {highlightSelectedTimelineItem && (
          <HighlightOverlay width={timelineWidth + LAYER_PADDING_LEFT} height={TIME_SCALE_HEIGHT + 1} />
        )}
        {replacingSelectedTimelineItem && (
          <ReplacingOverlay width={timelineWidth + LAYER_PADDING_LEFT} height={TIME_SCALE_HEIGHT + 1} />
        )}
      </Layer>
    </>
  );
};

interface IPositionIndicator {
  totalHeight: number;
  secondDisplayWidth: number;
  onPositionIndicatorUpdate: (x: number) => void;
}
const PositionIndicator = memo(({ totalHeight, secondDisplayWidth, onPositionIndicatorUpdate }: IPositionIndicator) => {
  const currentSeconds = useSelector(currentSecondsSeletor);
  const timelineSnap = useSelector(timelineSnapSelector);
  const isPlaying = useSelector(isPlayingSeletor);
  const isSplitMode = useSelector(isSplitModeSeletor);
  const recordingTimelineItemId = useSelector(recordingTimelineItemIdSelector);

  const ref = useRef(null);
  const updatePositionInterval = useRef<number | null>(null);
  const onPositionIndicatorUpdateRef = useRef(onPositionIndicatorUpdate);
  onPositionIndicatorUpdateRef.current = onPositionIndicatorUpdate;

  const x = currentSeconds * secondDisplayWidth + LAYER_PADDING_LEFT;

  useEffect(() => {
    clearTimeout(updatePositionInterval.current);

    if (!isPlaying) {
      ref.current.x(0);
      return;
    }

    const initialTs = Date.now();

    updatePositionInterval.current = setInterval(() => {
      const ts = Date.now();
      const newX = ((ts - initialTs) / 1000) * secondDisplayWidth;

      ref.current.x(newX);
      onPositionIndicatorUpdateRef.current(x + newX);
    }, 1000 / 30) as unknown as number;
  }, [isPlaying, secondDisplayWidth, x]);

  return (
    <Layer>
      <Line
        points={[x, 0, x, totalHeight]}
        stroke={recordingTimelineItemId || timelineSnap?.isPositionIndicator ? defaultTheme.colors.pinkError : '#000'}
        strokeWidth={1}
        lineCap='round'
        lineJoin='round'
        dash={isSplitMode ? [5, 5] : []}
        // shadowColor={recordingTimelineItemId ? defaultTheme.colors.pinkError : undefined}
        // shadowBlur={recordingTimelineItemId ? 1 : undefined}
        // shadowOpacity={recordingTimelineItemId ? 1 : 0}
        // shadowOffsetX={0}
        // shadowOffsetY={0}
        listening={false}
        ref={ref}
      />
    </Layer>
  );
});

interface ILayerPlaceholderProps {
  secondDisplayWidth: number;
  timelineWidth: number;
  beforeLayerId?: string;
  afterLayerId?: string;
  yOffset: number;
}
const LayerPlaceholder = memo(
  ({ secondDisplayWidth, timelineWidth, beforeLayerId, afterLayerId, yOffset }: ILayerPlaceholderProps) => {
    const ghostTimelineItemMeta = useSelector(ghostTimelineItemMetaSelector);
    const ghostTimelineItem = useSelector(timelineItemSeletor(ghostTimelineItemMeta?.timelineItemId));

    const isCurrentPlaceholder = !!(
      ghostTimelineItemMeta &&
      (ghostTimelineItemMeta.hoverBeforeLayerId === beforeLayerId ||
        ghostTimelineItemMeta.hoverAfterLayerId === afterLayerId)
    );

    const { startX, width } = getTimelinePosition({
      timelineItem: ghostTimelineItem,
      startSeconds: ghostTimelineItemMeta?.seconds,
      secondDisplayWidth,
    });

    const color = !isCurrentPlaceholder
      ? defaultTheme.colors.lightGray45
      : (ghostTimelineItem && itemColorsByType[ghostTimelineItem.type]?.[1]) || '#3498db';

    return (
      <Layer>
        <Line
          x={0}
          y={yOffset + LAYER_PLACEHOLDER_HEIGHT / 2}
          points={[0, 0, timelineWidth, 0]}
          stroke={color}
          strokeWidth={!isCurrentPlaceholder ? 1 : 2}
          listening={false}
        />
        {isCurrentPlaceholder && (
          <Rect
            x={startX}
            y={yOffset}
            height={LAYER_PLACEHOLDER_HEIGHT}
            width={width}
            stroke={color}
            dash={[5, 5]}
            strokeWidth={2}
            cornerRadius={4}
          />
        )}
      </Layer>
    );
  },
);

interface ILayerProps {
  timelineWidth: number;
  layer: ITimelineLayer;
  yOffset: number;
  // containerWidth: number;
  // scrollLeft: number;
  // getContainerRect: () => DOMRect;
  setCursor: (cursor: string) => void;
}
const TimelineLayer = memo(
  ({
    timelineWidth,
    layer,
    yOffset,
    setCursor,
  }: // scrollLeft, getContainerRect,
  ILayerProps) => {
    const acceptTypesByTimelineLayerId = useSelector(acceptTypesByTimelineLayerIdSelector);
    const acceptTypes = acceptTypesByTimelineLayerId[layer.id];

    if (!acceptTypes) {
      return null;
    }

    const height = LAYER_HEIGHT_BY_TYPE[acceptTypes[0]];

    // const ghostTimelineItemMeta = useSelector(ghostTimelineItemMetaSelector);
    // const ghostTimelineItem = useSelector(timelineItemSeletor(ghostTimelineItemMeta?.timelineItemId));

    // const ghostTimelineLayerPrevState = useSelector(ghostTimelineLayerPrevStateSelector);

    // const [isHover, setIsHover] = useState(false);

    // const getScrollableContainerRect = useGetNodeRect(scrollableContainerRef);

    return (
      <>
        <Layer
          x={0}
          y={yOffset}
          onMouseEnter={() => {
            setCursor('default');
          }}
        >
          <Rect
            width={timelineWidth + LAYER_PADDING_LEFT}
            height={height}
            // stroke={defaultTheme.colors.lightGray45}
            // strokeWidth={isSelected ? 2 : 0}
            // fill={defaultTheme.colors.lightGray47}
            cornerRadius={2}
          />
        </Layer>
      </>
    );
  },
);

interface ITimelineItemProps {
  itemId: ITimelineItem['id'];
  item?: ITimelineItem;
  layer: ITimelineLayer;
  layersDisplayItems: TLayersDisplayData['items'];
  // isVisible?: boolean;
  yOffset?: number;
  scrollLeft: number;
  scrollTop: number;
  secondDisplayWidth: number;
  isMouseDownTimelineItemRef: MutableRefObject<boolean>;
  isDraggingTimelineItemRef: MutableRefObject<boolean>;
  setCursor: (cursor: string) => void;
  getContainerRect: () => DOMRect;
}
const TimelineItem = memo(({ itemId, ...props }: ITimelineItemProps) => {
  const timelineItem = useSelector(timelineItemSeletor(itemId));

  if (!timelineItem) {
    return null;
  }

  return <TimelineItemWithData itemId={itemId} item={timelineItem} {...props} />;
});

const GHOST_DASH = [5, 5];

const TimelineItemWithData = memo(
  ({
    itemId,
    item,
    layer,
    layersDisplayItems,
    // isVisible,
    yOffset,
    scrollLeft,
    scrollTop,
    secondDisplayWidth,
    isMouseDownTimelineItemRef,
    isDraggingTimelineItemRef,
    setCursor,
    getContainerRect,
  }: ITimelineItemProps) => {
    const dispatch = useDispatch();

    const ref = useRef(null);

    const selectedTimelineItemId = useSelector(selectedTimelineItemIdSelector);
    const ghostTimelineItemMeta = useSelector(ghostTimelineItemMetaSelector);
    const ghostTimelineItem = useSelector(timelineItemSeletor(ghostTimelineItemMeta?.timelineItemId));
    const isSplitMode = useSelector(isSplitModeSeletor);
    const trimTimelineItemMeta = useSelector(trimTimelineItemMetaSelector);
    const recordingTimelineItemId = useSelector(recordingTimelineItemIdSelector);
    const itemProcessingStatus = useSelector(processingTimelineItemSelector(itemId));
    const currentSeconds = useSelector(currentSecondsSeletor);
    const replacingSelectedTimelineItem = useSelector(replacingSelectedTimelineItemSelector);

    const [isHover, setIsHover] = useState(false);

    // const mouseDownRef = useRef<{ x: number; y: number } | null>(null);
    // const leftTrimRef = useRef<HTMLDivElement>(null);
    // const rightTrimRef = useRef<HTMLDivElement>(null);
    const [splitItemSeconds, setSplitItemSeconds] = useState<number | null>(null);
    const splitterRef = useRef(null);

    const trimLeftInnerXRef = useRef<number | null>(null);
    const trimRightInnerXRef = useRef<number | null>(null);

    // const cloudAsset = useSelector(cloudAssetSelector(item.cloudAssetId));
    // const image = cloudAsset && getCloudAssetImage(cloudAsset);

    const handleTrimEnter = useCallback(() => {
      setCursor('ew-resize');
      setIsHover(true);
    }, [setCursor]);

    const handleTrimLeave = useCallback(() => {
      setCursor('default');
      setIsHover(false);
    }, [setCursor]);

    const handleClick = useCallback(
      (e) => {
        // no layer when moving to a placeholder layer
        if (!layer) {
          return;
        }

        if (isSplitMode) {
          setSplitItemSeconds(null);
          const containerRect = getContainerRect();

          const mouseTimelineX = e.evt.clientX + scrollLeft - containerRect.left;
          const draggedTimelineItemX = Math.max(e.target.x(), 0);

          const innerX = mouseTimelineX - draggedTimelineItemX;
          const seconds = innerX / secondDisplayWidth + item.start;

          dispatch(
            splitTimelineItem({
              timelineLayerId: layer.id,
              timelineItemId: itemId,
              seconds,
            }),
          );
          dispatch(addUndo({}));
          dispatch(saveVideo({}));

          return;
        }

        // mouseDownRef.current = null;

        // dispatch(setSelectedTimelineLayerId(layer.id));
        dispatch(setSelectedTimelineItemId(itemId));

        // to prevent unselect on document click
        clickTargetRef.current = {
          isTimelineItemTarget: true,
        };
      },
      [dispatch, getContainerRect, isSplitMode, item.start, itemId, layer, scrollLeft, secondDisplayWidth],
    );

    const setSplitterPosition = useCallback(
      (e) => {
        if (!splitterRef.current) {
          return;
        }

        const containerRect = getContainerRect();
        const mouseTimelineX = e.evt.clientX + scrollLeft - containerRect.left;
        splitterRef.current.x(mouseTimelineX - 3);

        const seconds = (item.trimStart || 0) + (mouseTimelineX - LAYER_PADDING_LEFT) / secondDisplayWidth - item.start;

        setSplitItemSeconds(seconds);
      },
      [getContainerRect, item.start, item.trimStart, scrollLeft, secondDisplayWidth],
    );
    // const withImage = item.type === 'video' || item.type === 'image' || item.type === 'gif';

    const isSelected = itemId === selectedTimelineItemId;
    const isPlaceholder = ghostTimelineItemMeta?.timelineItemId === itemId;
    const isRecording = recordingTimelineItemId === itemId;

    const { startX, width, endX } = getTimelinePosition({
      timelineItem: item,
      startSeconds: item.start,
      secondDisplayWidth,
    });

    const layerVerticalPadding = LAYER_VERTICAL_PADDING_BY_TYPE[item.type];
    const layerY = layer ? yOffset + layerVerticalPadding : null;

    // const videoConfig = useSelector(videoConfigSeletor);

    // const intersectLayerItemRef = useRef<ILayersDisplayDataLayer | ILayersDisplayDataPlaceholder | null>(null);
    const getHoverLayerDisplayItem = useCallback(
      ({ clientY }) => {
        const containerRect = getContainerRect();
        const mouseY = clientY + scrollTop - containerRect.top;

        const layersDisplayItem = layersDisplayItems.find((testLayerItem) => {
          return mouseY > testLayerItem.yOffset && mouseY < testLayerItem.yOffset + testLayerItem.height;
        });

        return layersDisplayItem;
      },
      [getContainerRect, layersDisplayItems, scrollTop],
    );

    // const ghostTimelineLayerPrevState = useSelector(ghostTimelineLayerPrevStateSelector);

    const handleDragMove = useCallback(
      (e) => {
        const containerRect = getContainerRect();

        const mouseTimelineX = e.evt.clientX + scrollLeft - containerRect.left - LAYER_PADDING_LEFT;

        const mouseSeconds = mouseTimelineX / secondDisplayWidth;

        let timelineItemStartX = e.target.x() - LAYER_PADDING_LEFT - INTER_TIMELINE_ITEM_SPACING / 2;
        let draggedTimelineItemX = Math.max(timelineItemStartX, 0);

        let draggedTimelineItemStart = draggedTimelineItemX / secondDisplayWidth;

        // find the layer or placeholder that the mouse is hovering
        const hoverLayerDisplayItem = getHoverLayerDisplayItem({ clientY: e.evt.clientY });

        const { seconds, snapSeconds } = getTimelineSnap({
          startSeconds: draggedTimelineItemStart,
          timelineItem: item,
          secondDisplayWidth,
          currentSeconds,
        });

        const newGhostTimelineItemMeta: IGhostTimelineItemMeta = {
          timelineItemId: itemId,
          timelineLayerId: (hoverLayerDisplayItem as ILayersDisplayDataLayer)?.layer?.id || null,
          hoverBeforeLayerId: (hoverLayerDisplayItem as ILayersDisplayDataPlaceholder)?.beforeLayerId || null,
          hoverAfterLayerId: (hoverLayerDisplayItem as ILayersDisplayDataPlaceholder)?.afterLayerId || null,
          newTimelineId: (hoverLayerDisplayItem as ILayersDisplayDataPlaceholder)?.newTimelineId || null,
          seconds,
        };

        dispatch(
          timelineGhostItemHoverTimelineLayer({
            newGhostTimelineItemMeta,
            mouseSeconds,
            hoverLayerDisplayItem,
          }),
        );

        dispatch(
          setTimelineSnap(
            snapSeconds !== null
              ? {
                  seconds: snapSeconds,
                  isPositionIndicator: snapSeconds === currentSeconds,
                }
              : null,
          ),
        );
      },
      [
        currentSeconds,
        dispatch,
        getContainerRect,
        getHoverLayerDisplayItem,
        item,
        itemId,
        scrollLeft,
        secondDisplayWidth,
      ],
    );

    // animate border color
    const borderColorShared = useSharedValue(0);
    const borderColor = interpolateColor(borderColorShared.value, [0, 1], ['#ffbfcb', 'red']);

    const animateBorderColor = useCallback(() => {
      if (!isRecording) {
        return;
      }

      const newValue = borderColorShared.value === 0 ? 1 : 0;
      borderColorShared.value = withTiming(
        newValue,
        {
          duration: 1000,
          easing: Easing.ease,
        },
        animateBorderColor,
      );
    }, [borderColorShared, isRecording]);

    const [isBulbHover, setIsBulbHover] = useState(false);
    const handleBulbEnter = useCallback(() => {
      setIsBulbHover(true);
      setCursor('pointer');
      setIsHover(true);
    }, [setCursor]);
    const handleBulbLeave = useCallback(() => {
      setIsBulbHover(false);
      setCursor('default');
      setIsHover(false);
    }, [setCursor]);
    const handleBulbClick = useCallback(
      (e) => {
        handleClick(e);

        const containerRect = getContainerRect();

        dispatch(
          setShowTimelineItemGeneratedMeta({
            timelineItemId: itemId,
            x: startX - scrollLeft,
            y: layerY - scrollTop + containerRect.top,
            width,
          }),
        );
      },
      [dispatch, getContainerRect, handleClick, itemId, layerY, scrollLeft, scrollTop, startX, width],
    );

    useEffect(() => {
      if (isRecording) {
        animateBorderColor();
      }
    }, [animateBorderColor, isRecording]);

    const timelineItemHeight = getTimelineItemHeight(item.type);

    const trimControlsHeight = timelineItemHeight - TRIM_CONTROL_MARGIN * 2 - TRIM_CONTROL_LINE_SPACING;
    const trimControlsY = layerY + TRIM_CONTROL_MARGIN;

    const fillLinearGradientStartPoint = useMemo(() => ({ x: 0, y: 0 }), []);
    const fillLinearGradientEndPoint = useMemo(() => ({ x: width, y: 0 }), [width]);
    const c1 = itemColorsByType[item.type]?.[0] || '#3498db';
    const c2 = itemColorsByType[item.type]?.[1] || '#3498db';
    const fillLinearGradientColorStops = useMemo(() => {
      const p2 = (TRIM_CONTROL_WIDTH * 2) / width;
      let p2pc = p2 / width;

      const p3 = width - TRIM_CONTROL_WIDTH * 2;
      let p3pc = p3 / width;

      // if item is small hardcode the gradient stops
      // otherwise stops overflow the 1 value and cause an error
      if (p3pc < 0 || p3pc < p2pc || p3pc > 1) {
        p2pc = 0.25;
        p3pc = 0.75;
      }

      const result = [0, c1, p2pc, c2, p3pc, c2, 1, c1];
      return result;
    }, [c1, c2, width]);

    const centerRectWidth = width - TRIM_CONTROL_WIDTH * 2 - TRIM_CONTROL_MARGIN * 2;
    // check if there is enough place for the center rect
    const withCenterRect = centerRectWidth > 0;

    const borderWidth = 2;

    const isProcessing = itemProcessingStatus?.status === 'PROCESSING';
    const isError = itemProcessingStatus?.status === 'ERROR';

    return (
      <Layer>
        {isPlaceholder && ghostTimelineItemMeta.timelineLayerId && (
          <Rect
            x={startX}
            y={layerY}
            height={timelineItemHeight}
            width={width}
            stroke={c2}
            dash={GHOST_DASH}
            strokeWidth={2}
            cornerRadius={4}
          />
        )}
        <Rect
          x={!isPlaceholder ? startX : ref.current.x()}
          y={!isPlaceholder ? layerY : ref.current.y()}
          width={width}
          height={timelineItemHeight}
          // stroke={isRecording ? borderColor : isSelected || isPlaceholder ? '#00cae0' : 'transparent'}
          stroke={isRecording ? borderColor : c2}
          strokeWidth={borderWidth}
          cornerRadius={4}
          // fill={item.enabled === false ? 'rgba(0, 0, 0, 0.5)' : item.type === 'group' ? '#3498db' : '#9da8ae'}

          fillLinearGradientStartPoint={fillLinearGradientStartPoint}
          fillLinearGradientEndPoint={fillLinearGradientEndPoint}
          fillLinearGradientColorStops={fillLinearGradientColorStops}
          draggable
          opacity={item.enabled === false || isPlaceholder ? 0.5 : 1}
          onMouseDown={() => {
            isMouseDownTimelineItemRef.current = true;
          }}
          onMouseUp={() => {
            isMouseDownTimelineItemRef.current = false;
          }}
          onDragStart={() => {
            dispatch(setIsSplitMode(false));
            setCursor('grab');
            dispatch(setIsPlaying(false));

            isDraggingTimelineItemRef.current = true;

            const newGhostTimelineItemMeta: IGhostTimelineItemMeta = {
              timelineItemId: itemId,
              timelineLayerId: layer.id,
              hoverBeforeLayerId: null,
              hoverAfterLayerId: null,
              newTimelineId: null,
              seconds: currentSeconds,
            };
            dispatch(setGhostTimelineItemMeta(newGhostTimelineItemMeta));
          }}
          onDragMove={handleDragMove}
          onDragEnd={() => {
            dispatch(setGhostTimelineItemMeta(null));
            dispatch(setGhostTimelineLayerPrevState(null));
            dispatch(setSelectedTimelineItemId(ghostTimelineItemMeta!.timelineItemId));

            // layer placeholder
            if (ghostTimelineItemMeta.newTimelineId) {
              dispatch(
                addToTimeline({
                  timelineItem: ghostTimelineItem,
                  start: ghostTimelineItemMeta.seconds,
                  beforeLayerId: ghostTimelineItemMeta.hoverBeforeLayerId,
                  afterLayerId: ghostTimelineItemMeta.hoverAfterLayerId,
                  newTimelineId: ghostTimelineItemMeta.newTimelineId,
                }),
              );
            }

            dispatch(setTimelineSnap(null));

            dispatch(removeEmptyTimelineLayers());

            dispatch(setSelectedTimelineItemId(itemId));
            // dispatch(setSelectedTimelineLayerId(layer?.id || ghostTimelineItemMeta.newTimelineId));

            // to prevent unselect on document click
            clickTargetRef.current = {
              isTimelineItemTarget: true,
            };

            dispatch(updateVideoDuration());
            dispatch(updateCanvas({}));

            dispatch(addUndo({}));
            dispatch(saveVideo({}));
          }}
          onMouseEnter={() => {
            if (!isSplitMode) {
              setCursor('grab');
            }
            setIsHover(true);
          }}
          onMouseLeave={() => {
            setCursor('default');
            setIsHover(false);
            setSplitItemSeconds(null);
          }}
          onMouseMove={isSplitMode ? setSplitterPosition : undefined}
          onClick={handleClick}
          ref={ref}
        />

        {!isPlaceholder && !isRecording && !isProcessing && !isSplitMode && !replacingSelectedTimelineItem && (
          <>
            {/* start control */}
            <Group
              x={
                trimTimelineItemMeta?.timelineItemId !== item.id || trimTimelineItemMeta?.side !== 'start'
                  ? startX + TRIM_CONTROL_MARGIN
                  : undefined
              }
              y={trimControlsY}
              opacity={item.enabled === false ? 0.5 : isSelected ? 1 : isHover ? 0.5 : 0}
              draggable
              onMouseDown={() => {
                isMouseDownTimelineItemRef.current = true;
              }}
              onMouseUp={() => {
                isMouseDownTimelineItemRef.current = false;
              }}
              onMouseEnter={handleTrimEnter}
              onMouseLeave={handleTrimLeave}
              onDragStart={() => {
                dispatch(
                  setTrimTimelineItemMeta({
                    timelineLayerId: layer.id,
                    timelineItemId: itemId,
                    side: 'start',
                    innerX: trimLeftInnerXRef.current,
                  }),
                );
                isDraggingTimelineItemRef.current = true;
              }}
              onDragMove={(e) => {
                const control = e.target;

                const startTimelineX = Math.max(
                  control.x() - TRIM_CONTROL_MARGIN - INTER_TIMELINE_ITEM_SPACING / 2 - LAYER_PADDING_LEFT,
                  LAYER_PADDING_LEFT,
                );
                const seconds = startTimelineX / secondDisplayWidth;

                const snapSeconds = getSnapForSecondTime({
                  timelineItemId: itemId,
                  currentSeconds,
                  seconds,
                  secondDisplayWidth,
                });

                dispatch(
                  setTimelineSnap(
                    snapSeconds
                      ? {
                          seconds: snapSeconds,
                          isPositionIndicator: snapSeconds === currentSeconds,
                        }
                      : null,
                  ),
                );

                dispatch(
                  trimTimelineItemStart({
                    start: snapSeconds || seconds,
                    onTrim: ({ start }) => {
                      const timelineX =
                        start * secondDisplayWidth +
                        TRIM_CONTROL_MARGIN +
                        INTER_TIMELINE_ITEM_SPACING / 2 +
                        LAYER_PADDING_LEFT;

                      control.x(timelineX);
                      control.y(trimControlsY);
                    },
                  }),
                );
              }}
              onDragEnd={() => {
                dispatch(setTrimTimelineItemMeta(null));
                dispatch(updateVideoDuration());
                dispatch(addUndo({}));
                dispatch(saveVideo({}));

                dispatch(setSelectedTimelineItemId(itemId));
                dispatch(setTimelineSnap(null));

                // to prevent unselect on document click
                clickTargetRef.current = {
                  isTimelineItemTarget: true,
                };
              }}
            >
              <Rect
                width={TRIM_CONTROL_WIDTH}
                height={timelineItemHeight - TRIM_CONTROL_MARGIN * 2}
                fill='transparent'
                cornerRadius={[4, 1, 1, 4]}
              />
              <Line
                points={[
                  TRIM_CONTROL_WIDTH / 2 - 1,
                  TRIM_CONTROL_LINE_SPACING,
                  TRIM_CONTROL_WIDTH / 2 - 1,
                  trimControlsHeight,
                ]}
                stroke='#fff'
                strokeWidth={1}
                listening={false}
              />
            </Group>
            {/* end control */}
            <Group
              x={
                trimTimelineItemMeta?.timelineItemId !== item.id || trimTimelineItemMeta?.side !== 'end'
                  ? endX - TRIM_CONTROL_WIDTH - TRIM_CONTROL_MARGIN
                  : undefined
              }
              y={trimControlsY}
              opacity={item.enabled === false ? 0.5 : isSelected ? 1 : isHover ? 0.5 : 0}
              draggable
              onMouseDown={() => {
                isMouseDownTimelineItemRef.current = true;
              }}
              onMouseUp={() => {
                isMouseDownTimelineItemRef.current = false;
              }}
              onMouseEnter={handleTrimEnter}
              onMouseLeave={handleTrimLeave}
              onDragStart={() => {
                dispatch(
                  setTrimTimelineItemMeta({
                    timelineLayerId: layer.id,
                    timelineItemId: itemId,
                    side: 'end',
                    innerX: trimRightInnerXRef.current,
                  }),
                );
                isDraggingTimelineItemRef.current = true;
              }}
              onDragMove={(e) => {
                const control = e.target;

                const endTimelineX =
                  control.x() +
                  TRIM_CONTROL_WIDTH +
                  TRIM_CONTROL_MARGIN +
                  INTER_TIMELINE_ITEM_SPACING / 2 -
                  LAYER_PADDING_LEFT;
                let seconds = endTimelineX / secondDisplayWidth;

                // const minEndTimelineX = startX + TRIM_CONTROL_WIDTH + TRIM_CONTROL_MARGIN;
                // const minSeconds = Math.max(item.start + 5, minEndTimelineX / secondDisplayWidth);

                // seconds = Math.max(seconds, minSeconds);

                const snapSeconds = getSnapForSecondTime({
                  timelineItemId: itemId,
                  currentSeconds,
                  seconds,
                  secondDisplayWidth,
                });

                dispatch(
                  setTimelineSnap(
                    snapSeconds
                      ? {
                          seconds: snapSeconds,
                          isPositionIndicator: snapSeconds === currentSeconds,
                        }
                      : null,
                  ),
                );

                dispatch(
                  trimTimelineItemEnd({
                    end: snapSeconds || seconds,
                    onTrim: ({ end }) => {
                      const timelineX =
                        end * secondDisplayWidth -
                        TRIM_CONTROL_WIDTH -
                        TRIM_CONTROL_MARGIN -
                        INTER_TIMELINE_ITEM_SPACING / 2 +
                        LAYER_PADDING_LEFT;

                      control.x(timelineX);
                      control.y(trimControlsY);
                    },
                  }),
                );
              }}
              onDragEnd={() => {
                dispatch(setTrimTimelineItemMeta(null));
                dispatch(updateVideoDuration());
                dispatch(addUndo({}));
                dispatch(saveVideo({}));

                dispatch(setSelectedTimelineItemId(itemId));
                dispatch(setTimelineSnap(null));

                // to prevent unselect on document click
                clickTargetRef.current = {
                  isTimelineItemTarget: true,
                };
              }}
            >
              <Rect
                width={TRIM_CONTROL_WIDTH}
                height={timelineItemHeight - TRIM_CONTROL_MARGIN * 2}
                // cornerRadius={[1, 4, 4, 1]}
                fill='transparent'
              />
              <Line
                points={[
                  TRIM_CONTROL_WIDTH / 2 + 1,
                  TRIM_CONTROL_LINE_SPACING,
                  TRIM_CONTROL_WIDTH / 2 + 1,
                  trimControlsHeight,
                ]}
                stroke='#fff'
                strokeWidth={1}
                listening={false}
              />
            </Group>
            {/* <Group y={layerY} listening={false} ref={splitterRef}> */}
            {/* <Line points={[0, 0, 0, timelineItemHeight]} stroke={'#aaa'} strokeWidth={1} /> */}
            {/* <Line points={[6, 0, 6, timelineItemHeight]} stroke={'#aaa'} strokeWidth={1} /> */}
            {/*</Group> */}
          </>
        )}

        {/* )} */}
        {/* {recordingTimelineItemId === itemId ? (
          <Text x={startX} y={layerY} fontSize={12} width={100} fill='white' text='Recording...' />
        ) : itemProcessingStatus?.isProcessing ? (
          <Text x={startX} y={layerY} fontSize={12} width={100} fill='white' text='Processing...' />
        ) : null} */}

        {/* white rect in the center */}
        {!isPlaceholder && withCenterRect && (
          <Rect
            x={startX + TRIM_CONTROL_WIDTH + TRIM_CONTROL_MARGIN}
            y={layerY + borderWidth / 2}
            width={centerRectWidth}
            height={timelineItemHeight - borderWidth}
            fill={defaultTheme.colors.white}
            opacity={item.enabled === false ? 0.5 : 1}
            listening={false}
          />
        )}

        {/* white transparent overlay over the whole item, keeping borders */}
        {!isPlaceholder &&
          (!isSelected || isRecording || isProcessing || isSplitMode || replacingSelectedTimelineItem) && (
            <Rect
              x={startX + borderWidth / 2}
              y={layerY + borderWidth / 2}
              width={width - borderWidth}
              height={timelineItemHeight - borderWidth}
              cornerRadius={4}
              fill={
                isRecording || isProcessing || isSplitMode || (!isSelected && !isHover)
                  ? defaultTheme.colors.white
                  : defaultTheme.colors.transparentWhite
              }
              opacity={item.enabled === false ? 0.5 : 1}
              listening={false}
            />
          )}

        {!isPlaceholder &&
          (isProcessing ? (
            <>
              {width > (10 + 12) * 2 && (
                <LoadingIndicator
                  x={startX + width / 2 - timelineItemHeight / 2 / 2}
                  y={layerY + timelineItemHeight / 2}
                  innerRadius={10}
                  outerRadius={12}
                  stroke={c2}
                  angle={300}
                  listening={false}
                />
              )}
            </>
          ) : (
            <>
              <Preview
                item={item}
                seconds={item.trimStart || 0}
                opacity={item.enabled === false ? 0.5 : 1}
                x={startX + TRIM_CONTROL_MARGIN * 2 + TRIM_CONTROL_WIDTH}
                y={layerY + borderWidth / 2 + PREVIEW_MARGIN}
                maxWidth={Math.min(
                  splitItemSeconds
                    ? splitItemSeconds * secondDisplayWidth -
                        TRIM_CONTROL_WIDTH -
                        TRIM_CONTROL_MARGIN -
                        SPLITTER_WIDTH / 2
                    : Infinity,
                  width - TRIM_CONTROL_WIDTH * 2 - TRIM_CONTROL_MARGIN * 2 - PREVIEW_MARGIN * 2,
                )}
                height={timelineItemHeight - borderWidth - PREVIEW_MARGIN * 2}
              />
              {splitItemSeconds ? (
                <Preview
                  item={item}
                  seconds={splitItemSeconds}
                  opacity={item.enabled === false ? 0.5 : 1}
                  x={startX + splitItemSeconds * secondDisplayWidth + SPLITTER_WIDTH}
                  y={layerY + borderWidth / 2 + PREVIEW_MARGIN}
                  maxWidth={Math.min(
                    width -
                      TRIM_CONTROL_WIDTH * 2 -
                      TRIM_CONTROL_MARGIN * 2 -
                      PREVIEW_MARGIN * 2 -
                      splitItemSeconds * secondDisplayWidth +
                      SPLITTER_WIDTH / 2,
                    width - TRIM_CONTROL_WIDTH * 2 - TRIM_CONTROL_MARGIN * 2 - PREVIEW_MARGIN * 2,
                  )}
                  height={timelineItemHeight - borderWidth - PREVIEW_MARGIN * 2}
                />
              ) : null}
            </>
          ))}
        {/* <Text x={startX} y={layerY} width={100} fontSize={10} fill='white' text={item.id} listening={false} /> */}
        {isError ? (
          <Text
            x={startX + 16}
            y={layerY + timelineItemHeight / 2 - 5}
            width={100}
            fontSize={20}
            fill='red'
            opacity={item.enabled === false ? 0.5 : 1}
            text={'Error'}
            // listening={false}
            onClick={() => {
              if (itemProcessingStatus?.retryFunctionId) {
                retryFunctions[itemProcessingStatus.retryFunctionId]?.();
              }
            }}
          />
        ) : (
          !isRecording &&
          !isProcessing &&
          ['video', 'audio', 'image', 'gif'].includes(item.type) &&
          !item.cloudAssetId && (
            <Text
              x={startX + 16}
              y={layerY + timelineItemHeight / 2 - 5}
              width={100}
              fontSize={20}
              fill='red'
              opacity={item.enabled === false ? 0.5 : 1}
              text={'Error (no cloud asset attached)'}
              // listening={false}
              onClick={() => {
                if (itemProcessingStatus?.retryFunctionId) {
                  retryFunctions[itemProcessingStatus.retryFunctionId]?.();
                }
              }}
            />
          )
        )}

        {/* splitter */}
        {isHover && isSplitMode && (
          <Rect
            y={layerY - borderWidth / 2}
            width={SPLITTER_WIDTH}
            height={timelineItemHeight + borderWidth}
            fill={defaultTheme.colors.lightGray47}
            listening={false}
            ref={splitterRef}
          />
        )}

        {width > TRIM_CONTROL_WIDTH + 21 + 3 + 15 + 4 + TRIM_CONTROL_WIDTH &&
          item.generatedMeta?.generatedFrom === 'stock-video' &&
          !isSplitMode &&
          !isPlaceholder && (
            <>
              <Rect
                width={21}
                height={21}
                x={endX - 3 - 15 - 4 - TRIM_CONTROL_WIDTH}
                y={layerY + 3}
                fill={isBulbHover ? defaultTheme.colors.lightGray47 : 'transparent'}
                cornerRadius={4}
                onMouseEnter={handleBulbEnter}
                onMouseLeave={handleBulbLeave}
                onClick={handleBulbClick}
              />
              <SVGIcon
                icon={BulbIcon}
                width={15}
                height={15}
                x={endX - 15 - 4 - TRIM_CONTROL_WIDTH}
                y={layerY + 4}
                listening={false}
              />
            </>
          )}

        {/* white transparent overlay over the whole item to block interactions */}
        {replacingSelectedTimelineItem && (
          <Rect
            x={startX}
            y={layerY}
            width={width}
            height={timelineItemHeight}
            cornerRadius={4}
            fill={defaultTheme.colors.transparentWhite}
            opacity={0.5}
          />
        )}
      </Layer>
    );
  },
);

const Preview = ({
  item,
  seconds,
  opacity,
  x,
  y,
  maxWidth,
  height,
}: {
  item: ITimelineItem;
  seconds: number;
  opacity?: number;
  x: number;
  y: number;
  maxWidth: number;
  height: number;
}) => {
  const renderComponent = useCallback(() => {
    if (item.type === 'image') {
      return <PreviewImage opacity={opacity} item={item} x={x} y={y} height={height} />;
    }
    if (item.type === 'gif') {
      return <PreviewGif opacity={opacity} item={item} x={x} y={y} height={height} />;
    }
    if (item.type === 'video') {
      return (
        <PreviewVideo opacity={opacity} item={item} seconds={seconds} x={x} y={y} width={maxWidth} height={height} />
      );
    }
    if (item.type === 'text') {
      return <PreviewText opacity={opacity} item={item} x={x} y={y} height={height} />;
    }
    if (item.type === 'audio') {
      return <PreviewAudio opacity={opacity} item={item} x={x} y={y} width={maxWidth} height={height} />;
    }
    return null;
  }, [item, opacity, x, y, height, seconds, maxWidth]);

  const clipFunc = useCallback(
    (ctx) => {
      ctx.rect(x, y, maxWidth, height); // Define the clipping rectangle
    },
    [height, maxWidth, x, y],
  );

  if (maxWidth <= 0) {
    return null;
  }

  return <Group clipFunc={clipFunc}>{renderComponent()}</Group>;
};

function calculateWidthAspectRatioFit(width: number, height: number, containerHeight: number) {
  const ratio = containerHeight / height;
  return width * ratio;
}

const PreviewText = ({
  item,
  opacity,
  x,
  y,
  height,
}: {
  item: ITimelineItem;
  opacity?: number;
  x: number;
  y: number;
  height: number;
}) => {
  return (
    <Text
      opacity={opacity}
      x={x}
      y={y + height / 2 - 13 / 2}
      text={item.text?.split('\n').join(' ').replace(/ {2,}/g, ' ')}
      fontSize={13}
      fontStyle='bold'
      fill={defaultTheme.colors.lightGray46}
      listening={false}
    />
  );
};

const PreviewImage = ({
  item,
  opacity,
  x,
  y,
  height,
}: {
  item: ITimelineItem;
  opacity?: number;
  x: number;
  y: number;
  height: number;
}) => {
  const cache = item.cloudAssetId ? (cloudAssetCache[item.cloudAssetId!] as ICloudAssetImageCache) : null;

  if (!cache) {
    return null;
  }

  const width = calculateWidthAspectRatioFit(cache.image.width, cache.image.height, height);

  return (
    <Image
      opacity={opacity}
      image={cache.image}
      x={x}
      y={y}
      width={width}
      height={height}
      cornerRadius={4}
      listening={false}
    />
  );
};

const PreviewGif = ({
  item,
  opacity,
  x,
  y,
  height,
}: {
  item: ITimelineItem;
  opacity?: number;
  x: number;
  y: number;
  height: number;
}) => {
  const cache = item.cloudAssetId ? (cloudAssetCache[item.cloudAssetId] as ICloudAssetGifCache) : null;
  const gifFrames = cache?.gifFrames;

  const gifCanvasRef = useRef<HTMLCanvasElement>(document.createElement('canvas'));
  const gifCtxRef = useRef<CanvasRenderingContext2D>(gifCanvasRef.current.getContext('2d'));

  const tmpCanvasRef = useRef<HTMLCanvasElement>(document.createElement('canvas'));
  const tmpCtxRef = useRef<CanvasRenderingContext2D>(tmpCanvasRef.current.getContext('2d'));

  const imageDataRef = useRef<ImageData>(null);

  const [width, setWidth] = useState(0);

  const renderFrame = useCallback(
    async (gifFrame) => {
      try {
        const gifCanvas = gifCanvasRef.current;
        const gifCtx = gifCtxRef.current;

        const tmpCanvas = tmpCanvasRef.current;
        const tmpCtx = tmpCtxRef.current;

        let imageData = imageDataRef.current;

        const dims = gifFrame.dims;

        if (gifFrame.disposalType === 2) {
          gifCtx.clearRect(0, 0, gifCanvas.width, gifCanvas.height);
        }

        if (!imageData || dims.width !== imageData.width || dims.height !== imageData.height) {
          tmpCanvas.width = dims.width;
          tmpCanvas.height = dims.height;

          imageDataRef.current = tmpCtx.createImageData(dims.width, dims.height);
          imageData = imageDataRef.current;
        }

        // set the patch data as an override
        imageData.data.set(gifFrame.patch);

        // draw the patch back over the tmp canvas
        tmpCtx.putImageData(imageData, 0, 0);

        // draw the tmp canvas to the gif canvas at the correct position
        gifCtx.drawImage(tmpCanvas, dims.left, dims.top);

        const newWidth = calculateWidthAspectRatioFit(dims.width, dims.height, height);
        setWidth(newWidth);
      } catch (error) {
        console.error('Error rendering gif frame:', error);
      }
    },
    [height],
  );

  useEffect(() => {
    if (!gifFrames) {
      return;
    }
    renderFrame(gifFrames[0]);
  }, [gifFrames, renderFrame]);

  if (!gifFrames) {
    return null;
  }

  return (
    <Image
      opacity={opacity}
      image={gifCanvasRef.current}
      x={x}
      y={y}
      width={width}
      height={height}
      cornerRadius={4}
      listening={false}
    />
  );
};

const PreviewVideo = ({
  item,
  seconds,
  opacity,
  x,
  y,
  width: maxWidth,
  height,
}: {
  item: ITimelineItem;
  seconds: number;
  opacity?: number;
  x: number;
  y: number;
  width: number;
  height: number;
}) => {
  const cloudAsset = useSelector(cloudAssetSelector(item.cloudAssetId));

  const cache = item.cloudAssetId ? (cloudAssetCache[item.cloudAssetId] as ICloudAssetVideoCache) : null;
  const blobUrl = cache?.blobUrl;

  const [canvas, setCanvas] = useState(null);
  const [videoWidth, setVideoWidth] = useState(0);
  const [videoHeight, setVideoHeight] = useState(0);

  const width = calculateWidthAspectRatioFit(videoWidth, videoHeight, height);

  const videoRef = useRef<HTMLVideoElement>(document.createElement('video'));

  const loadAndSeekVideo = useCallback(() => {
    const video = videoRef.current;

    video.src = blobUrl;
    video.crossOrigin = 'anonymous'; // Not necessary if the blob is from the same origin

    video.addEventListener('loadeddata', () => {
      setVideoWidth(video.videoWidth);
      setVideoHeight(video.videoHeight);

      video.currentTime = seconds;
    });

    video.addEventListener('error', (err) => {
      console.error('Error loading video:', err);
    });

    video.load(); // Start loading the video
  }, [blobUrl, seconds]);

  useEffect(() => {
    videoRef.current.addEventListener('seeked', () => {
      const video = videoRef.current;

      const newCanvas = document.createElement('canvas');
      newCanvas.width = video.videoWidth;
      newCanvas.height = video.videoHeight;

      const context = newCanvas.getContext('2d');
      context.drawImage(video, 0, 0, newCanvas.width, newCanvas.height);

      setCanvas(newCanvas);
    });
  }, []);

  const blobUrlRef = useRef<string>(null);

  useEffect(() => {
    if (!blobUrl) {
      return;
    }

    if (blobUrlRef.current !== blobUrl) {
      blobUrlRef.current = blobUrl;
      loadAndSeekVideo();
      return;
    }

    videoRef.current.currentTime = seconds;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blobUrl, seconds]);

  if (!blobUrl || width <= 0) {
    return null;
  }

  const GAP = 4;

  const TEXT_SIZE = 12;
  const TEXT_GAP = (height - TEXT_SIZE * 2) / 3;

  return (
    <>
      <Image
        opacity={opacity}
        image={canvas}
        x={x}
        y={y}
        width={width}
        height={height}
        cornerRadius={4}
        listening={false}
      />
      <Text
        opacity={opacity}
        x={x + width + GAP}
        y={y}
        width={maxWidth - width - GAP}
        height={height}
        text={getTranscriptionCombinedText(cloudAsset)}
        fontSize={TEXT_SIZE}
        fill={defaultTheme.colors.black}
        verticalAlign='middle'
        padding={TEXT_GAP}
        listening={false}
      />
    </>
  );
};

const PreviewAudio = ({
  item,
  opacity,
  x,
  y,
  width,
  height,
}: {
  item: ITimelineItem;
  opacity?: number;
  x: number;
  y: number;
  width: number;
  height: number;
}) => {
  const cloudAsset = useSelector(cloudAssetSelector(item.cloudAssetId));

  // const [patternImage, setPatternImage] = useState(audioIconImage);

  // useEffect(() => {
  //   if (patternImage) {
  //     return;
  //   }

  //   const loadImage = async () => {
  //     const svgString = encodeURIComponent(renderToStaticMarkup(<AudioWavesIcon />));
  //     const dataUrl = `data:image/svg+xml,${svgString}`;

  //     const img = new window.Image();
  //     img.src = dataUrl;
  //     img.onload = () => {
  //       setPatternImage(img);
  //       audioIconImage = img;
  //     };
  //   };

  //   loadImage();
  // }, [patternImage]);

  // if (!patternImage) {
  //   return null;
  // }

  const isTextToSpeech = cloudAsset?.originalSrc === 'elevenlabs';

  const ICON_CONTAINER_WIDTH = 28;
  const GAP = 4;
  const icon = isTextToSpeech ? TextToSpeechIcon : AudioRecordingIcon;
  const iconWidth = isTextToSpeech ? 16 : 21;
  const iconHeight = isTextToSpeech ? 22 : 21;
  const iconPaddingX = (ICON_CONTAINER_WIDTH - iconWidth) / 2;
  const iconPaddingY = (height - iconHeight) / 2;

  const TEXT_SIZE = 12;
  const TEXT_GAP = (height - TEXT_SIZE * 2) / 3;

  return (
    <>
      <SVGIcon
        icon={icon}
        width={iconWidth}
        height={iconHeight}
        x={x + iconPaddingX}
        y={y + iconPaddingY}
        listening={false}
      />
      <Text
        opacity={opacity}
        x={x + ICON_CONTAINER_WIDTH + GAP}
        y={y}
        width={width - ICON_CONTAINER_WIDTH - GAP}
        height={height}
        text={isTextToSpeech ? cloudAsset.sourceMeta?.text || '' : getTranscriptionCombinedText(cloudAsset)}
        fontSize={TEXT_SIZE}
        fill={defaultTheme.colors.black}
        verticalAlign='middle'
        padding={TEXT_GAP}
        listening={false}
      />
    </>
  );
};

const LoadingIndicator = (props) => {
  const arcRef = useRef(null);
  const animRef = useRef(null);

  useEffect(() => {
    const arc = arcRef.current;

    animRef.current = new Konva.Animation((frame) => {
      if (frame && arc) {
        const degPerSecond = 360;
        const angleDiff = (frame.timeDiff * degPerSecond) / 1000;
        arc.rotation(arc.rotation() + angleDiff);
      }
    }, arc.getLayer());

    animRef.current.start();

    return () => {
      if (animRef.current) {
        animRef.current.stop();
      }
    };
  }, []);

  return <Arc ref={arcRef} {...props} />;
};

export const getTimelineItemDuration = (timelineItem: ITimelineItem) => {
  return timelineItem.end - timelineItem.start;
};

// detect snap for a specific point on the timeline
const getSnapForSecondTime = ({
  timelineItemId,
  currentSeconds,
  seconds,
  secondDisplayWidth,
}: {
  timelineItemId: ITimelineItem['id']; // current timeline item
  currentSeconds: number; // position indicator
  seconds: number; // where we are moving
  secondDisplayWidth: number; // width in px of one second
}) => {
  if (!timelineItemId) {
    return null;
  }

  const SNAP_WIDTH_DISTANCE = 30;
  const secondsDistance = SNAP_WIDTH_DISTANCE / secondDisplayWidth;

  const start = Math.floor(getMathMax(seconds, 0)) - POSITIONS_MAP_STEP;

  const keys = [
    `${start}-${start + POSITIONS_MAP_STEP}`,
    `${start + POSITIONS_MAP_STEP}-${start + POSITIONS_MAP_STEP * 2}`,
    `${start + POSITIONS_MAP_STEP * 2}-${start + POSITIONS_MAP_STEP * 3}`,
  ];

  const itemsMap = store.getState().videoEditor.video.data.config.itemsMap;

  // check if testSecond is within the range of the current second at all
  const getIsClose = (testSecond: number) => {
    return Math.abs(testSecond - seconds) < secondsDistance;
  };
  const getTestSecondDistance = (testSecond: number) => {
    return Math.abs(testSecond - seconds);
  };
  // check if testSecond is closer to the current second than the previous closest
  const getIsCloser = (testSecond: number) => {
    if (testSecondsDistance === null) {
      return true;
    }
    return getTestSecondDistance(testSecond) < testSecondsDistance;
  };
  const runForTimelineItemMetaList = (
    timelineItemMetaList: ITimelineItemMeta[],
    getTestSecond: (timelineItem: ITimelineItem) => number,
  ) => {
    for (let a = 0; a < timelineItemMetaList.length; a++) {
      const itemMeta = timelineItemMetaList[a];
      const testTimelineItem = itemsMap[itemMeta.timelineItemId];

      const testSecond = getTestSecond(testTimelineItem);
      const isClose = getIsClose(testSecond);
      const isCloser = getIsCloser(testSecond);

      if (testTimelineItem.id !== timelineItemId && isClose && isCloser) {
        testSeconds = testSecond;
        testSecondsDistance = getTestSecondDistance(testSecond);
      }
    }
  };

  const currentIsClose = getIsClose(currentSeconds);
  let testSeconds = currentIsClose ? currentSeconds : null;
  let testSecondsDistance = currentIsClose ? getTestSecondDistance(currentSeconds) : null;

  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];

    const rootStartTimelineItemsPositions = videoEditorData.rootStartTimelineItemsPositionsMap[key] || [];
    runForTimelineItemMetaList(rootStartTimelineItemsPositions, (_timelineItem) => _timelineItem.start);

    const rootEndTimelineItemsPositions = videoEditorData.rootEndTimelineItemsPositionsMap[key] || [];
    runForTimelineItemMetaList(rootEndTimelineItemsPositions, (_timelineItem) => _timelineItem.end);
  }

  return testSeconds;
};

// when dragging a timeline item
const getTimelineSnap = ({
  timelineItem,
  startSeconds,
  secondDisplayWidth,
  currentSeconds,
}: {
  timelineItem: ITimelineItem;
  startSeconds: number;
  secondDisplayWidth: number;
  currentSeconds: number; // position indicator
}) => {
  const getSnapX = (start: number, end: number) => {
    const snapStartSeconds = getSnapForSecondTime({
      timelineItemId: timelineItem.id,
      currentSeconds,
      seconds: start,
      secondDisplayWidth,
    });

    const snapEndSeconds = getSnapForSecondTime({
      timelineItemId: timelineItem.id,
      currentSeconds,
      seconds: end,
      secondDisplayWidth,
    });

    // find the closest snap
    const isStart = !!snapStartSeconds && Math.abs(snapStartSeconds - start) < Math.abs(snapEndSeconds - end);

    return {
      start: snapStartSeconds ? (isStart ? snapStartSeconds : null) : null,
      end: snapEndSeconds ? (!isStart ? snapEndSeconds : null) : null,
    };
  };

  const duration = getTimelineItemDuration(timelineItem);
  const endSeconds = startSeconds + duration;
  const snapX = getSnapX(startSeconds, endSeconds);

  let seconds = startSeconds;
  let snapSeconds = null;

  if (snapX.start) {
    seconds = snapX.start;
    snapSeconds = snapX.start;
  }

  if (snapX.end) {
    seconds = snapX.end - duration;
    snapSeconds = snapX.end;
  }

  return {
    seconds, // x position of the timeline item
    snapSeconds,
  };
};

const getTimelineXFromSeconds = ({ seconds, secondDisplayWidth }: { seconds: number; secondDisplayWidth: number }) => {
  return seconds * secondDisplayWidth + LAYER_PADDING_LEFT + INTER_TIMELINE_ITEM_SPACING / 2;
};

const SVGIcon = ({ icon: Icon, ...props }: Partial<ComponentProps<typeof Image>> & { icon: FC<SvgProps> }) => {
  const [image, setImage] = useState(null);

  useEffect(() => {
    const loadImage = () => {
      const svgString = encodeURIComponent(renderToStaticMarkup(<Icon />));
      const dataUrl = `data:image/svg+xml,${svgString}`;

      const img = new window.Image();
      img.src = dataUrl;
      img.onload = () => {
        setImage(img);
      };
    };
    loadImage();
  }, [Icon]);

  if (!image) {
    return null;
  }

  return <Image image={image} {...props} />;
};

const S = {
  Container: styled.div<{
    maxHeight: number;
  }>`
    position: relative;
    z-index: 3;
    width: 100%;
    height: ${({ maxHeight }) => maxHeight}px;
    border-top-width: 1px;
    border-top-color: #eaeaea;
    background-color: ${defaultTheme.colors.lightGray47};
    overflow: hidden;
    border: 1px solid #red;
  `,
  VerticalScrollbarContainer: styled.div<{ height: number }>`
    position: absolute;
    top: ${TIME_SCALE_HEIGHT}px;
    right: 0;
    width: ${SCROLL_BAR_OFFSET + SCROLL_BAR_WIDTH};
    height: ${({ height }) => height}px;
  `,
  HorizontalScrollbarContainer: styled.div<{ width: number }>`
    position: absolute;
    bottom: 0;
    left: 0;
    width: ${({ width }) => width}px;
    height: ${SCROLL_BAR_OFFSET + SCROLL_BAR_WIDTH}px;
  `,
};
