import { useSelector } from 'react-redux';
import { contentIdSelector } from '../../../../store/analytics/analytics.selector';
import { playlistItemSelector } from '../../../../store/playlist/playlist.selectors';
import { safeDivide } from '../../../../utils/math';
import { PlaylistOverviewStats } from '../../../../store/attributes/playlistStats/playlistStats.types';
import { useMemo } from 'react';
import { ESharedWithFilter } from '../../../../types/anayltics';
import { playlistSharedWithFilterSelector } from '../../../../store/attributes/playlistAttributes/playlistAttributes.selector';

export type TPlaylistStats = {
  started: number;
  startedRate?: number;
  progress: number;
  progressRate: number;
  completed: number;
  completedRate: number;
  success: number;
  sharedWithCommunicationHub: number;
  viewedOrganically: number;
  answeredCorrectlyNum: number;
  totalAnswersNum: number;
  totalUsersCount: number;
};

const useCalculatePlaylistStats = (stats: PlaylistOverviewStats): TPlaylistStats => {
  const playlistId = useSelector(contentIdSelector);
  const playlist = useSelector(playlistItemSelector(playlistId));
  const sharedWith = useSelector(playlistSharedWithFilterSelector);

  const totalUsersCount = useMemo(() => {
    const hasOrganically = sharedWith.includes(ESharedWithFilter.ORGANICALLY);
    const hasCommunicationHub = sharedWith.includes(ESharedWithFilter.COMMUNICATION_HUB);

    if (!stats) {
      return 0;
    }

    if (hasOrganically && !hasCommunicationHub) {
      return stats.viewed_organically;
    }

    if (hasCommunicationHub && !hasOrganically) {
      return stats.shared_with_communication_hub;
    }

    if (hasCommunicationHub && hasOrganically) {
      return stats.viewed_organically + stats.shared_with_communication_hub;
    }

    return stats.total_users_count;
  }, [sharedWith, stats]);

  if (!stats || !playlist) {
    return {
      started: 0,
      startedRate: 0,
      progress: 0,
      progressRate: 0,
      completed: 0,
      completedRate: 0,
      success: 0,
      sharedWithCommunicationHub: 0,
      viewedOrganically: 0,
      answeredCorrectlyNum: 0,
      totalAnswersNum: 0,
      totalUsersCount: 0,
    };
  }

  const success = playlist?.is_quiz
    ? safeDivide(
        stats.answered_correctly_num,
        playlist.bite_shares.filter((biteShare) => biteShare.playlist_section !== 'intro').length *
          stats.started_users_num,
      )
    : safeDivide(stats.answered_correctly_num, stats.total_answers_num);

  return {
    started: stats.started_users_num,
    startedRate: safeDivide(stats.started_users_num, totalUsersCount),
    progress: stats.viewed_bite_shares_num,
    progressRate: safeDivide(stats.viewed_bite_shares_num, stats.started_users_num * playlist?.bite_shares.length),
    completed: stats.completed_users_num,
    completedRate: safeDivide(stats.completed_users_num, stats.started_users_num),
    success,
    sharedWithCommunicationHub: stats.shared_with_communication_hub,
    viewedOrganically: stats.viewed_organically,
    totalUsersCount,
    answeredCorrectlyNum: stats.answered_correctly_num,
    totalAnswersNum: stats.total_answers_num,
  };
};

export default useCalculatePlaylistStats;
