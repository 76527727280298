import { all, call, delay, put, select, spawn, takeEvery, takeLatest } from 'redux-saga/effects';
import withRetry from '../../utils/withRetry';
import {
  cloneCloudAssetCacheForTimelineItem,
  createCloudAsset,
  createCloudAssetCache,
  loadCloudAssets,
  loadVideoAssets,
  processCloudAsset,
  processCloudAssetMeta,
  resizeCloudAssetForResolution,
  setCloudAsset,
  setCloudAssetCacheReady,
  setTemporaryCloudAsset,
  toggleIsBrandCloudAsset,
  toggleIsBrandCloudAssetDone,
  toggleIsBrandCloudAssetError,
  toggleIsFavoriteCloudAsset,
  toggleIsFavoriteCloudAssetDone,
  toggleIsFavoriteCloudAssetError,
} from './cloudAssets.slice';
import { activeOrganizationSelector, authSelector, userSelector } from '../auth/auth.selectors';
import {
  addCloudAssetToBrand,
  addCloudAssetToFavorites,
  createCloudAsset as createCloudAssetService,
  downloadFileService,
  getCloudAsset,
  getCloudAssetProcessingTask,
  processCloudAssetMeta as processCloudAssetMetaService,
  removeCloudAssetFromBrand,
  removeCloudAssetFromFavorites,
  resizeCloudAssetForResolution as resizeCloudAssetForResolutionService,
} from '../api/bites-api/calls/cloudAssets.calls';
import { log, logError } from '../appActivity/appActivity.slice';
import { AxiosResponse } from 'axios';
import {
  ICloudAsset,
  ICreateCloudAssetPayload,
  ICreateCloudAssetCache,
  IGetCloudAssets,
  ICloneCloudAssetCacheForTimelineItem,
  IResizeCloudAssetForResolutionPayload,
  IResizeCloudAssetForResolutionTask,
  IProcessCloudAssetMetaPayload,
  UploadCloudAssetToS3Payload,
  ToggleIsFavoriteCloudAsset,
  ToggleIsBrandCloudAsset,
  LoadCloudAssets,
} from './cloudAssets.types';
import { PayloadAction } from '@reduxjs/toolkit';
import uploadToS3 from '../../utils/uploadToS3';
import { IS_PROD } from '../../utils/constants/env';
import {
  timelineItemSeletor,
  videoResolutionSeletor,
  videoSelector,
  videoTimelineLayersSeletor,
} from '../videoEditor/videoEditor.selectors';
import { v4 as uuid } from 'uuid';
import {
  ICloudAssetAudioCache,
  ICloudAssetVideoCache,
  TCloudAssetCache,
  cloneCloudAssetCacheForAudioTimelineItem,
  cloneCloudAssetCacheForVideoTimelineItem,
  cloudAssetCache,
} from './cloudAssets.cache';
import store from '..';
import { ITimelineItem, ITimelineLayer, IVideo, IVideoConfig } from '../videoEditor/videoEditor.types';
import { parseGIF, decompressFrames } from 'gifuct-js';
import {
  cloudAssetSelector,
  temporaryCloudAssetIdsSelector,
  toggleIsBrandByIdSelector,
  toggleIsFavoriteByIdSelector,
} from './cloudAssets.selector';
import { getErrorLogData } from '../../utils/getErrorLogData';
import { getTimelineItemsByCloudAssetIdSaga } from '../videoEditor/videoEditor.saga';
import { getResizeTarget } from '../../screens/videoEditor/utils/getResizeTarget';
import { BASE_BACKEND_URL } from '../api/bites-api';

function* createCloudAssetSaga({ payload }: Pick<PayloadAction<ICreateCloudAssetPayload>, 'payload'>) {
  const {
    fileType,
    fileMeta,
    file,
    previewImageFile,
    dataUrl,
    blob,
    sourceMeta,
    originalSrc,
    originalData,
    isBrandAsset,
    onCreate,
    onCacheReady,
    onFail,
  } = payload;
  const processId = payload.processId || uuid();

  try {
    const org = yield select(activeOrganizationSelector);
    const user = yield select(authSelector);

    yield put(
      log({
        event: 'createCloudAssetSaga: start',
        processId,
        data: {
          fileType,
        },
      }),
    );

    const tmpCloudAsset: ICloudAsset = {
      id: uuid(),
      fileType,
      fileMeta,
      // dummy storage
      storage: {
        type: 's3',
        bucket: '',
        key: '',
        url: '',
        taskId: '',
      },
      previewImage: {
        type: 's3',
        bucket: '',
        key: '',
        url: '',
        taskId: '',
      },
      orgId: org.id,
      creatorId: user.id,
      sourceMeta,
      originalSrc,
      originalData,
      isBrandAsset,
    };

    yield put(setTemporaryCloudAsset({ temporaryCloudAssetId: tmpCloudAsset.id, cloudAsset: tmpCloudAsset }));

    if (typeof onCacheReady === 'function') {
      yield spawn(createCloudAssetCacheSaga, {
        payload: {
          processId,
          cloudAsset: tmpCloudAsset,
          file,
          dataUrl,
          blob,
          onCacheReady,
          onFail,
        },
      });
    }

    const storage = yield call(uploadCloudAssetToS3Saga, {
      payload: {
        processId,
        file,
      },
    });

    yield put(
      log({
        event: 'createCloudAssetSaga: uploaded to s3',
        processId,
        data: {
          storage,
        },
      }),
    );

    const keyParts = storage.key.split('/');
    keyParts.shift(); // remove the orgId
    keyParts.pop(); // remove the file name
    const previewImageKey = keyParts.join('/') + '/thumbnail.png';
    const previewImage = previewImageFile
      ? yield call(uploadCloudAssetToS3Saga, {
          payload: {
            processId,
            file: previewImageFile,
            key: previewImageKey,
            contentType: 'image/png',
          },
        })
      : undefined;

    const cloudAsset: ICloudAsset = {
      ...tmpCloudAsset,
      storage,
      previewImage,
    };

    yield put(
      log({
        event: 'createCloudAssetSaga: creating cloud asset',
        processId,
        data: {
          cloudAsset,
        },
      }),
    );

    const {
      data: { cloudAsset: newCloudAsset },
    }: AxiosResponse<{ cloudAsset: ICloudAsset }> = yield withRetry(() => createCloudAssetService(cloudAsset), {
      errorContext: {
        data: {
          action: 'createCloudAssetSaga',
        },
      },
    });

    yield put(
      setCloudAsset({
        cloudAsset: newCloudAsset,
      }),
    );

    yield put(
      log({
        event: 'createCloudAssetSaga: created cloud asset',
        processId,
        data: {
          newCloudAsset,
        },
      }),
    );

    yield put(setTemporaryCloudAsset({ temporaryCloudAssetId: tmpCloudAsset.id, cloudAsset: newCloudAsset }));
    yield put(processCloudAsset({ cloudAsset: newCloudAsset }));

    yield put(
      log({
        event: 'createCloudAssetSaga: done',
        processId,
      }),
    );

    if (onCreate) {
      onCreate({
        cloudAsset: newCloudAsset,
      });
    }

    return [newCloudAsset];
  } catch (error) {
    yield put(
      logError({
        event: 'createCloudAssetSaga: error',
        processId,
        data: {
          error,
        },
      }),
    );

    if (onFail) {
      onFail({
        error,
      });
    }
  }
}

function* uploadCloudAssetToS3Saga({ payload }: Pick<PayloadAction<UploadCloudAssetToS3Payload>, 'payload'>) {
  const { file, key, contentType } = payload;
  const processId = payload.processId || uuid();

  const org = yield select(activeOrganizationSelector);
  // const user = yield select(authSelector);

  try {
    const { taskId } = yield uploadToS3({
      file,
      processId,
      orgId: org.id,
      endpoint: `${BASE_BACKEND_URL}/api/common_services/cloud_asset/s3/upload/create?${key ? `key=${key}` : ''}${
        contentType ? `&contentType=${contentType}` : ''
      }`,
      multiPartEndpoints: {
        preSignEndpoint: `${BASE_BACKEND_URL}/api/common_services/cloud_asset/s3/upload/multipart/create?orgId=${
          org.id
        }${key ? `&key=${key}` : ''}${contentType ? `&contentType=${contentType}` : ''}`,
        completeEndpoint: `${BASE_BACKEND_URL}/api/common_services/cloud_asset/s3/upload/multipart/complete`,
      },
      mediaType: file.type?.split('/')[0] as 'image' | 'video' | 'audio',
    });

    const s3Bucket = IS_PROD ? 'cloud-assets-production' : 'cloud-assets-staging';
    const s3Key = key ? `${org.id}/${key}` : taskId;
    const s3Url = `https://${s3Bucket}.s3.amazonaws.com/${s3Key}`;

    yield put(
      log({
        event: 'uploadCloudAssetToS3Saga: uploaded to s3',
        processId,
        data: {
          taskId,
          s3Bucket,
          s3Url,
        },
      }),
    );

    return {
      type: 's3',
      url: s3Url,
      bucket: s3Bucket,
      key: s3Key,
      // taskId,
    };
  } catch (error) {
    yield put(
      logError({
        event: 'uploadCloudAssetToS3Saga: error',
        data: {
          error,
        },
      }),
    );

    throw error;
  }
}

const getStorageForResolution = (cloudAsset: ICloudAsset, resolution: Partial<IVideoConfig['resolution']>) => {
  const resized = cloudAsset.storageByResolution?.find((storageByResolution) =>
    resolution.height
      ? storageByResolution.resolution.height === resolution.height
      : storageByResolution.resolution.width === resolution.width,
  );

  if (resized) {
    return resized.storage;
  }

  if (
    (resolution.height && cloudAsset.fileMeta.height <= resolution.height) ||
    (resolution.width && cloudAsset.fileMeta.width <= resolution.width)
  ) {
    return cloudAsset.storage;
  }

  return null;
};

function* resizeCloudAssetForResolutionSaga({
  payload,
}: Pick<PayloadAction<IResizeCloudAssetForResolutionPayload>, 'payload'>) {
  const processId = payload.processId || uuid();
  const { cloudAssetId } = payload;

  const resolution = yield select(videoResolutionSeletor);
  const cloudAsset: ICloudAsset = yield select(cloudAssetSelector(cloudAssetId));

  if (!cloudAsset.fileMeta?.width || !cloudAsset.fileMeta?.height) {
    yield put(
      logError({
        event: 'resizeCloudAssetForResolutionSaga: error - no width or height',
        processId,
        data: {
          cloudAssetId,
        },
      }),
    );
    return;
  }

  const resizeTarget = getResizeTarget(resolution);

  const storage = getStorageForResolution(cloudAsset, resizeTarget);

  // for video we need to have the storage from storageByResolution not the original one,
  // since storageByResolution references files processed also for HDR
  if (storage && (storage !== cloudAsset.storage || cloudAsset.fileType !== 'video')) {
    yield put(
      log({
        event: 'resizeCloudAssetForResolutionSaga: no need to resize',
        processId,
        data: {
          resolution,
          cloudAsset,
        },
      }),
    );
    return;
  }

  try {
    const {
      data: { taskId },
    }: AxiosResponse<{
      taskId: string;
    }> = yield withRetry(
      () =>
        resizeCloudAssetForResolutionService({
          cloudAssetId,
          resolution: resizeTarget,
        }),
      {
        errorContext: {
          processId,
          data: {
            action: 'resizeCloudAssetForResolutionSaga',
            cloudAssetId,
            resolution: resizeTarget,
          },
        },
      },
    );

    yield cloudAssetProcessingTaskPollingSaga({
      taskId,
      cloudAssetId,
      processId,
    });
  } catch (error) {
    yield put(
      logError({
        event: 'resizeCloudAssetForResolutionSaga: error',
        processId,
        data: {
          ...getErrorLogData(error),
          resolution,
          cloudAssetId,
        },
      }),
    );
  }
}

function* processCloudAssetMetaSaga({ payload }: Pick<PayloadAction<IProcessCloudAssetMetaPayload>, 'payload'>) {
  const processId = payload.processId || uuid();
  const { cloudAssetId } = payload;

  yield put(
    log({
      event: 'processCloudAssetMetaSaga: start',
      processId,
      data: {
        cloudAssetId,
      },
    }),
  );

  const cloudAsset: ICloudAsset = yield select(cloudAssetSelector(cloudAssetId));

  if (
    ((cloudAsset.fileType === 'image' || cloudAsset.fileType === 'gif') &&
      cloudAsset.fileMeta?.vectorData !== undefined) ||
    ((cloudAsset.fileType === 'audio' || cloudAsset.fileType === 'video') &&
      cloudAsset.fileMeta?.transcription !== undefined &&
      cloudAsset.fileMeta?.vectorData !== undefined)
  ) {
    yield put(
      log({
        event: 'processCloudAssetMetaSaga: no need to process',
        processId,
        data: {
          cloudAssetId,
        },
      }),
    );
    return;
  }

  try {
    const {
      data: { taskId },
    }: AxiosResponse<{
      taskId: string;
    }> = yield withRetry(
      () =>
        processCloudAssetMetaService({
          cloudAssetId,
        }),
      {
        errorContext: {
          processId,
          data: {
            action: 'processCloudAssetMetaSaga',
            cloudAssetId,
          },
        },
      },
    );

    yield cloudAssetProcessingTaskPollingSaga({
      taskId,
      cloudAssetId,
      processId,
    });
  } catch (error) {
    yield put(
      logError({
        event: 'processCloudAssetMetaSaga: error',
        processId,
        data: {
          ...getErrorLogData(error),
          cloudAssetId,
        },
      }),
    );
  }
}

function* cloudAssetProcessingTaskPollingSaga({
  taskId,
  cloudAssetId,
  processId,
}: {
  taskId: string;
  cloudAssetId: ICloudAsset['id'];
  processId: string;
}) {
  const video: IVideo = yield select(videoSelector);

  if (!video) {
    return;
  }

  yield put(
    log({
      event: 'cloudAssetProcessingTaskPollingSaga polling: start',
      processId,
      data: {
        taskId,
        cloudAssetId,
        videoId: video.id,
      },
    }),
  );

  const startTs = Date.now();
  while (true) {
    try {
      const {
        data: { cloudAssetProcessingTask },
      }: AxiosResponse<{ cloudAssetProcessingTask: IResizeCloudAssetForResolutionTask }> =
        yield getCloudAssetProcessingTask({
          taskId,
        });

      const currentVideo: IVideo = yield select(videoSelector);

      if (currentVideo?.id !== video.id) {
        yield put(
          log({
            event: 'cloudAssetProcessingTaskPollingSaga polling: video changed',
            processId,
            data: {
              taskId,
              cloudAssetId,
              videoId: video.id,
              currentVideoId: currentVideo?.id,
            },
          }),
        );
        return;
      }

      if (cloudAssetProcessingTask.status === 'FAILED') {
        yield put(
          logError({
            event: 'cloudAssetProcessingTaskPollingSaga polling: failed',
            processId,
            data: {
              taskId,
              cloudAssetId,
            },
          }),
        );
        break;
      }

      if (cloudAssetProcessingTask.status === 'DONE') {
        const {
          data: { cloudAsset: updatedCloudAsset },
        } = yield getCloudAsset({ id: cloudAssetId });

        yield put(
          setCloudAsset({
            cloudAsset: updatedCloudAsset,
          }),
        );

        const timelineItems = yield getTimelineItemsByCloudAssetIdSaga({ cloudAssetId });

        yield put(
          createCloudAssetCache({
            processId,
            cloudAsset: updatedCloudAsset,
            timelineItems,
          }),
        );

        yield put(
          log({
            event: 'cloudAssetProcessingTaskPollingSaga polling: done',
            processId,
            data: {
              taskId,
              cloudAssetId,
            },
          }),
        );
        break;
      }
    } catch (error) {
      yield put(
        logError({
          event: 'cloudAssetProcessingTaskPollingSaga: polling error',
          processId,
          data: {
            ...getErrorLogData(error),
            taskId,
            cloudAssetId,
          },
        }),
      );
    } finally {
      if (Date.now() - startTs > 1000 * 60 * 5) {
        throw new Error('Polling timeout');
      }
      yield delay(5000);
    }
  }
}

const getObjectUrlForCloudAsset = async ({
  cloudAsset,
  file,
  blob,
}: {
  cloudAsset: ICloudAsset;
  file?: File;
  blob?: Blob;
}) => {
  if (blob) {
    return URL.createObjectURL(blob);
  }

  if (file) {
    return URL.createObjectURL(file);
  }

  let url: string = cloudAsset.storage.url;
  if (cloudAsset.fileType === 'image' || cloudAsset.fileType === 'video') {
    url = getCloudAssetFileUrl(cloudAsset);
  }

  const { data } = await downloadFileService(url);
  return URL.createObjectURL(data);
};

const getArrayBufferFromBlob = (blob: Blob): Promise<ArrayBuffer> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.result instanceof ArrayBuffer) {
        resolve(reader.result);
      }
      reject(new Error('Unexpected result type'));
    };
    reader.onerror = reject;
    reader.readAsArrayBuffer(blob);
  });
};

export interface IOnCacheReadyProps {
  cloudAsset: ICloudAsset;
  cloudAssetCache: TCloudAssetCache;
}
export type TOnCacheReady = (props: IOnCacheReadyProps) => void;
interface ICreateCloudAssetImageByDataUrlCache {
  processId: number;
  cloudAsset: ICloudAsset;
  dataUrl: string;
}
function* createCloudAssetImageByDataUrlCacheSaga({
  payload,
}: Pick<PayloadAction<ICreateCloudAssetImageByDataUrlCache>, 'payload'>) {
  const { processId, cloudAsset, dataUrl } = payload;

  try {
    const decodedData = atob(dataUrl.split(',')[1]);

    // Create a Uint8Array for the binary data
    const arrayBuffer = new ArrayBuffer(decodedData.length);
    const uint8Array = new Uint8Array(arrayBuffer);

    for (let i = 0; i < decodedData.length; i++) {
      uint8Array[i] = decodedData.charCodeAt(i);
    }

    // Create a blob object
    const mimeString = dataUrl.split(',')[0].split(':')[1].split(';')[0]; // Extract mime type
    const blob = new Blob([uint8Array], { type: mimeString });

    let blobUrl;
    let image;

    yield new Promise((resolve, reject) => {
      // Create a blob URL
      blobUrl = URL.createObjectURL(blob);

      image = new Image();
      image.src = blobUrl;

      image.addEventListener(
        'load',
        () => {
          resolve(image);
        },
        { once: true },
      );

      // error
      image.addEventListener(
        'error',
        (error: any) => {
          store.dispatch(
            logError({
              event: 'createCloudAssetImageCacheSaga: error',
              processId,
              data: {
                errorMessage: error?.message,
                errorStack: error?.stack,
                cloudAsset,
              },
            }),
          );
          reject(error);
        },
        { once: true },
      );
      // yield new Promise((resolve) => (image.onload = resolve));
    });

    cloudAssetCache[cloudAsset.id!] = {
      blobUrl,
      image,
    };
  } catch (error) {
    yield put(
      logError({
        event: 'createCloudAssetImageByDataUrlCacheSaga: error',
        processId,
        data: {
          error: cloudAsset?.toString?.(),
          cloudAsset,
        },
      }),
    );

    throw error;
  }
}

interface ICreateCloudAssetImageCache {
  processId: number;
  cloudAsset: ICloudAsset;
  file?: File;
  blob?: Blob;
}
function* createCloudAssetImageCacheSaga({ payload }: Pick<PayloadAction<ICreateCloudAssetImageCache>, 'payload'>) {
  const { processId, cloudAsset, file, blob } = payload;

  try {
    const blobUrl: string = yield getObjectUrlForCloudAsset({
      cloudAsset,
      file,
      blob,
    });

    let image;

    yield new Promise((resolve, reject) => {
      image = new Image();
      image.src = blobUrl;

      image.addEventListener(
        'load',
        () => {
          resolve(image);
        },
        { once: true },
      );

      // error
      image.addEventListener(
        'error',
        (error: any) => {
          store.dispatch(
            logError({
              event: 'createCloudAssetImageCacheSaga: error',
              processId,
              data: {
                errorMessage: error?.message,
                errorStack: error?.stack,
                cloudAsset,
              },
            }),
          );
          reject(error);
        },
        { once: true },
      );

      // yield new Promise((resolve) => (image.onload = resolve));
    });

    cloudAssetCache[cloudAsset.id!] = {
      blobUrl,
      image,
    };
  } catch (error) {
    yield put(
      logError({
        event: 'createCloudAssetImageCacheSaga: error',
        processId,
        data: {
          error: cloudAsset?.toString?.(),
          cloudAsset,
        },
      }),
    );

    throw error;
  }
}

interface ICreateCloudAssetGifCache {
  processId: number;
  cloudAsset: ICloudAsset;
  file?: File;
  blob?: Blob;
}
function* createCloudAssetGifCacheSaga({ payload }: Pick<PayloadAction<ICreateCloudAssetGifCache>, 'payload'>) {
  const { processId, cloudAsset, blob, file } = payload;

  try {
    let arrayBufferData: ArrayBuffer | null = null;
    if (blob || file) {
      arrayBufferData = yield getArrayBufferFromBlob(blob || file);
    } else {
      ({ data: arrayBufferData } = yield downloadFileService(cloudAsset.storage.url, {
        responseType: 'arraybuffer',
      }));
    }

    const gif = parseGIF(arrayBufferData);
    const frames = decompressFrames(gif, true);

    cloudAssetCache[cloudAsset.id!] = {
      gifFrames: frames,
    };
  } catch (error) {
    console.error(error);

    yield put(
      logError({
        event: 'createGifAssetCacheSaga: error',
        processId,
        data: {
          error: cloudAsset?.toString?.(),
          cloudAsset,
        },
      }),
    );

    throw error;
  }
}

interface ICreateCloudAssetVideoCache {
  processId: number;
  cloudAsset: ICloudAsset;
  file?: File;
  blob?: Blob;
  onDone?: () => void;
}
function* createCloudAssetVideoCacheSaga({ payload }: Pick<PayloadAction<ICreateCloudAssetVideoCache>, 'payload'>) {
  const { processId, blob, file, cloudAsset, onDone } = payload;

  try {
    const blobUrl: string = yield getObjectUrlForCloudAsset({
      cloudAsset,
      file,
      blob,
    });

    const getVideo = async () => {
      return withRetry(
        () =>
          new Promise<HTMLVideoElement>((resolve, reject) => {
            const video = window.document.createElement('video');
            video.src = blobUrl;
            video.load();

            video.addEventListener(
              'loadedmetadata',
              () => {
                video.currentTime = 0.1;
                video.currentTime = 0;
                resolve(video);
              },
              { once: true },
            );

            video.addEventListener(
              'error',
              (error: any) => {
                store.dispatch(
                  logError({
                    event: 'createCloudAssetVideoCacheSaga.getVideo: error',
                    processId,
                    data: {
                      errorMessage: error?.message,
                      errorStack: error?.stack,
                      cloudAsset,
                    },
                  }),
                );
                reject(error);
              },
              { once: true },
            );

            video.load();

            setTimeout(() => {
              if (!video.readyState) {
                store.dispatch(
                  logError({
                    event: 'createCloudAssetVideoCacheSaga: video not ready, retrying...',
                    processId,
                    data: {
                      cloudAsset,
                    },
                  }),
                );
                // abort the video loading
                video.src = '';
                reject(new Error('Video not ready'));
              }
            }, 5000);
          }),
        {
          errorContext: {
            action: 'createCloudAssetVideoCacheSaga',
            processId,
            data: {
              cloudAsset,
            },
          },
        },
      );
    };

    const video = yield getVideo();

    cloudAssetCache[cloudAsset.id!] = {
      blobUrl,
      video,
      getVideo,
    };

    onDone?.();
  } catch (error: any) {
    console.error(error);

    yield put(
      logError({
        event: 'createCloudAssetVideoCacheSaga: error',
        processId,
        data: {
          errorMessage: error?.message,
          errorStack: error?.stack,
          cloudAsset,
        },
      }),
    );

    throw error;
  }
}

interface ICreateCloudAssetAudioCache {
  processId: number;
  cloudAsset: ICloudAsset;
  file?: File;
  blob?: Blob;
}
function* createCloudAssetAudioCacheSaga({ payload }: Pick<PayloadAction<ICreateCloudAssetAudioCache>, 'payload'>) {
  const { processId, cloudAsset, blob, file } = payload;

  try {
    const blobUrl: string = yield getObjectUrlForCloudAsset({
      cloudAsset,
      file,
      blob,
    });

    const getAudio = async () => {
      return withRetry(
        () =>
          new Promise<HTMLAudioElement>((resolve, reject) => {
            const audio = new Audio(blobUrl);

            audio.addEventListener(
              'canplaythrough',
              () => {
                audio.currentTime = 0.1;
                audio.currentTime = 0;
                resolve(audio);
              },
              { once: true },
            );

            // error
            audio.addEventListener(
              'error',
              (error: any) => {
                store.dispatch(
                  logError({
                    event: 'createCloudAssetAudioCacheSaga: error ',
                    processId,
                    data: {
                      errorMessage: error?.message,
                      errorStack: error?.stack,
                      cloudAsset,
                    },
                  }),
                );
                reject(error);
              },
              { once: true },
            );

            audio.load();

            setTimeout(() => {
              if (!audio.readyState) {
                store.dispatch(
                  logError({
                    event: 'createCloudAssetAudioCacheSaga: Audio not ready, retrying...',
                    processId,
                    data: {
                      cloudAsset,
                    },
                  }),
                );
                // abort the audio loading
                audio.src = '';
                reject(new Error('Audio not ready'));
              }
            }, 5000); // Adjust the timeout as needed
          }),
        {
          errorContext: {
            action: 'createCloudAssetAudioCacheSaga',
            processId,
            data: {
              cloudAsset,
            },
          },
        },
      );
    };

    const audio = yield getAudio();

    // temporary until we set up CF
    cloudAssetCache[cloudAsset.id!] = {
      blobUrl,
      audio,
      getAudio,
    };
  } catch (error) {
    console.error(error);

    yield put(
      logError({
        event: 'createCloudAssetAudioCacheSaga: error',
        processId,
        data: {
          error: cloudAsset?.toString?.(),
          cloudAsset,
        },
      }),
    );

    throw error;
  }
}

function* createCloudAssetCacheSaga({ payload }: Pick<PayloadAction<ICreateCloudAssetCache>, 'payload'>) {
  const { cloudAsset, timelineItems, file, blob, dataUrl, onCacheReady, onFail } = payload;
  const processId = payload.processId || uuid();

  let attempt = 1;
  while (true) {
    try {
      yield put(
        log({
          event: 'createCloudAssetCacheSaga',
          processId,
          data: {
            cloudAsset,
            file,
            withDataUrl: !!dataUrl,
          },
        }),
      );

      if (cloudAsset.fileType === 'image' && dataUrl) {
        yield createCloudAssetImageByDataUrlCacheSaga({
          payload: {
            processId,
            cloudAsset,
            dataUrl,
          },
        });
      }

      if (cloudAsset.fileType === 'image') {
        yield createCloudAssetImageCacheSaga({
          payload: {
            processId,
            cloudAsset,
            blob,
            file,
          },
        });
      }

      if (cloudAsset.fileType === 'gif') {
        yield createCloudAssetGifCacheSaga({
          payload: {
            processId,
            cloudAsset,
            blob,
            file,
          },
        });
      }

      if (cloudAsset.fileType === 'video') {
        yield createCloudAssetVideoCacheSaga({
          payload: {
            processId,
            cloudAsset,
            blob,
            file,
          },
        });
      }

      if (cloudAsset.fileType === 'audio') {
        yield createCloudAssetAudioCacheSaga({
          payload: {
            processId,
            cloudAsset,
            blob,
            file,
          },
        });
      }

      if (timelineItems) {
        yield all(
          timelineItems.map((timelineItem) =>
            call(cloneCloudAssetCacheForTimelineItemSaga, {
              payload: {
                timelineItem,
              },
            }),
          ),
        );
      }

      yield put(
        setCloudAssetCacheReady({
          cloudAssetId: cloudAsset.id!,
        }),
      );

      if (onCacheReady) {
        onCacheReady({
          cloudAsset,
          cloudAssetCache: cloudAssetCache[cloudAsset.id!],
        });
      }

      // break the cycle
      return;
    } catch (error: any) {
      yield put(
        logError({
          event: 'createCloudAssetCacheSaga: error',
          processId,
          data: {
            errorMessage: error?.message,
            errorStack: error?.stack,
          },
        }),
      );

      attempt++;

      if (attempt > 3) {
        if (onFail) {
          onFail({
            error,
          });
        }
        return;
      }
    }
  }
}
function* processCloudAssetSaga({
  payload: { cloudAsset, processId },
}: PayloadAction<{ cloudAsset: ICloudAsset; processId?: string }>) {
  processId = processId || uuid();

  if (cloudAsset.fileType === 'image') {
    yield put(
      resizeCloudAssetForResolution({
        cloudAssetId: cloudAsset.id,
        processId,
      }),
    );
  }

  if (cloudAsset.fileType === 'video') {
    yield put(
      resizeCloudAssetForResolution({
        cloudAssetId: cloudAsset.id,
        processId,
      }),
    );
  }

  yield put(
    processCloudAssetMeta({
      cloudAssetId: cloudAsset.id,
      processId,
    }),
  );
}

export function* getCloudAssetIdsFromTimelineLayers(payload: {
  cloudAssetIdsMap?: Record<string, ITimelineItem[]>;
  timelineLayers: ITimelineLayer[];
}) {
  const temporaryCloudAssetIds = yield select(temporaryCloudAssetIdsSelector);

  const { cloudAssetIdsMap = {}, timelineLayers } = payload;

  for (let layer of timelineLayers) {
    const { timeline } = layer;

    for (let timelineItemId of timeline) {
      const timelineItem = yield select(timelineItemSeletor(timelineItemId));

      if (timelineItem.type === 'group') {
        yield getCloudAssetIdsFromTimelineLayers({
          cloudAssetIdsMap,
          timelineLayers: timelineItem.timelineLayers,
        });

        continue;
      }

      const tmpId = temporaryCloudAssetIds[timelineItem.cloudAssetId];
      if (
        !timelineItem.cloudAssetId ||
        // if is temporary cloud asset id and is not replaced with the real one yet
        (tmpId && tmpId === timelineItem.cloudAssetId)
      ) {
        continue;
      }

      let { cloudAssetId } = timelineItem;

      // replace the temporary cloud asset id with the real one
      if (tmpId) {
        cloudAssetId = tmpId;
      }

      cloudAssetIdsMap[cloudAssetId] = cloudAssetIdsMap[cloudAssetId] || [];
      cloudAssetIdsMap[cloudAssetId].push(timelineItem);
    }
  }

  return cloudAssetIdsMap;
}

export function* loadCloudAssetsSaga({ payload }: Pick<PayloadAction<LoadCloudAssets>, 'payload'>) {
  const { cloudAssetIds } = payload;
  const processId = payload.processId || uuid();

  try {
    const promisesResults = yield Promise.allSettled(
      cloudAssetIds.map((cloudAssetId) => {
        return withRetry(() => getCloudAsset({ id: cloudAssetId }), {
          errorContext: {
            data: {
              processId,
              action: 'loadCloudAssetsSaga',
              data: {
                cloudAssetId,
              },
            },
          },
        });
      }),
    );

    const results = promisesResults
      .map((promiseResult) => {
        if (promiseResult.status === 'fulfilled') {
          return promiseResult.value;
        }

        return null;
      })
      .filter(Boolean);

    for (let result of results) {
      yield put(setCloudAsset({ cloudAsset: result.data.cloudAsset }));
    }

    if (results.length < cloudAssetIds.length) {
      throw new Error('Some assets failed to load');
    }
  } catch (error) {
    logError({
      event: 'loadCloudAssetsSaga: error',
      processId,
      data: {
        ...getErrorLogData(error),
        cloudAssetIds,
      },
    });
  }
}

export function* loadVideoAssetsSaga({ payload }: Pick<PayloadAction<IGetCloudAssets>, 'payload'>) {
  const { onDone, onFail } = payload;
  const processId = payload.processId || uuid();

  try {
    const timelineLayers: ITimelineLayer[] = yield select(videoTimelineLayersSeletor);

    const cloudAssetIdsMap: Record<string, ITimelineItem[]> = yield getCloudAssetIdsFromTimelineLayers({
      timelineLayers,
    });

    const cloudAssetIds = Object.keys(cloudAssetIdsMap);
    const results = yield all(
      cloudAssetIds.map((cloudAssetId) => {
        return withRetry(() => getCloudAsset({ id: cloudAssetId }), {
          errorContext: {
            data: {
              processId,
              action: 'loadVideoAssetsSaga: loadAssetsSaga',
              data: {
                cloudAssetId,
              },
            },
          },
        });
      }),
    );

    const promises: Promise<any>[] = [];

    for (let result of results) {
      const { cloudAsset } = result.data;

      yield put(setCloudAsset({ cloudAsset }));

      const timelineItems = cloudAssetIdsMap[cloudAsset.id];

      const promise = new Promise((resolve) => {
        store.dispatch(
          createCloudAssetCache({
            processId,
            cloudAsset,
            timelineItems,
            onCacheReady: async () => {
              resolve(true);
            },
          }),
        );
        store.dispatch(processCloudAsset({ cloudAsset }));
      });
      promises.push(promise);
    }

    yield all(promises);

    yield put(
      log({
        event: 'loadAssetsSaga: done',
        processId,
      }),
    );

    if (onDone) {
      onDone();
    }
  } catch (error: any) {
    yield put(
      logError({
        event: 'loadAssetsSaga: error',
        processId,
        data: {
          errorMessage: error?.message,
          errorStack: error?.stack,
        },
      }),
    );

    if (onFail) {
      onFail();
    }
  }
}

const getCloudAssetFileUrl = (cloudAsset: ICloudAsset) => {
  const resolution = store.getState().videoEditor.video.data.config.resolution;

  const resizeTarget: Partial<IVideoConfig['resolution']> = {};

  if (resolution.width > resolution.height) {
    resizeTarget.height = resolution.height;
  } else {
    resizeTarget.width = resolution.width;
  }

  const storage = getStorageForResolution(cloudAsset, resizeTarget);
  const url = storage?.url || cloudAsset.storage.url;

  return url;
};

export function* cloneCloudAssetCacheForTimelineItemSaga({
  payload: { timelineItem },
}: Pick<PayloadAction<ICloneCloudAssetCacheForTimelineItem>, 'payload'>) {
  if (!timelineItem.cloudAssetId) {
    return;
  }

  const originalCache = cloudAssetCache[timelineItem.cloudAssetId];
  const cloudAsset: ICloudAsset = yield select(cloudAssetSelector(timelineItem.cloudAssetId));

  if (cloudAsset.fileType === 'video') {
    yield cloneCloudAssetCacheForVideoTimelineItem(originalCache as ICloudAssetVideoCache, timelineItem.id);
  }
  if (cloudAsset.fileType === 'audio') {
    yield cloneCloudAssetCacheForAudioTimelineItem(originalCache as ICloudAssetAudioCache, timelineItem.id);
  }
}

function* toggleIsFavoriteCloudAssetSaga({ payload }: PayloadAction<ToggleIsFavoriteCloudAsset>) {
  const processId = payload.processId || uuid();
  const { cloudAssetId } = payload;
  try {
    const toggleIsFavoriteState = yield select(toggleIsFavoriteByIdSelector);
    if (toggleIsFavoriteState.isLoading) {
      return;
    }

    const cloudAsset = yield select(cloudAssetSelector(cloudAssetId));

    const user = yield select(userSelector);

    const isFavorite = !!cloudAsset.favoriteByUserIds?.includes(user.id);

    if (!isFavorite) {
      yield addCloudAssetToFavorites(cloudAsset.id);
    } else {
      yield removeCloudAssetFromFavorites(cloudAsset.id);
    }

    const cloudAsset2 = yield select(cloudAssetSelector(cloudAssetId));

    yield put(
      setCloudAsset({
        cloudAsset: {
          ...cloudAsset2,
          favoriteByUserIds: isFavorite
            ? cloudAsset2.favoriteByUserIds?.filter((id) => id !== user.id)
            : [...(cloudAsset2.favoriteByUserIds || []), user.id],
        },
      }),
    );

    yield put(
      toggleIsFavoriteCloudAssetDone({
        cloudAssetId,
      }),
    );
  } catch (error) {
    yield put(
      logError({
        event: 'toggleIsFavoriteCloudAssetSaga: error',
        processId,
        data: {
          ...getErrorLogData(error),
          cloudAssetId,
        },
      }),
    );

    yield put(
      toggleIsFavoriteCloudAssetError({
        cloudAssetId,
        error,
      }),
    );
  }
}

function* toggleIsBrandCloudAssetSaga({ payload }: PayloadAction<ToggleIsBrandCloudAsset>) {
  const processId = payload.processId || uuid();
  const { cloudAssetId } = payload;
  try {
    const toggleIsBrandState = yield select(toggleIsBrandByIdSelector);
    if (toggleIsBrandState.isLoading) {
      return;
    }

    const cloudAsset = yield select(cloudAssetSelector(cloudAssetId));

    if (!cloudAsset.isBrandAsset) {
      yield addCloudAssetToBrand(cloudAsset.id);
    } else {
      yield removeCloudAssetFromBrand(cloudAsset.id);
    }

    const cloudAsset2 = yield select(cloudAssetSelector(cloudAssetId));

    yield put(
      setCloudAsset({
        cloudAsset: {
          ...cloudAsset2,
          isBrandAsset: !cloudAsset2.isBrandAsset,
        },
      }),
    );

    yield put(
      toggleIsBrandCloudAssetDone({
        cloudAssetId,
      }),
    );
  } catch (error) {
    yield put(
      logError({
        event: 'toggleIsBrandCloudAssetSaga: error',
        processId,
        data: {
          ...getErrorLogData(error),
          cloudAssetId,
        },
      }),
    );

    yield put(
      toggleIsBrandCloudAssetError({
        cloudAssetId,
        error,
      }),
    );
  }
}

export default function* videoEditorSaga() {
  yield takeEvery(createCloudAsset, createCloudAssetSaga);
  yield takeEvery(createCloudAssetCache, createCloudAssetCacheSaga);
  yield takeEvery(cloneCloudAssetCacheForTimelineItem, cloneCloudAssetCacheForTimelineItemSaga);
  yield takeLatest(loadVideoAssets, loadVideoAssetsSaga);
  yield takeEvery(resizeCloudAssetForResolution, resizeCloudAssetForResolutionSaga);
  yield takeEvery(processCloudAssetMeta, processCloudAssetMetaSaga);
  yield takeEvery(toggleIsFavoriteCloudAsset, toggleIsFavoriteCloudAssetSaga);
  yield takeEvery(toggleIsBrandCloudAsset, toggleIsBrandCloudAssetSaga);
  yield takeEvery(loadCloudAssets, loadCloudAssetsSaga);
  yield takeEvery(processCloudAsset, processCloudAssetSaga);
}
