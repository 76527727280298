import React, { memo, useCallback, useEffect } from 'react';
import { IStackNavigation } from '../../../../navigation/types';
import { useDispatch, useSelector } from 'react-redux';
import { contentIdSelector } from '../../../../store/analytics/analytics.selector';
import { STATS_COMMENTS_SCREEN } from '../constants';
import { trackEvent } from '../../../../store/appActivity/appActivity.slice';
import { useIsFocused } from '@react-navigation/native';
import { SITE_URL } from '../../../../utils/constants/urls';
import WebView from '../../../../components/WebView';
import { biteItemSelector } from '../../../../store/bite/bite.selectors';
import { getBiteShareId } from '../../../../utils/formatDataFromServer';
import useToken from '../../../../hooks/useToken';
import styled from 'styled-components/native';
import AnalyticsHeader from '../../common/AnalyticsHeader';
import { useTranslation } from 'react-i18next';

interface IProps extends IStackNavigation {}
const Comments: React.FC<IProps> = ({ navigation }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isFocused = useIsFocused();

  const { token } = useToken();
  const biteId = useSelector(contentIdSelector);
  const bite = useSelector(biteItemSelector(biteId));
  const biteShareId = getBiteShareId(bite);

  const handleBackPress = useCallback(() => {
    navigation.pop();
  }, [navigation]);

  useEffect(() => {
    if (!isFocused) {
      return;
    }

    dispatch(
      trackEvent({
        event: 'pageview',
        props: { page_title: STATS_COMMENTS_SCREEN },
      }),
    );
  }, [dispatch, isFocused]);

  return (
    <S.Container>
      <AnalyticsHeader
        onBackButtonPress={handleBackPress}
        title={t('analytics.stats.title')}
        subtitle={bite.subject}
        withoutFilter
      />
      <WebView
        url={`${SITE_URL}/bites/${biteShareId}/discussion?token=${token}&rnd=${Date.now()}&isPreview=1&keepSection=true&hideHeader=true&hideTitle=true`}
      />
    </S.Container>
  );
};

const S = {
  Container: styled.View`
    flex: 1;
  `,
};

export default memo(Comments);
