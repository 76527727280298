import BitesApi from '../index';
import { ESharedWithFilter } from '../../../../types/anayltics';
import { ESortByType } from '../../../attributes/playlistAttributes/playlistAttributes.types';
import { ESortDirection } from '../../../../types/common';

interface IAnalyticsRequestData {
  orgId: number;
  userIds?: number[];
  answerIds?: number[];
  next?: string;
  filter?: string;
  attributeValueIds?: number[];
  pageSize?: number;
  sharedWith?: ESharedWithFilter[];
  allowedDataOnly: boolean;
}

interface IBiteAnalyticsRequestData extends IAnalyticsRequestData {
  biteIds: number[];
}

interface IPlaylistAnalyticsRequestData extends IAnalyticsRequestData {
  playlistIds?: number[];
  quizIds?: number[];
  sortBy: ESortByType.PROGRESS | ESortByType.SUCCESS;
  sortDirection: ESortDirection;
}

export const getBiteDistributions = async ({ orgId, biteId }) =>
  BitesApi.get(`distribution/${orgId}/bite/${biteId}/distributions`);

export const getPlaylistDistributions = async ({ orgId, playlistId }) =>
  BitesApi.get(`distribution/${orgId}/playlist/${playlistId}/distributions`);

export const getViewsNumber = async ({
  orgId,
  biteIds,
  allowedDataOnly,
  sharedWith,
  attributeValueIds,
}: {
  orgId: number;
  biteIds: string[];
  allowedDataOnly: boolean;
  sharedWith?: ESharedWithFilter[];
  attributeValueIds?: number[];
}) =>
  BitesApi.get(`/analytics/${orgId}/aggregated/views/`, {
    params: {
      bite_ids: JSON.stringify(biteIds),
      allowed_data_only: allowedDataOnly || undefined,
      attribute_value_ids: attributeValueIds?.length ? JSON.stringify(attributeValueIds) : undefined,
      shared_with: sharedWith?.length ? JSON.stringify(sharedWith) : undefined,
    },
  });

export const getCommentsNumber = async ({
  orgId,
  biteIds,
  allowedDataOnly,
  sharedWith,
  attributeValueIds,
}: {
  orgId: number;
  biteIds: string[];
  allowedDataOnly: boolean;
  sharedWith?: ESharedWithFilter[];
  attributeValueIds?: number[];
}) =>
  BitesApi.get(`/analytics/${orgId}/aggregated/comments/`, {
    params: {
      bite_ids: JSON.stringify(biteIds),
      allowed_data_only: allowedDataOnly || undefined,
      attribute_value_ids: attributeValueIds?.length ? JSON.stringify(attributeValueIds) : undefined,
      shared_with: sharedWith?.length ? JSON.stringify(sharedWith) : undefined,
    },
  });

export const getAnswersNumber = async ({
  orgId,
  biteIds,
  allowedDataOnly,
  sharedWith,
  attributeValueIds,
  answerIds,
}: {
  orgId: number;
  biteIds: string[];
  allowedDataOnly: boolean;
  sharedWith?: ESharedWithFilter[];
  attributeValueIds?: number[];
  answerIds?: number[];
}) =>
  BitesApi.get(`/analytics/${orgId}/aggregated/answers/`, {
    params: {
      bite_ids: JSON.stringify(biteIds),
      allowed_data_only: allowedDataOnly || undefined,
      attribute_value_ids: attributeValueIds?.length ? JSON.stringify(attributeValueIds) : undefined,
      shared_with: sharedWith?.length ? JSON.stringify(sharedWith) : undefined,
      answer_ids: answerIds?.length ? JSON.stringify(answerIds) : undefined,
    },
  });

export const getOverviewList = async ({
  orgId,
  biteIds,
  filter,
  attributeValueIds,
  next,
  sharedWith,
  allowedDataOnly,
}: IBiteAnalyticsRequestData) =>
  BitesApi.get(`/analytics/${orgId}/overview/bite/`, {
    params: {
      bite_ids: JSON.stringify(biteIds),
      attribute_value_ids: attributeValueIds?.length ? JSON.stringify(attributeValueIds) : undefined,
      shared_with: sharedWith?.length ? JSON.stringify(sharedWith) : undefined,
      allowed_data_only: allowedDataOnly || undefined,
      next,
      engaged: filter,
    },
  });

export const getViewsList = async ({
  orgId,
  biteIds,
  filter,
  next,
  attributeValueIds,
  sharedWith,
  pageSize,
  allowedDataOnly,
}: IBiteAnalyticsRequestData) =>
  BitesApi.get(`/analytics/${orgId}/views/bite/`, {
    params: {
      bite_ids: JSON.stringify(biteIds),
      attribute_value_ids: attributeValueIds?.length ? JSON.stringify(attributeValueIds) : undefined,
      shared_with: sharedWith?.length ? JSON.stringify(sharedWith) : undefined,
      allowed_data_only: allowedDataOnly || undefined,
      next,
      viewed: filter,
      page_size: pageSize,
    },
  });

export const getAnswersList = async ({
  orgId,
  biteIds,
  userIds,
  answerIds,
  filter,
  next,
  attributeValueIds,
  sharedWith,
  pageSize,
  allowedDataOnly,
}: IBiteAnalyticsRequestData) =>
  BitesApi.get(`/analytics/${orgId}/answers/bite/`, {
    params: {
      bite_ids: JSON.stringify(biteIds),
      user_ids: userIds?.length ? JSON.stringify(userIds) : undefined,
      answer_ids: answerIds?.length ? JSON.stringify(answerIds) : undefined,
      attribute_value_ids: attributeValueIds?.length ? JSON.stringify(attributeValueIds) : undefined,
      shared_with: sharedWith?.length ? JSON.stringify(sharedWith) : undefined,
      allowed_data_only: allowedDataOnly || undefined,
      next,
      answered: filter,
      page_size: pageSize,
    },
  });

export const getAnswersAggregated = async ({ orgId, biteId, attributeValueIds, sharedWith, allowedDataOnly }) =>
  BitesApi.get(`/analytics/${orgId}/aggregated/answers/${biteId}/`, {
    params: {
      attribute_value_ids: attributeValueIds?.length ? JSON.stringify(attributeValueIds) : undefined,
      shared_with: sharedWith?.length ? JSON.stringify(sharedWith) : undefined,
      allowed_data_only: allowedDataOnly || undefined,
    },
  });

export const getCommentsList = async ({
  orgId,
  biteIds,
  userIds,
  answerIds,
  filter,
  next,
  attributeValueIds,
  sharedWith,
  pageSize,
  allowedDataOnly,
}: IBiteAnalyticsRequestData) =>
  BitesApi.get(`/analytics/${orgId}/comments/bite/`, {
    params: {
      bite_ids: JSON.stringify(biteIds),
      user_ids: userIds?.length ? JSON.stringify(userIds) : undefined,
      answer_ids: answerIds?.length ? JSON.stringify(answerIds) : undefined,
      attribute_value_ids: attributeValueIds?.length ? JSON.stringify(attributeValueIds) : undefined,
      shared_with: sharedWith?.length ? JSON.stringify(sharedWith) : undefined,
      allowed_data_only: allowedDataOnly || undefined,
      next,
      commented: filter,
      page_size: pageSize,
    },
  });

interface IGetStatsOverview {
  orgId: number;
  playlistIds?: number[];
  quizIds?: number[];
  allowedDataOnly: boolean;
  sharedWith?: ESharedWithFilter[];
  attributeValueIds?: number[];
}

export const getPlaylistStatsOverview = async ({
  orgId,
  playlistIds,
  allowedDataOnly,
  sharedWith,
  attributeValueIds,
}: IGetStatsOverview) =>
  BitesApi.get(`analytics/${orgId}/playlist/overview/`, {
    params: {
      playlist_ids: JSON.stringify(playlistIds),
      allowed_data_only: allowedDataOnly || undefined,
      attribute_value_ids: attributeValueIds?.length ? JSON.stringify(attributeValueIds) : undefined,
      shared_with: sharedWith?.length ? JSON.stringify(sharedWith) : undefined,
    },
  });

export const getQuizStatsOverview = async ({
  orgId,
  quizIds,
  allowedDataOnly,
  sharedWith,
  attributeValueIds,
}: IGetStatsOverview) =>
  BitesApi.get(`analytics/${orgId}/quiz/overview/`, {
    params: {
      quiz_ids: JSON.stringify(quizIds),
      allowed_data_only: allowedDataOnly || undefined,
      attribute_value_ids: attributeValueIds?.length ? JSON.stringify(attributeValueIds) : undefined,
      shared_with: sharedWith?.length ? JSON.stringify(sharedWith) : undefined,
    },
  });

export const getPlaylistUsersList = async ({
  orgId,
  playlistIds,
  filter,
  attributeValueIds,
  next,
  sharedWith,
  allowedDataOnly,
  sortBy,
  sortDirection,
  pageSize,
}: IPlaylistAnalyticsRequestData) =>
  BitesApi.get(`/analytics/${orgId}/playlist/users`, {
    params: {
      playlist_ids: JSON.stringify(playlistIds),
      attribute_value_ids: attributeValueIds?.length ? JSON.stringify(attributeValueIds) : undefined,
      shared_with: sharedWith?.length ? JSON.stringify(sharedWith) : undefined,
      allowed_data_only: allowedDataOnly || undefined,
      next,
      engaged: filter,
      sort_by: sortBy,
      sort_direction: sortDirection,
      page_size: pageSize,
    },
  });

export const getQuizUsersList = async ({
  orgId,
  quizIds,
  filter,
  attributeValueIds,
  next,
  sharedWith,
  allowedDataOnly,
  sortBy,
  sortDirection,
  pageSize,
}: IPlaylistAnalyticsRequestData) =>
  BitesApi.get(`/analytics/${orgId}/quiz/users`, {
    params: {
      quiz_ids: quizIds?.length ? JSON.stringify(quizIds) : undefined,
      attribute_value_ids: attributeValueIds?.length ? JSON.stringify(attributeValueIds) : undefined,
      shared_with: sharedWith?.length ? JSON.stringify(sharedWith) : undefined,
      allowed_data_only: allowedDataOnly || undefined,
      next,
      engaged: filter,
      sort_by: sortBy,
      sort_direction: sortDirection,
      page_size: pageSize,
    },
  });
