import React, { useCallback } from 'react';
import { getIsWebRtl } from '../../../../locale/i18n';
import styled from 'styled-components/native';
import { calcHeight, calcWidth } from '../../../../utils/dimensions';
import { useDispatch, useSelector } from 'react-redux';
import {
  sortBySelector,
  sortDirectionSelector,
} from '../../../../store/attributes/playlistAttributes/playlistAttributes.selector';
import { setSortBy } from '../../../../store/attributes/playlistAttributes/playlistAttributes.slice';
import { ESortByType } from '../../../../store/attributes/playlistAttributes/playlistAttributes.types';
import SortIcon from '../../../../assets/icons/analytics/sort.svg';
import StartedIcon from '../../../../assets/icons/analytics/intro_empty.svg';
import ProgressIcon from '../../../../assets/icons/analytics/progress.svg';
import CompletedIcon from '../../../../assets/icons/analytics/completed.svg';
import SuccessIcon from '../../../../assets/icons/analytics/success.svg';
import { ESortingDirection } from '../../../../types/anayltics';
import { useTranslation } from 'react-i18next';
import { contentTypeSelector } from '../../../../store/analytics/analytics.selector';
import { EAnalyticsContentType } from '../../../../store/analytics/analytics.types';

const AttributesSorting = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isWebRtl = getIsWebRtl();

  const contentType = useSelector(contentTypeSelector);
  const sortBy = useSelector(sortBySelector);
  const sortDirection = useSelector(sortDirectionSelector);

  const sortByStarted = useCallback(() => {
    const _sortDirection =
      sortBy === ESortByType.STARTED
        ? sortDirection === ESortingDirection.ASC
          ? ESortingDirection.DESC
          : ESortingDirection.ASC
        : ESortingDirection.DESC;
    dispatch(setSortBy({ sortBy: ESortByType.STARTED, sortDirection: _sortDirection }));
  }, [dispatch, sortBy, sortDirection]);

  const sortByProgress = useCallback(() => {
    const _sortDirection =
      sortBy === ESortByType.PROGRESS
        ? sortDirection === ESortingDirection.ASC
          ? ESortingDirection.DESC
          : ESortingDirection.ASC
        : ESortingDirection.DESC;
    dispatch(setSortBy({ sortBy: ESortByType.PROGRESS, sortDirection: _sortDirection }));
  }, [dispatch, sortBy, sortDirection]);

  const sortByCompleted = useCallback(() => {
    const _sortDirection =
      sortBy === ESortByType.COMPLETED
        ? sortDirection === ESortingDirection.ASC
          ? ESortingDirection.DESC
          : ESortingDirection.ASC
        : ESortingDirection.DESC;
    dispatch(setSortBy({ sortBy: ESortByType.COMPLETED, sortDirection: _sortDirection }));
  }, [dispatch, sortBy, sortDirection]);

  const sortBySuccess = useCallback(() => {
    const _sortDirection =
      sortBy === ESortByType.SUCCESS
        ? sortDirection === ESortingDirection.ASC
          ? ESortingDirection.DESC
          : ESortingDirection.ASC
        : ESortingDirection.DESC;
    dispatch(setSortBy({ sortBy: ESortByType.SUCCESS, sortDirection: _sortDirection }));
  }, [dispatch, sortBy, sortDirection]);

  return (
    <S.IconsContainer isRtl={isWebRtl}>
      <S.IconSortingButton onPress={sortByStarted}>
        <S.IconContainer isRtl={isWebRtl}>
          <StartedIcon />
        </S.IconContainer>
        <S.SortText>{t('analytics.playlist.attributes.sortByStarted')}</S.SortText>
        {sortBy === ESortByType.STARTED && (
          <S.SortIconContainer sortDirection={sortDirection}>
            <SortIcon />
          </S.SortIconContainer>
        )}
      </S.IconSortingButton>
      <S.VerticalDivider />
      {contentType === EAnalyticsContentType.PLAYLIST && (
        <>
          <S.IconSortingButton onPress={sortByProgress}>
            <S.IconContainer isRtl={isWebRtl}>
              <ProgressIcon />
            </S.IconContainer>
            <S.SortText>{t('analytics.playlist.attributes.sortByProgress')}</S.SortText>
            {sortBy === ESortByType.PROGRESS && (
              <S.SortIconContainer sortDirection={sortDirection}>
                <SortIcon />
              </S.SortIconContainer>
            )}
          </S.IconSortingButton>
          <S.VerticalDivider />
        </>
      )}
      <S.IconSortingButton onPress={sortByCompleted}>
        <S.IconContainer isRtl={isWebRtl}>
          <CompletedIcon />
        </S.IconContainer>
        <S.SortText>{t('analytics.playlist.attributes.sortByCompleted')}</S.SortText>
        {sortBy === ESortByType.COMPLETED && (
          <S.SortIconContainer sortDirection={sortDirection}>
            <SortIcon />
          </S.SortIconContainer>
        )}
      </S.IconSortingButton>
      <S.VerticalDivider />
      <S.IconSortingButton onPress={sortBySuccess}>
        <S.IconContainer isRtl={isWebRtl}>
          <SuccessIcon />
        </S.IconContainer>
        <S.SortText>{t('analytics.playlist.attributes.sortBySuccess')}</S.SortText>
        {sortBy === ESortByType.SUCCESS && (
          <S.SortIconContainer sortDirection={sortDirection}>
            <SortIcon />
          </S.SortIconContainer>
        )}
      </S.IconSortingButton>
    </S.IconsContainer>
  );
};

const S = {
  IconsContainer: styled.View<{ isRtl: boolean }>`
    flex-direction: ${({ isRtl }) => (isRtl ? 'row-reverse' : 'row')};
    align-items: center;
    justify-content: space-between;
    background-color: ${({ theme }) => theme.colors.white};
    padding: 0 ${calcWidth(10)}px;
  `,
  IconContainer: styled.View<{ isRtl: boolean }>`
    align-items: center;
    height: ${calcHeight(24)}px;
    width: ${calcWidth(50)}px;
    justify-content: center;
  `,
  IconSortingButton: styled.TouchableOpacity`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  `,
  SortIconContainer: styled.View<{ sortDirection: ESortingDirection }>`
    position: absolute;
    bottom: ${calcHeight(-6)}px;
    transform: ${({ sortDirection }) => (sortDirection === ESortingDirection.ASC ? 'rotate(180deg)' : 'rotate(0deg)')};
  `,
  SortText: styled.Text`
    color: ${({ theme }) => theme.colors.lightGray40};
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    font-size: ${({ theme }) => theme.fontSizes.s11}px;
    font-weight: 400;
    line-height: ${({ theme }) => theme.fontSizes.s14}px;
    text-align: center;
  `,
  VerticalDivider: styled.View`
    width: 1px;
    height: 39px;
    background-color: ${({ theme }) => theme.colors.overviewDivider};
  `,
};

export default AttributesSorting;
