import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import Modal from '../../../components/modals/ModalController';
import TextToSpeechSidebar from '../LeftSidebar/TextToSpeechSidebar.web';
import defaultTheme from '../../../themes/defaultTheme';
import { SECONDARY_SIDEBAR_WIDTH } from '../videoEditor.constants';
import CloseIcon from '../../../assets/icons/close-blue.svg';
import { ICloudAsset } from '../../../store/cloudAssets/cloudAssets.types';
import { v4 as uuid } from 'uuid';
import { useDispatch } from 'react-redux';
import { setHighlightSelectedTimelineItem } from '../../../store/videoEditor/videoEditor.slice';

const PREVIEW_PANEL_WIDTH = 650;
const ADDITIONAL_PANEL_WIDTH = 300;

type Props = {
  isVisible: boolean;
  initialText?: string;
  initialVoiceId?: string;
  initialCloudAsset: ICloudAsset;
  onClose: () => void;
};
export const TextToSpeechSettingsModal = ({
  isVisible,
  initialText,
  initialVoiceId,
  initialCloudAsset,
  onClose,
}: Props) => {
  const dispatch = useDispatch();

  const additionalPanelRef = useRef(null);
  const previewPanelRef = useRef(null);
  const [, setPortalsRenderedKey] = useState('');

  const [isPreviewPanelVisible, setIsPreviewPanelVisible] = useState(!!initialCloudAsset);
  const [isAdditionalPanelVisible, setIsAdditionalPanelVisible] = useState(false);

  const handleCloseAdditionalPanel = useCallback(() => {
    setIsAdditionalPanelVisible(false);
  }, []);

  const containerWidth =
    SECONDARY_SIDEBAR_WIDTH +
    (isPreviewPanelVisible ? PREVIEW_PANEL_WIDTH : isAdditionalPanelVisible ? ADDITIONAL_PANEL_WIDTH : 0);

  const handleAdditionalPanelRendered = useCallback((ref) => {
    additionalPanelRef.current = ref;
    // this will trigger a rerender of the TextToSpeechSidebar component
    // to display the additional panel and preview panel content
    setPortalsRenderedKey(uuid());
  }, []);

  useEffect(() => {
    if (isVisible) {
      setIsPreviewPanelVisible(!!initialCloudAsset);
      setIsAdditionalPanelVisible(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible]);

  useEffect(() => {
    return () => {
      dispatch(setHighlightSelectedTimelineItem(false));
    };
  }, [dispatch]);

  return (
    <Modal isVisible={isVisible} backdropOpacity={0} onModalHide={onClose}>
      <S.Container width={containerWidth}>
        <S.PreviewPanel isVisible={isPreviewPanelVisible} ref={previewPanelRef}>
          {isAdditionalPanelVisible && <S.Overlay onClick={handleCloseAdditionalPanel} />}
        </S.PreviewPanel>
        <S.AdditionalPanel isVisible={isAdditionalPanelVisible} ref={handleAdditionalPanelRendered} />
        <S.MainPanel>
          <TextToSpeechSidebar
            initialText={initialText}
            initialVoiceId={initialVoiceId}
            initialCloudAsset={initialCloudAsset}
            setIsPreviewPanelVisible={setIsPreviewPanelVisible}
            setIsAdditionalPanelVisible={setIsAdditionalPanelVisible}
            isAdditionalPanelVisible={isAdditionalPanelVisible}
            isPreviewPanelVisible={isPreviewPanelVisible}
            previewPanelRef={previewPanelRef}
            additionalPanelRef={additionalPanelRef}
            isReplace
            onAdd={onClose}
          />
          <S.CloseButton onClick={onClose}>
            <CloseIcon />
          </S.CloseButton>
        </S.MainPanel>
      </S.Container>
    </Modal>
  );
};

const S = {
  Container: styled.div<{ width: number }>`
    position: relative;
    align-self: center;
    box-sizing: border-box;
    width: ${({ width }) => width}px;
    transition: width 300ms ease-in-out;

    & * {
      box-sizing: border-box;
    }
  `,
  MainPanel: styled.div`
    z-index: 1;
    position: relative;
    display: flex;
    flex-direction: column;
    width: ${SECONDARY_SIDEBAR_WIDTH}px;
    height: 590px;
    border-radius: 8px;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.2);
    background-color: ${defaultTheme.colors.white};
  `,
  CloseButton: styled.div`
    position: absolute;
    top: 16px;
    right: 16px;
    cursor: pointer;
  `,
  PreviewPanel: styled.div<{ isVisible: boolean }>`
    position: absolute;
    top: 0px;
    left: calc(${SECONDARY_SIDEBAR_WIDTH}px - 12px);
    bottom: 0px;
    display: flex;
    flex-direction: column;
    padding-left: 12px;
    width: ${PREVIEW_PANEL_WIDTH}px;
    border-radius: 8px;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.2);
    background-color: ${defaultTheme.colors.white};
    transition: all 300ms ease-in-out;
    overflow: hidden;
    ${({ isVisible }) => `transform: translateX(${isVisible ? 0 : -100}px);`}
    ${({ isVisible }) => `opacity: ${isVisible ? 1 : 0};`}
    ${({ isVisible }) => `pointer-events: ${isVisible ? 'auto' : 'none'};`}
  `,
  AdditionalPanel: styled.div<{ isVisible: boolean }>`
    position: absolute;
    top: 0px;
    left: calc(${SECONDARY_SIDEBAR_WIDTH}px - 12px);
    bottom: 0px;
    display: flex;
    flex-direction: column;
    padding-left: 12px;
    width: ${ADDITIONAL_PANEL_WIDTH}px;
    border-radius: 8px;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.2);
    background-color: ${defaultTheme.colors.white};
    transition: all 300ms ease-in-out;
    overflow-x: hidden;
    overflow-y: auto;
    ${({ isVisible }) => `transform: translateX(${isVisible ? 0 : -100}px);`}
    ${({ isVisible }) => `opacity: ${isVisible ? 1 : 0};`}
    ${({ isVisible }) => `pointer-events: ${isVisible ? 'auto' : 'none'};`}
  `,
  Overlay: styled.div`
    z-index: 100;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    cursor: pointer;
  `,
};
