import React, { memo, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getPlaylistAttributes } from '../../../../store/attributes/playlistAttributes/playlistAttributes.slice';
import HeaderTabs from '../common/HeaderTabs';
import useRedirectFromAttributes from '../../hooks/useRedirectFromAttributes';
import { IStackNavigation } from '../../../../navigation/types';
import { ECurrentList } from '../../../../store/analytics/analytics.types';
import { currentListSelector } from '../../../../store/analytics/analytics.selector';
import GuestsAttribute from '../common/GuestsAttribute';
import {
  selectedFilterAttributeValueIdsSelector,
  playlistSharedWithFilterSelector,
} from '../../../../store/attributes/playlistAttributes/playlistAttributes.selector';
import useHeaderTabsDivider from '../../hooks/useHeaderTabsDivider';
import { attributesListDataSet } from '../../analytics.constants';
import usePlaylistAttributesMap from '../hooks/usePlaylistAttributesMap';
import { PLAYLIST_ATTRIBUTES_SCREEN, PLAYLIST_USERS_LIST_SCREEN } from '../constants';
import styled from 'styled-components/native';
import { calcWidth, deviceWidth, isWeb } from '../../../../utils/dimensions';
import { ESharedWithFilter } from '../../../../types/anayltics';
import PlaylistAttributesList from '../common/PlaylistAttributesList';
import AttributesSorting from '../common/AttributesSorting';
import PlaylistTotalBar from '../../common/TotalBar/PlaylistTotalBar/PlaylistTotalBar';
import { isNeedToScrollUpSelector } from '../../../../store/attributes/playlistStats/playlistStats.selector';

const PlaylistAttributes: React.FC<IStackNavigation> = ({ navigation }) => {
  const { attributesMap } = usePlaylistAttributesMap();
  const selectedAttributes = useSelector(selectedFilterAttributeValueIdsSelector);
  const [isHideGuestsAttribute, setIsHideGuestsAttribute] = useState(false);
  const [isFocusedMode, setIsFocusedMode] = useState(false);

  const { handleScroll, isShowHeaderBottomDivider } = useHeaderTabsDivider();

  useRedirectFromAttributes({
    attributesMap,
    listScreen: PLAYLIST_USERS_LIST_SCREEN,
    navigation,
  });

  const isNeedToScrollUp = useSelector(isNeedToScrollUpSelector);
  const currentList = useSelector(currentListSelector);
  const sharedWithFilter = useSelector(playlistSharedWithFilterSelector);

  const renderGuestsAttribute = useCallback(
    ({ isRefreshing }) => {
      if (
        selectedAttributes.length ||
        isHideGuestsAttribute ||
        (sharedWithFilter.length === 1 && sharedWithFilter.includes(ESharedWithFilter.COMMUNICATION_HUB))
      ) {
        return null;
      }

      return <GuestsAttribute isRefreshing={isRefreshing} />;
    },
    [isHideGuestsAttribute, selectedAttributes.length, sharedWithFilter],
  );

  const renderListHeaderComponent = useCallback(
    ({ isRefreshing }) => (
      <>
        <PlaylistTotalBar isUsers={false} />
        {renderGuestsAttribute({ isRefreshing })}
      </>
    ),
    [renderGuestsAttribute],
  );

  useEffect(() => {
    if (currentList === ECurrentList.USERS) {
      navigation.replace(PLAYLIST_USERS_LIST_SCREEN);
    }
  }, [currentList, navigation]);

  return (
    <S.Container>
      <HeaderTabs
        currentScreen={PLAYLIST_ATTRIBUTES_SCREEN}
        attributesScreen={PLAYLIST_ATTRIBUTES_SCREEN}
        listScreen={PLAYLIST_USERS_LIST_SCREEN}
        rightComponent={<AttributesSorting />}
        isShowBottomDivider={isShowHeaderBottomDivider && !isFocusedMode}
      />
      <PlaylistAttributesList
        dataSet={attributesListDataSet}
        scrollEventThrottle={10}
        onScroll={handleScroll}
        onSelectAttribute={setIsHideGuestsAttribute}
        onSingleAttributeChanged={setIsFocusedMode}
        isNeedToScrollUp={isNeedToScrollUp}
        attributesMap={attributesMap}
        onLoad={getPlaylistAttributes}
        renderListHeaderComponent={renderListHeaderComponent}
        hasMultipleValues
      />
    </S.Container>
  );
};

const S = {
  Container: styled.View`
    height: 100%;
    width: ${isWeb ? calcWidth(550) : deviceWidth}px;
    align-self: center;
    flex: 1;
    background-color: white;
  `,
};

export default memo(PlaylistAttributes);
