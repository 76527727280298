import { ITimelineItem } from '../../../store/videoEditor/videoEditor.types';
import { INTER_TIMELINE_ITEM_SPACING } from '../timeline.constants';
import { getTimelineItemDuration } from '../Timeline.web';

export const getTimelineItemWidth = ({
  timelineItem,
  secondDisplayWidth,
}: {
  timelineItem: ITimelineItem;
  secondDisplayWidth: number;
}) => {
  const duration = getTimelineItemDuration(timelineItem);
  let width = duration * secondDisplayWidth - INTER_TIMELINE_ITEM_SPACING;
  width = Math.max(width, 4);

  return width;
};
