import { useDispatch, useSelector } from 'react-redux';
import { generationIdSelector } from '../../../store/videoEditorLeftSidebar/videoEditorLeftSidebar.selectors';
import { generationByIdSelector } from '../../../store/aiGeneration/aiGeneration.selector';
import { useEffect } from 'react';
import store from '../../../store';
import { ILayersDisplayDataLayer, ITimelineLayer } from '../../../store/videoEditor/videoEditor.types';
import {
  setHighlightSelectedTimelineItem,
  setHighlightSelectedTimelineItemPosition,
  setReplacingSelectedTimelineItemWalkme,
  setSelectedTimelineItemId,
} from '../../../store/videoEditor/videoEditor.slice';
import { selectedTimelineItemIdSelector } from '../../../store/videoEditor/videoEditor.selectors';
import { getTimelinePosition } from '../utils/getTimelinePosition';
import { getTimelineItemHeight } from '../timeline.constants';

type Props = {
  timelineLayers: ITimelineLayer[];
  highlightedLayersDisplayItem: ILayersDisplayDataLayer;
  getContainerRect: () => DOMRect;
  secondDisplayWidth: number;
  scrollTop: number;
  scrollLeft: number;
};
export const useInitGenerationWalkme = ({
  timelineLayers,
  highlightedLayersDisplayItem,
  getContainerRect,
  secondDisplayWidth,
  scrollTop,
  scrollLeft,
}: Props) => {
  const dispatch = useDispatch();

  const generationId = useSelector(generationIdSelector);
  const generation = useSelector(generationByIdSelector(generationId));
  const selectedTimelineItemId = useSelector(selectedTimelineItemIdSelector);

  useEffect(() => {
    if (!generation) {
      return;
    }

    // do not extract to selector to avoid unnererendering
    const itemsMap = store.getState().videoEditor.video.data.config.itemsMap;

    for (let i = 0; i < timelineLayers.length; i++) {
      const layer = timelineLayers[i];

      const timelineItemId = layer.timeline[0];

      if (!timelineItemId) {
        continue;
      }

      const index = Math.min(layer.timeline.length - 1, Math.ceil(layer.timeline.length / 2));
      const timelineItem = itemsMap[layer.timeline[index]];

      if (timelineItem?.type !== 'video') {
        continue;
      }

      dispatch(setSelectedTimelineItemId(timelineItem.id));
      dispatch(setHighlightSelectedTimelineItem(true));
      dispatch(setReplacingSelectedTimelineItemWalkme(true));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!highlightedLayersDisplayItem) {
      dispatch(setHighlightSelectedTimelineItemPosition(null));
      return;
    }

    const containerRect = getContainerRect();
    const itemsMap = store.getState().videoEditor.video.data.config.itemsMap;

    const timelineItem = itemsMap[selectedTimelineItemId];

    const { startX, width } = getTimelinePosition({
      timelineItem,
      startSeconds: timelineItem.start,
      secondDisplayWidth,
    });

    const height = getTimelineItemHeight(timelineItem.type);

    dispatch(
      setHighlightSelectedTimelineItemPosition({
        screenY: highlightedLayersDisplayItem.yOffset - scrollTop + containerRect.top,
        screenX: startX - scrollLeft + containerRect.left,
        width,
        height,
      }),
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [highlightedLayersDisplayItem, selectedTimelineItemId]);
};
