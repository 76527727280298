import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { generateTopics } from '../../../store/editAiGeneration/editAiGeneration.slice';
import styled from 'styled-components/native';

export function TopicsError() {
  const dispatch = useDispatch();

  const handleGenerateTopics = useCallback(() => {
    dispatch(generateTopics());
  }, [dispatch]);

  return (
    <S.TopicsErrorContainer>
      <S.TopicsErrorText>Failed to generate topics.</S.TopicsErrorText>
      <S.TopicsErrorRetry>
        <S.TopicsErrorRetryText onPress={handleGenerateTopics}>Click here to try again.</S.TopicsErrorRetryText>
      </S.TopicsErrorRetry>
    </S.TopicsErrorContainer>
  );
}

const S = {
  TopicsErrorContainer: styled.Text`
    color: ${({ theme }) => theme.colors.failRed};
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
  `,
  TopicsErrorText: styled.Text``,
  TopicsErrorRetry: styled.TouchableOpacity`
    margin-left: 8px;
  `,
  TopicsErrorRetryText: styled.Text`
    text-decoration: underline;
    cursor: pointer;
  `,
};
